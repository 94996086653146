import React from "react";
import { useCheckboxStyles } from "./styles";
import cx from "classnames";

export const Checkbox = ({ label, className, ...rest }) => {
  const classes = useCheckboxStyles();

  return (
    <div className={cx(classes.checkbox, className)}>
      <input
        id={"chb"}
        type="checkbox"
        {...rest}
        // onChange={() => onChangeCheckbox(name, index)}
        // checked={checked}
      />
      <label htmlFor={"chb"}>{label}</label>
    </div>
  );
};
