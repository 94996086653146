import React from 'react';

import DisguisedFaceEmoji from '#/src/static/img/disguised-face.png';
import SecondPlaceMedalEmoji from '#/src/static/img/second-place-medal.png';
import SunglassesEmoji from '#/src/static/img/sunglasses.png';
import ThirdPlaceMedalEmoji from '#/src/static/img/third-place-medal.png';
import TopEmoji from '#/src/static/img/top.png';
import Boat1 from '#/src/static/img/boats/boat1.png';
import Boat2 from '#/src/static/img/boats/boat2.png';
import Boat3 from '#/src/static/img/boats/boat3.png';
import Boat4 from '#/src/static/img/boats/boat4.png';
import Boat5 from '#/src/static/img/boats/boat5.png';
import Boat6 from '#/src/static/img/boats/boat6.png';
import Boat7 from '#/src/static/img/boats/boat7.png';
import Boat8 from '#/src/static/img/boats/boat8.png';
import Boat9 from '#/src/static/img/boats/boat9.png';
import Boat10 from '#/src/static/img/boats/boat10.png';
import Boat11 from '#/src/static/img/boats/boat11.png';
import Boat12 from '#/src/static/img/boats/boat12.png';
import Boat13 from '#/src/static/img/boats/boat13.png';
import Boat14 from '#/src/static/img/boats/boat14.png';
import CommonBoat from '#/src/static/img/boats/common_boat.png';

import { PlaceTitle } from './place-title';
import { RATING_TYPES } from '#/src/containers/Filter/PromoRating/constants'

const getBoatIcon = (place) => {
    switch (place) {
        case 1:
            return Boat1;
        case 2:
            return Boat2;
        case 3:
            return Boat3;
        case 4:
            return Boat4;
        case 5:
            return Boat5;
        case 6:
            return Boat6;
        case 7:
            return Boat7;
        case 8:
            return Boat8;
        case 9:
            return Boat9;
        case 10:
            return Boat10;
        case 11:
            return Boat11;
        case 12:
            return Boat12;
        case 13:
            return Boat13;
        case 14:
            return Boat14;
        default:
            return CommonBoat;
    }
};

export const getPlaceTitle = ({
    place,
    isCurrent,
    currentPlace,
    ratingType,
}) => {
    // Для первых 20 мест рейтинга по объему в качестве иконок корабли
    // для остальных - эмоджи
    if (ratingType === RATING_TYPES.totalAmount && place <= 20) {
        const Icon = getBoatIcon(place);

        return <PlaceTitle place={ place } iconSrc={ Icon } iconWidth={ 40 } iconHeight={ 30 } />;
    }

    // Случай с 1 местом не обрабатывается, т.к. в этом случае будет показан подиум
    if (place === 2) {
        return <PlaceTitle place={ place } iconSrc={ SecondPlaceMedalEmoji } />;
    }

    if (place === 3) {
        return <PlaceTitle place={ place } iconSrc={ ThirdPlaceMedalEmoji } />;
    }

    if (isCurrent) {
        return <PlaceTitle place={ place } iconSrc={ TopEmoji } />;
    }

    const isPositivePlace = place < currentPlace;
    const iconSrc = isPositivePlace ? SunglassesEmoji : DisguisedFaceEmoji;

    return <PlaceTitle place={ place } iconSrc={ iconSrc } />;
};
