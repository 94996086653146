import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Overlay from './components/Overlay'
import ErrorPage from './pages/ErrorPage'
import { authenticationUser } from './redux/User/actions'
import { IS_NEW_AUTH } from './config'

export const KeycloakAuthWrapper = ({
  children,
  isKeycloakAuthType,
  error,
  onError,
}) => {
  const dispatch = useDispatch()
  const { isFetching, isAuth, logout, logoutRedirectUrl } = useSelector(state => state.User)
  const getSession = (payload) => dispatch(authenticationUser(payload))

  useEffect(() => {
    if (isKeycloakAuthType && !IS_NEW_AUTH) {
      const redirectToAuth = (redirectUrl) => window.location.href = redirectUrl
      getSession({ redirectToAuth, onError })
    }
  }, [isKeycloakAuthType, IS_NEW_AUTH])

  useEffect(() => {
    if (logout && logoutRedirectUrl && !IS_NEW_AUTH) {
      const redirectToAuth = () => window.location.href = logoutRedirectUrl
      getSession({ redirectToAuth, onError })
    }
  }, [isKeycloakAuthType, logout, logoutRedirectUrl, IS_NEW_AUTH])

  const _isAuth = isKeycloakAuthType ? isAuth : true

  const renderChildren = IS_NEW_AUTH
    ? !isFetching && !error && _isAuth
    : !isFetching && !error

  const renderOverlay = IS_NEW_AUTH
    ? isFetching && !_isAuth
    : isFetching

  const renderError = !isFetching && error

  return (
    <>
      {renderChildren && <>{children}</>}
      {renderOverlay && <Overlay size='big' />}
      {renderError && <ErrorPage />}
    </>
  )
}