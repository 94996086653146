import React, { Component } from 'react'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { getClientItem, clearClient } from '#/src/redux/Client/actions'
import { authenticationUser } from '#/src/redux/User/actions'
import { formatNumber } from '#/src/utils'
import { adaptive_1200 } from '#/src/config'
import { COLORS } from '#/src/constants/styleVariables';
import OrderItem from './blocks/OrderItem'

const styles = {
  constraint: {
    width: '100%',
    display: 'flex',
    '& + &': {
      marginTop: 10,
    },
  },
  [adaptive_1200]: {
    constraint: {
      display: 'block'
    }
  },
  notDefined: {
    fontStyle: 'italic',
    color: COLORS.textSecondaryColor
  }
}

const reverseStr = str => str.split('').reverse().join('')

const formatMoney = (value, defaultValue = '---') => {
  if (!value) {
    return defaultValue
  }
  let [whole, fraction] = value.toString().split('.')
  whole = reverseStr(whole)
  const a = []
  let i = 0
  do {
    a.push(whole.substr(i * 3, 3))
    i++
  } while(((i - 1) * 3 + 3) < whole.length)

  whole = reverseStr(a.join(' '))
  return `${whole}${fraction ? ('.' + fraction) : '.00'} ₽`
}

class ClientDetail extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    name: PropTypes.string,
    fullName: PropTypes.string,
    INN: PropTypes.string,
    OGRN: PropTypes.string,
    KPP: PropTypes.string,
    address: PropTypes.string,
    orders: PropTypes.array,
    onProgrammingRedirect: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { id, dispatch } = this.props
    this.onClearClient()
    dispatch(getClientItem(id))
  }

  componentWillUnmount() {
    this.onClearClient()
  }

  onClearClient = () => this.props.dispatch(clearClient())

  onOpenTaskDetail = (taskId, taskName) => {
    const { onProgrammingRedirect, dispatch } = this.props
    dispatch(authenticationUser(true))
      .then(() => onProgrammingRedirect(`/tasks/${taskId}`, { title: taskName }))
      .catch(err => console.log(err))
  }

  render() {
    const {
      classes,
      isFetching,
      name,
      INN,
      OGRN,
      KPP,
      address,
      fullName,
      contactPhone,
      contactName,
      orders,
      stats,
      docs,
      settings,
      limits,
      redirect,
    } = this.props

    const {
      constraints,
      createdDate,
      endDate,
      frozenAmount,
      maxOrderAmount,
      remainAmount,
      totalAmount,
      utilizedAmount
    } = limits;

    if (redirect) {
      return <Redirect to='/tasks' />
    }

    return (
      <>
        <div className={classnames('modal-content__header', {'modal-content--blur': isFetching})}>
          <div>
            <div className='modal-content__title'>
              <span className='icon icon-user' />
              <span className={name ? '' : classes.notDefined}>{name || 'Не указано'}</span>
            </div>
            <div className='modal-content__title'>
              <span>ИНН: {INN}</span>
            </div>
          </div>
          <div>
            <ul className='stats-list'>
              {settings.statusItems.map(({ key, text, className }) => {
                if (key === 'total') {
                  return null
                }
                return (typeof stats[key] !== 'undefined' && stats[key].count) ?
                  <li key={key} title={text} className={classnames('stats-list__item', {[`stats-list__item--${className}`]: className})}>
                    <i className='icon icon-ok' />
                    <span>{formatNumber(stats[key].count)}</span>
                  </li>
                  : null
              })}
            </ul>
          </div>
        </div>
        <div className={classnames('modal-content__body', {'modal-content--blur': isFetching})}>
          <div className='info-block'>
            <div className={classnames(
                'info-block__item',
                'info-block__item--title-main',
                'info-block__item--left',
                !fullName && classes.notDefined
              )}>
              {fullName || 'Не указано'}
            </div>
            <div className='info-block__item info-block__item--right'>
              <span className='info-block__item-title'>КПП</span>
              <span className={classnames('info-block__item-value', !KPP && classes.notDefined)}>{KPP || 'Не указан'}</span>
            </div>
            <div className='info-block__item info-block__item--right'>
              <span className='info-block__item-title'>ОГРН</span>
              <span className={classnames('info-block__item-value', !OGRN && classes.notDefined)}>{OGRN || 'Не указан'}</span>
            </div>
          </div>
          <div className='info-block info-block--last'>
            <div className='info-block__item info-block__item--title info-block__item--left info-block__item--fullwidth'>Контакты</div>
            <div className='info-block__item info-block__item--left'>
              <span className='info-block__item-title'>Адрес</span>
              <span className={classnames('info-block__item-value', !address && classes.notDefined)}>{address || 'Не указан'}</span>
            </div>
            <div className='info-block__item info-block__item--right'>
              <span className='info-block__item-title'>Телефон</span>
              <span className={classnames('info-block__item-value', !contactPhone && classes.notDefined)}>{contactPhone || 'Не указан'}</span>
            </div>
            <div className='info-block__item info-block__item--right'>
              <span className='info-block__item-title'>Контактное лицо</span>
              <span className={classnames('info-block__item-value', !contactName && classes.notDefined)}>{contactName || 'Не указано'}</span>
            </div>
          </div>
          <div className='info-block info-block--last'>
            <div className='info-block__item info-block__item--title info-block__item--left info-block__item--fullwidth'>Лимиты</div>
            <div className='info-block__item info-block__item--left info-block__item--small'>
              <span className='info-block__item-title'>Расчётный лимит</span>
              <span className='info-block__item-value'>{formatMoney(totalAmount, '---')}</span>
            </div>
            <div className='info-block__item info-block__item--left info-block__item--small'>
              <span className='info-block__item-title'>Использованный лимит</span>
              <span className='info-block__item-value'> {formatMoney(utilizedAmount, '---')}</span>
            </div>
            <div className='info-block__item info-block__item--right info-block__item--small'>
              <span className='info-block__item-title'>Замороженный лимит</span>
              <span className='info-block__item-value'>{formatMoney(frozenAmount, '---')}</span>
            </div>
            <div className='info-block__item info-block__item--right info-block__item--small'>
              <span className='info-block__item-title'>Свободный лимит</span>
              <span className='info-block__item-value'>{formatMoney(remainAmount, '---')}</span>
            </div>
          </div>
          <div className='info-block info-block--last'>
            <div className='info-block__item info-block__item--left info-block__item--small'>
              <span className='info-block__item-title'>Дата установки</span>
              <span className='info-block__item-value'>{createdDate}</span>
            </div>
            <div className='info-block__item info-block__item--left info-block__item--small'>
              <span className='info-block__item-title'>Дата окончания действия</span>
              <span className='info-block__item-value'>{endDate}</span>
            </div>
            {maxOrderAmount
              ? <div className='info-block__item info-block__item--right info-block__item--big'>
                  <span className='info-block__item-title'>Максимальная сумма сделки</span>
                  <span className='info-block__item-value'>{formatMoney(maxOrderAmount, '---')}</span>
                </div>
              : null
            }
          </div>
          {constraints && constraints.length &&
            <div className='info-block info-block--last'>
              <div className='info-block__item info-block__item--title info-block__item--left info-block__item--fullwidth'>Ограничения по лимитам</div>
              {constraints.map(({ limitAmount, maxOrderAmount, startMaxOrderAmount, prepaidPercent }, index) =>
                <div key={index} className={classes.constraint}>
                  <div className='info-block__item info-block__item--left info-block__item--small'>
                    <span className='info-block__item-title'>Суммарный объем лимита</span>
                    <span className='info-block__item-value'>{formatMoney(limitAmount, '---')}</span>
                  </div>
                  <div className='info-block__item info-block__item--left info-block__item--small'>
                    <span className='info-block__item-title'>Сумма сделки</span>
                    <span className='info-block__item-value'> {formatMoney(maxOrderAmount, '---')}</span>
                  </div>
                  <div className='info-block__item info-block__item--right info-block__item--small'>
                    <span className='info-block__item-title'>Максимальная НЦК по сделке</span>
                    <span className='info-block__item-value'>{formatMoney(startMaxOrderAmount, '---')}</span>
                  </div>
                  <div className='info-block__item info-block__item--right info-block__item--small'>
                    <span className='info-block__item-title'>Ограничение по авансу</span>
                    <span className='info-block__item-value'>{prepaidPercent ? `${prepaidPercent}%` : '---'}</span>
                  </div>
                </div>
              )}
            </div>
          }
          <div className='info-block info-block--last'>
            <div className='info-block__item info-block__item--title info-block__item--left info-block__item--fullwidth'>Документы</div>
            <div className='info-block__item info-block__item--fullwidth'>
              <div className='documents-list'>
                {docs.map(doc => doc.files.map(file => (
                  <a
                    key={file.name}
                    href={file.url}
                    className='documents-list__item'
                    target='_blank'
                    rel='noopener noreferrer'
                    download
                  >
                    <div className='documents-list__item-body'>
                      <div className='documents-list__item-icon'>
                        <i className='icon icon-doc-2' />
                      </div>
                      <span className='documents-list__item-title'>
                        {doc.name}
                      </span>
                    </div>
                    <div className='documents-list__item-footer'>
                      <span className='documents-list__item-size'>
                        {file.name}
                      </span>
                    </div>
                  </a>
                )))}
              </div>
            </div>
          </div>
          {orders.length
            ? <div className='orders-list'>
                {orders.map((order) =>
                  <OrderItem
                    key={order.order_id}
                    task={order.tasks ? order.tasks[0] : undefined}
                    orderNumber={order.orderNumber}
                    createdDate={order.createdDate}
                    activePhase={order.phases[order.phases.length - 1]}
                    onOpenTaskDetail={this.onOpenTaskDetail}
                  />
                )}
              </div>
            : null
          }
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ User, Client }) => {
  const { isFetching, company } = Client;
  const { INN, KPP, OGRN, contact, displayName, docs, fullName, legalAddress, limits, stat } = company;

  return {
    isFetching,
    settings: User.settings,
    name: displayName || fullName,
    fullName,
    INN,
    OGRN,
    KPP,
    address: legalAddress?.addressResult,
    contactPhone: contact?.phone,
    contactName: contact?.fio,
    orders: [], // company.order || [],
    stats: stat || {},
    docs: docs || [],
    limits: limits || {},
    redirect: Client.redirect,
  }
}

export default connect(mapStateToProps)(injectSheet(styles)(ClientDetail))

