import axios from 'axios'
import { SERVER } from '../constants'

export const getCompanyOrder = async (orderId) => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'GET',
      data: { orderId },
      url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/order/get?orderId=${orderId}`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}
