import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ModalAlternative } from '../../containers/ModalAlternative/ModalAlternative'
import { registerAgentAction } from '../../redux/Register/actions'
import Container from '../Verify/Container'
import Header from '../Verify/Header'
import Item from '../Verify/Item'
import Form from './Form'

import { useRegisterStyles } from './styles'

import like from '../../static/img/like.svg'
import likeSub from '../../static/img/likesub.svg'
import stopIcon from '../../static/img/stop.svg'

import { IS_COCONUT } from '../../config'

const TOAST_TIMEOUT = 3000

export const HELPER_TEXT_1 = `Для входа в личный кабинет после нажатия на кнопку "Зарегистрироваться" перейдите по ссылке <a href='https://bg.alfabank.ru'>https://bg.alfabank.ru</a>`

export const HELPER_TEXT_2 = `, где имя пользователя — это ИНН Вашей компании.`

export default connect(
  null,
  (dispatch) => ({
    registerAgent: (payload, onSuccess, onError) =>
      dispatch(registerAgentAction(payload, onSuccess, onError)),
  })
)(({ registerAgent }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [error, setError] = useState()
  const classes = useRegisterStyles()

  const onSubmit = (values) => {
    const onSuccess = () => setModalOpen(true)
    registerAgent(values, onSuccess, setError)
  }

  const onCloseModal = () => setModalOpen(false)
  const onCloseErrorModal = () => setError()

  return (
    <Fragment>
      <ToastContainer
        className={classes.toast}
        enableMultiContainer
        containerId='register'
        autoClose={TOAST_TIMEOUT}
        position={toast.POSITION.TO}
        closeOnClick
        pauseOnHover
        draggable
      />
      <section className={classes.root}>
        <Container>
          <Header items={[]} className={classes.header} />
          <Item>
            <Item.Head title='Регистрация агента' />
            <Form onSubmit={onSubmit} />
            {modalOpen &&
              <ModalAlternative
                modalClass={classes.modal}
                onClose={onCloseModal}
                contentClass={classes.contentClass}
              >
                <div className={classes.logoContainer}>
                  <img className={classes.background} iconName='success-background' src={likeSub} alt='' />
                  <img className={classes.icon} iconName='success' src={like} alt='' />
                </div>
                <h1 className={classes.h1}>Ваш запрос отправлен в Банк.</h1>
                {IS_COCONUT
                  ? <div className={classes.p} dangerouslySetInnerHTML={{__html: `${HELPER_TEXT_1}${HELPER_TEXT_2}`}} />
                  : <div className={classes.p}>
                      Для продолжения регистрации необходимо подтвердить e-mail, указанный в Заявке. Мы направили на вашу почту письмо. Откройте его и перейдите по ссылке внутри. После подтверждения вы получите письмо с Логином и Временным паролем от Личного Кабинета.
                    </div>
                }
              </ModalAlternative>
            }
            {error &&
              <ModalAlternative
                modalClass={classes.modal}
                onClose={onCloseErrorModal}
                contentClass={classes.contentClass}
              >
                <div className={classes.logoContainer}>
                  <img className={classes.stopIcon} src={stopIcon} alt='' />
                </div>
                <div className={classnames(classes.p, classes.pError)}>
                  {error}
                </div>
              </ModalAlternative>
            }
          </Item>
        </Container>
      </section>
    </Fragment>
  )
})