import * as actionTypes from './actionTypes'
import { Search } from '../../services/api'

import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'

export const searchByString = (query, findAll) => async dispatch => {
  dispatch({ type: actionTypes.SEARCH })
  try {
    const requestMethod = findAll ? Search.selectByString : Search.findByString
    const { isSuccess, ...res } = await requestMethod(query)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({
      type: actionTypes.SEARCH_SUCCESS,
      result: res.list ? res.list.slice(0, 7) : []
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.SEARCH_ERROR })
  }
}

export const clearSearchResults = () => dispatch => dispatch({ type: actionTypes.SEARCH_CLEAR })