import { RATING_CONFIG_BY_TYPE, RATING_TYPES } from './constants'
import { Podium } from './Place/Podium/Podium'
import { RatingList } from './Place/RatingList'

export const getCurrentRatingByRatingType = ({
     ratingLists,
     selectedRatingType,
   }) => ratingLists.find((rating) => rating.ratingType === selectedRatingType);

export const getCurrentPlaceFromRating = (rating) =>
  rating.positions.find((position) => position.isCurrent);

export const getRatingDescription = ({
   period,
   selectedRatingType,
   currentPlace,
 }) =>
  `${RATING_CONFIG_BY_TYPE[selectedRatingType].ratingParam} выдач за ${period} — ${currentPlace.score} ${RATING_CONFIG_BY_TYPE[selectedRatingType].scoreUnits}`;

export const getRatingComponent = (rating) => {
  switch (rating.ratingType) {
    case RATING_TYPES.count:
      return rating.positions[0].place === 1 ? Podium : RatingList;
    case RATING_TYPES.totalAmount:
      return RatingList;
  }
};