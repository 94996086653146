import React from 'react';
import { useSelector } from 'react-redux'
import { Modal } from '../../../layout'
import { getOrderParameters } from '../../../redux/Company/selectors'

export const Parameters = () => {
  const parameters = useSelector(getOrderParameters);

  return <Modal.Parameters parameters={parameters} />
}

