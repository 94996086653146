import axios from 'axios';
import { SERVER } from '../constants';

export const getPromoAgentRating = async () => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.NEW_EXTERNAL_API_ENDPOINT}/agent/rating`,
    });

    if (response.status === 200) {
      return {
        isSuccess: true,
        data: response.data,
      }
    }

    return {
      isSuccess: false,
    };
  } catch (err) {
    return {
      isSuccess: false,
    }
  }
};
