import { createUseStyles } from "react-jss";
import { THEME_COLOR } from "../../config";

export const useCheckboxStyles = createUseStyles({
  checkbox: {
    width: "340px",
    display: "inline-block",
    verticalAlign: "top",
    position: "relative",
    "&:nth-child(2n)": {
      // marginLeft: "20px",
    },
    "& > input": {
      display: "none",
      "&:checked": {
        "& + label": {
          "&:before": {
            opacity: 0,
          },
          "&:after": {
            opacity: 1,
          },
        },
      },
    },
    "& > label": {
      paddingLeft: "47px",
      color: "#212529",
      fontSize: "16px",
      lineHeight: "27px",
      fontWeight: 500,
      cursor: "pointer",
      userSelect: "none",
      "&:before": {
        content: '""',
        width: "27px",
        height: "27px",
        border: "3px solid #BBBBBB",
        borderRadius: "6px",
        position: "absolute",
        left: 0,
        top: 0,
        display: "block",
        opacity: 1,
        transition: "opacity 300ms ease",
      },
      "&:after": {
        content: '""',
        background: `url(${require("./checked.svg")}) no-repeat`,
        fill: THEME_COLOR,
        width: "27px",
        height: "27px",
        borderRadius: "6px",
        position: "absolute",
        left: 0,
        top: 0,
        display: "block",
        opacity: 0,
        transition: "opacity 300ms ease",
      },
    },
  },
});
