export const allCountries = [
  ['Afghanistan (‫افغانستان‬‎)', 'af', '93', '+00-00-000-0000'],
  // [
  //   'Åland Islands',
  //   'ax',
  //   '358',
  //   ''
  // ],
  ['Albania (Shqipëri)', 'al', '355', '+000(000)000-000'],
  ['Algeria (‫الجزائر‬‎)', 'dz', '213', '+000-00-000-0000'],
  ['American Samoa', 'as', '1684', '+0(000)000-0000'],
  ['Andorra', 'ad', '376', '+000-000-000'],
  ['Angola', 'ao', '244', '+000(000)000-000'],
  ['Anguilla', 'ai', '1264', '+0(000)000-0000'],
  // [
  //   'Antarctica',
  //   'aq',
  //   '672',
  //   ''
  // ],
  ['Antigua and Barbuda', 'ag', '1268', '+0(000)000-0000'],
  ['Argentina', 'ar', '54', '+00(000)000-0000'],
  ['Armenia (Հայաստան)', 'am', '374', '+000-00-000-000'],
  ['Aruba', 'aw', '297', '+000-000-0000'],
  ['Australia', 'au', '61', '+00 000 000 000'],
  ['Austria (Österreich)', 'at', '43', '+00(000)000-0000'],
  ['Azerbaijan (Azərbaycan)', 'az', '994', '+000-00-000-00-00'],
  ['Bahamas', 'bs', '1242', '+0(000)000-0000'],
  ['Bahrain (‫البحرين‬‎)', 'bh', '973', '+000-0000-0000'],
  ['Bangladesh (বাংলাদেশ)', 'bd', '880', '+000-00-000-000'],
  ['Barbados', 'bb', '1246', '+0(000)000-0000'],
  ['Belarus (Беларусь)', 'by', '375', '+000(00)000-00-00'],
  ['Belgium (België)', 'be', '32', '+00 000 00 00 00'],
  ['Belize', 'bz', '501', '+000-000-0000'],
  ['Benin (Bénin)', 'bj', '229', '+000-00-00-0000'],
  ['Bermuda', 'bm', '1441', '+0(000)000-0000'],
  ['Bhutan (འབྲུག)', 'bt', '975', '+000-0-000-000'],
  ['Bolivia', 'bo', '591', '+000-0-000-0000'],
  ['Bosnia and Herzegovina (Босна и Херцеговина)', 'ba', '387', '+000-00-0000'],
  ['Botswana', 'bw', '267', '+000-00-000-000'],
  // [
  //   'Bouvet Island',
  //   'bv',
  //   '47',
  //   ''
  // ],
  ['Brazil (Brasil)', 'br', '55', '+00-00-0000-0000'],
  ['British Indian Ocean Territory', 'io', '246', '+000-000-0000'],
  ['British Virgin Islands', 'vg', '1284', '+0(000)000-0000'],
  ['Brunei', 'bn', '673', '+000-000-0000'],
  ['Bulgaria (България)', 'bg', '359', '+000(000)000-000'],
  ['Burkina Faso', 'bf', '226', '+000-00-00-0000'],
  ['Burundi (Uburundi)', 'bi', '257', '+000-00-00-0000'],
  ['Cambodia (កម្ពុជា)', 'kh', '855', '+000-00-000-000'],
  ['Cameroon (Cameroun)', 'cm', '237', '+000-0000-0000'],
  [
    'Canada',
    'ca',
    '1',
    '+0 (000) 000-0000',
    1,
    [
      '204',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  ],
  ['Cape Verde (Kabu Verdi)', 'cv', '238', '+000(000)00-00'],
  ['Caribbean Netherlands', 'bq', '599', '+000-000-0000', 1],
  ['Cayman Islands', 'ky', '1345', '+0(000)000-0000'],
  [
    'Central African Republic (République centrafricaine)',
    'cf',
    '236',
    '+000-00-00-0000',
  ],
  ['Chad (Tchad)', 'td', '235', '+000-00-00-00-00'],
  ['Chile', 'cl', '56', '+00-0-0000-0000'],
  ['China (中国)', 'cn', '86', '+00 00-00000000'],
  // [
  //   'Christmas Island',
  //   'cx',
  //   '61',
  //   ''
  // ],
  // [
  //   'Cocos (Keeling) Islands',
  //   'cc',
  //   '61',
  //   ''
  // ],
  ['Colombia', 'co', '57', '+00(000)000-0000'],
  ['Comoros (‫جزر القمر‬‎)', 'km', '269', '+000-00-00000'],
  [
    'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    'cd',
    '243',
    '+000(000)000-000',
  ],
  ['Congo (Republic) (Congo-Brazzaville)', 'cg', '242', '+000-00-000-0000'],
  ['Cook Islands', 'ck', '682', '+000-00-000'],
  ['Costa Rica', 'cr', '506', '+000 0000-0000'],
  ['Côte d’Ivoire', 'ci', '225', '+000-00-000-000'],
  ['Croatia (Hrvatska)', 'hr', '385', '+000-00-000-000'],
  ['Cuba', 'cu', '53', '+00-0-000-0000'],
  ['Curaçao', 'cw', '599', '+000-000-0000', 0],
  ['Cyprus (Κύπρος)', 'cy', '357', '+000-00-000-000'],
  ['Czech Republic (Česká republika)', 'cz', '420', '+000(000)000-000'],
  ['Denmark (Danmark)', 'dk', '45', '+00 00 00 00 00'],
  ['Djibouti', 'dj', '253', '+000-00-00-00-00'],
  ['Dominica', 'dm', '1767', '+0(000)000-0000'],
  [
    'Dominican Republic (República Dominicana)',
    'do',
    '1',
    '+0(000)000-0000',
    2,
    ['809', '829', '849'],
  ],
  ['Ecuador', 'ec', '593', '+000-0-000-0000'],
  ['Egypt (‫مصر‬‎)', 'eg', '20', '+00(000)000-0000'],
  ['El Salvador', 'sv', '503', '+000 0000-0000'],
  ['Equatorial Guinea (Guinea Ecuatorial)', 'gq', '240', '+000-00-000-0000'],
  ['Eritrea', 'er', '291', '+000-0-000-000'],
  ['Estonia (Eesti)', 'ee', '372', '+000-000-0000'],
  ['Ethiopia', 'et', '251', '+000-00-000-0000'],
  ['Falkland Islands (Islas Malvinas)', 'fk', '500', '+000-00000'],
  ['Faroe Islands (Føroyar)', 'fo', '298', '+000-000-000'],
  ['Fiji', 'fj', '679', '+000-00-00000'],
  ['Finland (Suomi)', 'fi', '358', '+000 00 0000 0000'],
  ['France', 'fr', '33', '+00 0 00 00 00 00'],
  ['French Guiana (Guyane française)', 'gf', '594', '+000-00000-0000'],
  ['French Polynesia (Polynésie française)', 'pf', '689', '+000-00-00-00'],
  // [
  //   'French Southern and Antarctic Lands',
  //   'tf',
  //   '262',
  //   ''
  // ],
  ['Gabon', 'ga', '241', '+000-0-00-00-00'],
  ['Gambia', 'gm', '220', '+000(000)00-00'],
  ['Georgia (საქართველო)', 'ge', '995', '+000(000)000-000'],
  ['Germany (Deutschland)', 'de', '49', '+00 000 0000000'],
  ['Ghana (Gaana)', 'gh', '233', '+000(000)000-000'],
  ['Gibraltar', 'gi', '350', '+000-000-00000'],
  ['Greece (Ελλάδα)', 'gr', '30', '+00(000)000-0000'],
  ['Greenland (Kalaallit Nunaat)', 'gl', '299', '+000-00-00-00'],
  ['Grenada', 'gd', '1473', '+0(000)000-0000'],
  // [
  //   'Guadeloupe',
  //   'gp',
  //   '590',
  //   '',
  //   0
  // ],
  ['Guam', 'gu', '1671', '+0(000)000-0000'],
  ['Guatemala', 'gt', '502', '+000 0000-0000'],
  // [
  //   'Guernsey',
  //   'gg',
  //   '44',
  //   ''
  // ],
  ['Guinea (Guinée)', 'gn', '224', '+000-00-000-000'],
  ['Guinea-Bissau (Guiné Bissau)', 'gw', '245', '+000-0-000000'],
  ['Guyana', 'gy', '592', '+000-000-0000'],
  ['Haiti', 'ht', '509', '+000 0000-0000'],
  // [
  //   'Heard Island and McDonald Islands',
  //   'hm',
  //   '672',
  //   ''
  // ],
  ['Honduras', 'hn', '504', '+000-0000-0000'],
  ['Hong Kong (香港)', 'hk', '852', '+000 0000 0000'],
  ['Hungary (Magyarország)', 'hu', '36', '+00(000)000-000'],
  ['Iceland (Ísland)', 'is', '354', '+000 000 0000'],
  ['India (भारत)', 'in', '91', '+00 00000-00000'],
  ['Indonesia', 'id', '62', '+00-00-000-00'],
  ['Iran (‫ایران‬‎)', 'ir', '98', '+00(000)000-0000'],
  ['Iraq (‫العراق‬‎)', 'iq', '964', '+000(000)000-0000'],
  ['Ireland', 'ie', '353', '+000 00 0000000'],
  // [
  //   'Isle of Man',
  //   'im',
  //   '44',
  //   ''
  // ],
  ['Israel (‫ישראל‬‎)', 'il', '972', '+000-0-000-0000'],
  ['Italy (Italia)', 'it', '39', '+00 000 000000', 0],
  ['Jamaica', 'jm', '1876', '+0(000)000-0000'],
  ['Japan (日本)', 'jp', '81', '+00 000 00 0000'],
  // [
  //   'Jersey',
  //   'je',
  //   '44',
  //   ''
  // ],
  ['Jordan (‫الأردن‬‎)', 'jo', '962', '+000-0-0000-0000'],
  [
    'Kazakhstan (Казахстан)',
    'kz',
    '7',
    '+0 000 000-00-00',
    1,
    ['71', '72', '70', '77', '75', '76'],
  ],
  ['Kenya', 'ke', '254', '+000-000-000000'],
  ['Kiribati', 'ki', '686', '+000-00-000'],
  // [
  //   'Kosovo',
  //   'xk',
  //   '383',
  //   ''
  // ],
  ['Kuwait (‫الكويت‬‎)', 'kw', '965', '+000-0000-0000'],
  ['Kyrgyzstan (Кыргызстан)', 'kg', '996', '+000(000)000-000'],
  ['Laos (ລາວ)', 'la', '856', '+000-00-000-000'],
  ['Latvia (Latvija)', 'lv', '371', '+000-00-000-000'],
  ['Lebanon (‫لبنان‬‎)', 'lb', '961', '+000-0-000-000'],
  ['Lesotho', 'ls', '266', '+000-0-000-0000'],
  ['Liberia', 'lr', '231', '+000-00-000-000'],
  ['Libya (‫ليبيا‬‎)', 'ly', '218', '+000-00-000-000'],
  ['Liechtenstein', 'li', '423', '+000(000)000-0000'],
  ['Lithuania (Lietuva)', 'lt', '370', '+000(000)00-000'],
  ['Luxembourg', 'lu', '352', '+000(000)000-000'],
  ['Macau (澳門)', 'mo', '853', '+000-0000-0000'],
  ['Macedonia (FYROM) (Македонија)', 'mk', '389', '+000-00-000-000'],
  ['Madagascar (Madagasikara)', 'mg', '261', '+000-00-00-00000'],
  ['Malawi', 'mw', '265', '+000-0-0000-0000'],
  ['Malaysia', 'my', '60', '+00 00-0000-0000'],
  ['Maldives', 'mv', '960', '+000-000-0000'],
  ['Mali', 'ml', '223', '+000-00-00-0000'],
  ['Malta', 'mt', '356', '+000-0000-0000'],
  ['Marshall Islands', 'mh', '692', '+000-000-0000'],
  ['Martinique', 'mq', '596', '+000(000)00-00-00'],
  ['Mauritania (‫موريتانيا‬‎)', 'mr', '222', '+000-00-00-0000'],
  ['Mauritius (Moris)', 'mu', '230', '+000-000-0000'],
  // [
  //   'Mayotte',
  //   'yt',
  //   '262',
  //   ''
  // ],
  ['Mexico (México)', 'mx', '52', '+00-00-00-0000'],
  ['Micronesia', 'fm', '691', '+000-000-0000'],
  ['Moldova (Republica Moldova)', 'md', '373', '+000-0000-0000'],
  ['Monaco', 'mc', '377', '+000-00-000-000'],
  ['Mongolia (Монгол)', 'mn', '976', '+000-00-00-0000'],
  ['Montenegro (Crna Gora)', 'me', '382', '+000-00-000-000'],
  ['Montserrat', 'ms', '1664', '+0(000)000-0000'],
  ['Morocco (‫المغرب‬‎)', 'ma', '212', '+000-00-0000-000'],
  ['Mozambique (Moçambique)', 'mz', '258', '+000-00-000-000'],
  ['Myanmar (Burma) (မြန်မာ)', 'mm', '95', '+00-000-000'],
  ['Namibia (Namibië)', 'na', '264', '+000-00-000-0000'],
  ['Nauru', 'nr', '674', '+000-000-0000'],
  ['Nepal (नेपाल)', 'np', '977', '+000-00-000-000'],
  ['Netherlands (Nederland)', 'nl', '31', '+00 00 00000000'],
  ['New Caledonia (Nouvelle-Calédonie)', 'nc', '687', '+000-00-0000'],
  ['New Zealand', 'nz', '64', '+00 000-000-0000'],
  ['Nicaragua', 'ni', '505', '+000-0000-0000'],
  ['Niger (Nijar)', 'ne', '227', '+000-00-00-0000'],
  ['Nigeria', 'ng', '234', '+000-00-000-00'],
  ['Niue', 'nu', '683', '+000-0000'],
  ['Norfolk Island', 'nf', '672', '+000-000-000'],
  ['North Korea (조선 민주주의 인민 공화국)', 'kp', '850', '+000-000-000'],
  ['Northern Mariana Islands', 'mp', '1670', '+0(000)000-0000'],
  ['Norway (Norge)', 'no', '47', '+00 000 00 000'],
  ['Oman (‫عُمان‬‎)', 'om', '968', '+000-00-000-000'],
  ['Pakistan (‫پاکستان‬‎)', 'pk', '92', '+00 000-0000000'],
  ['Palau', 'pw', '680', '+000-000-0000'],
  ['Palestine (‫فلسطين‬‎)', 'ps', '970', '+000-00-000-0000'],
  ['Panama (Panamá)', 'pa', '507', '+000-000-0000'],
  ['Papua New Guinea', 'pg', '675', '+000(000)00-000'],
  ['Paraguay', 'py', '595', '+000(000)000-000'],
  ['Peru (Perú)', 'pe', '51', '+00(000)000-000'],
  ['Philippines', 'ph', '63', '+00 000 0000'],
  // [
  //   'Pitcairn Islands',
  //   'pn',
  //   '64',
  //   ''
  // ],
  ['Poland (Polska)', 'pl', '48', '+00 000-000-000'],
  ['Portugal', 'pt', '351', '+000-00-000-0000'],
  ['Puerto Rico', 'pr', '1', '+0 (000) 000-0000', 3, ['787', '939']],
  ['Qatar (‫قطر‬‎)', 'qa', '974', '+000-0000-0000'],
  ['Réunion (La Réunion)', 're', '262', '+000-00000-0000'],
  ['Romania (România)', 'ro', '40', '+00-00-000-0000'],
  ['Russia (Россия)', 'ru', '7', '+0 000 000-00-00', 0],
  ['Rwanda', 'rw', '250', '+000(000)000-000'],
  // [
  //   'Saint Barthélemy (Saint-Barthélemy)',
  //   'bl',
  //   '590',
  //   '',
  //   1
  // ],
  ['Saint Helena', 'sh', '290'],
  ['Saint Kitts and Nevis', 'kn', '1869', '+0(000)000-0000'],
  ['Saint Lucia', 'lc', '1758', '+0(000)000-0000'],
  // [
  //   'Saint Martin (Saint-Martin (partie française))',
  //   'mf',
  //   '590',
  //   '',
  //   2
  // ],
  ['Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', 'pm', '508'],
  ['Saint Vincent and the Grenadines', 'vc', '1784', '+0(000)000-0000'],
  ['Samoa', 'ws', '685', '+000-00-0000'],
  ['San Marino', 'sm', '378', '+000-0000-000000'],
  ['São Tomé and Príncipe (São Tomé e Príncipe)', 'st', '239', '+000-00-00000'],
  [
    'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    'sa',
    '966',
    '+000-0-000-0000',
  ],
  ['Senegal (Sénégal)', 'sn', '221', '+000-00-000-0000'],
  ['Serbia (Србија)', 'rs', '381', '+000-00-000-0000'],
  ['Seychelles', 'sc', '248', '+000-0-000-000'],
  ['Sierra Leone', 'sl', '232', '+000-00-000000'],
  ['Singapore', 'sg', '65', '+00 0000-0000'],
  ['Sint Maarten', 'sx', '1721', '+0(000)000-0000'],
  ['Slovakia (Slovensko)', 'sk', '421', '+000(000)000-000'],
  ['Slovenia (Slovenija)', 'si', '386', '+000-00-000-000'],
  ['Solomon Islands', 'sb', '677', '+000-00000'],
  ['Somalia (Soomaaliya)', 'so', '252', '+000-0-000-000'],
  ['South Africa', 'za', '27', '+00-00-000-0000'],
  // [
  //   'South Georgia and the South Sandwich Islands',
  //   'gs',
  //   '500',
  //   ''
  // ],
  ['South Korea (대한민국)', 'kr', '82', '+00-00-000-0000'],
  ['South Sudan (‫جنوب السودان‬‎)', 'ss', '211', '+000-00-000-0000'],
  ['Spain (España)', 'es', '34', '+00 000 000 000'],
  ['Sri Lanka (ශ්‍රී ලංකාව)', 'lk', '94', '+00-00-000-0000'],
  ['Sudan (‫السودان‬‎)', 'sd', '249', '+000-00-000-0000'],
  ['Suriname', 'sr', '597', '+000-000-000'],
  // [
  //   'Svalbard and Jan Mayen',
  //   'sj',
  //   '47',
  //   ''
  // ],
  ['Swaziland', 'sz', '268', '+000-00-00-0000'],
  ['Sweden (Sverige)', 'se', '46', '+00 00 000 00 00'],
  ['Switzerland (Schweiz)', 'ch', '41', '+00 00 000 00 00'],
  ['Syria (‫سوريا‬‎)', 'sy', '963', '+000-00-0000-000'],
  ['Taiwan (台灣)', 'tw', '886', '+000-0000-0000'],
  ['Tajikistan', 'tj', '992', '+000-00-000-0000'],
  ['Tanzania', 'tz', '255', '+000-00-000-0000'],
  ['Thailand (ไทย)', 'th', '66', '+00-00-000-000'],
  ['Timor-Leste', 'tl', '670', '+000-000-0000'],
  ['Togo', 'tg', '228', '+000-00-000-000'],
  ['Tokelau', 'tk', '690', '+000-0000'],
  ['Tonga', 'to', '676', '+000-00000'],
  ['Trinidad and Tobago', 'tt', '1868', '+0(000)000-0000'],
  ['Tunisia (‫تونس‬‎)', 'tn', '216', '+000-00-000-000'],
  ['Turkey (Türkiye)', 'tr', '90', '+00 000 000 00 00'],
  ['Turkmenistan', 'tm', '993', '+000-0-000-0000'],
  ['Turks and Caicos Islands', 'tc', '1649', '+0(000)000-0000'],
  ['Tuvalu', 'tv', '688', '+000-00000'],
  ['U0S0 Virgin Islands', 'vi', '1340', '+0(000)000-0000'],
  ['Uganda', 'ug', '256', '+000(000)000-000'],
  ['Ukraine (Україна)', 'ua', '380', '+000(00)000-00-00'],
  [
    'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    'ae',
    '971',
    '+000-0-000-0000',
  ],
  ['United Kingdom', 'gb', '44', '+00 0000 000000'],
  ['United States', 'us', '1', '+0 (000) 000-0000', 0],
  // [
  //   'United States Minor Outlying Islands',
  //   'um',
  //   '1',
  //   ''
  // ],
  ['Uruguay', 'uy', '598', '+000-0-000-00-00'],
  ['Uzbekistan (Oʻzbekiston)', 'uz', '998', '+000-00-000-0000'],
  ['Vanuatu', 'vu', '678', '+000-00000'],
  ['Vatican City (Città del Vaticano)', 'va', '39', '+00 00 0000 0000', 1],
  ['Venezuela', 've', '58', '+00(000)000-0000'],
  ['Vietnam (Việt Nam)', 'vn', '84', '+00-00-0000-000'],
  ['Wallis and Futuna', 'wf', '681', '+000-00-0000'],
  ['Western Sahara', 'eh', '212', '+000-00-0000'],
  ['Yemen (‫اليمن‬‎)', 'ye', '967', '+000-0-000-000'],
  ['Zambia', 'zm', '260', '+000-00-000-0000'],
  ['Zimbabwe', 'zw', '263', '+000-0-000000'],
]

export const allCountriesMask = []
allCountries.forEach(item => {
  if (item[5] && Array.isArray(item[5])) {
    item[5].forEach(subItem => {
      allCountriesMask.push({
        mask: `${item[3]}`,
        country: `${item[1]}`,
        start: `${item[2]}${subItem}`,
      })
    })
  } else {
    allCountriesMask.push({
      mask: `${item[3]}`,
      country: `${item[1]}`,
      start: `${item[2]}`,
    })
  }
})

const multivariateCodes = ['1', '7']

const ruMask = {
  mask: '+0 000 000-00-00',
  country: 'ru',
  start: '7',
}

export const getMask = (value, mask, initMask) => {
  if (!value) {
    return ruMask
  }

  if (value && (!(value.indexOf(mask.start) === 0) || multivariateCodes.indexOf(mask.start) !== -1)) {
    return (
      allCountriesMask.find(item => value.indexOf(item.start) === 0) || initMask
    )
  }

  return mask
}

export const isPhone = (phone) => {
  if (phone != null) {
    const digitsArray = phone.match(/\d/g)
    if (digitsArray != null) {
      const digitsString = digitsArray.reduce((result, item) => `${result}${item}`, '')
      const availableCountries = allCountries.filter(
        item =>
          item[2] &&
          item[5] == null &&
          item[2].toString().length <= digitsString.length &&
          digitsString.substr(0, item[2].toString().length) === item[2]
      )

      if (availableCountries.length === 0) {
        return digitsArray && digitsArray.length === 11 // for Russia
      }

      const country = availableCountries.reduce((result, item) =>
        item[2].toString().length > result[2].toString().length
          ? item
          : result,
        availableCountries[0]
      )

      const template = country[3].toString().match(/\d/g)

      return template != null
        ? template.length === digitsArray.length
        : false
    } else {
      return false
    }
  }

  return true // if empty, its maybe email
}