import React from "react";
import PropTypes from "prop-types";
import Modal from "../index";
import { GuarantorCompanies } from './GuarantorCompanies';
import { GuarantorPersons } from './GuarantorPersons';

const Guarantors = React.memo(({ guarantorCompanies, guarantorPersons }) => (
  <Modal.Block iconName='shield' title='Поручители'>
    {guarantorCompanies.length > 0 && <GuarantorCompanies companies={guarantorCompanies} />}
    {guarantorPersons.length > 0 && <GuarantorPersons persons={guarantorPersons} />}
  </Modal.Block>
));

Guarantors.propTypes = {
  guarantorCompanies: PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.string,
    displayName: PropTypes.string,
    INN: PropTypes.string,
    OGRN: PropTypes.string
  })).isRequired,
  guarantorPersons: PropTypes.arrayOf(PropTypes.shape({
    inn: PropTypes.string,
    firstName: PropTypes.string,
    secondName: PropTypes.string,
    lastName: PropTypes.string,
    birthDate: PropTypes.string,
    birthPlace: PropTypes.string,
    passport: PropTypes.shape({
      number: PropTypes.string,
      series: PropTypes.string,
      issuingAuthority: PropTypes.string,
      issuingAuthorityCode: PropTypes.string,
      issuedDate: PropTypes.string
    }),
    registrationAddress: PropTypes.string,
    actualAddress: PropTypes.string,
    contactsList: PropTypes.arrayOf(PropTypes.shape({
      number: PropTypes.string,
      email: PropTypes.string
    })),
    citizenship: PropTypes.string
  })).isRequired
}

export default Guarantors;
