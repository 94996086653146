import React from 'react'
import injectSheet from 'react-jss'
import Modal from '../index'
import { COLORS } from '../../../constants/styleVariables'

const styles = {
  right: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  item: {
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: '50px',
    },
  },
  title: {
    fontSize: '14px',
  },
  link: {
    color: COLORS.textLinkColor,
    textDecoration: "none",
    "&:hover": {
      color: COLORS.textLinkColor,
      textDecoration: "underline"
    }
  }
}

const Principal = ({
  classes,
  name,
  inn,
  kpp,
  id
}) =>
  <Modal.Block iconName='shield' title='Принципал'>
    <Modal.Content>
      <Modal.Value>
        <a
          href={`/clients/${id}`}
          target='_blank'
          rel='noopener noreferrer'
          className={classes.link}
        >
          {name}
        </a>
        <div className={classes.right}>
          <div className={classes.item}>
            <Modal.Value>{inn}</Modal.Value>
            <Modal.Title className={classes.title}>ИНН</Modal.Title>
          </div>
          <div className={classes.item}>
            <Modal.Value>{kpp}</Modal.Value>
            <Modal.Title className={classes.title}>КПП</Modal.Title>
          </div>
        </div>
      </Modal.Value>
      <Modal.Title>Наименование принципала</Modal.Title>
    </Modal.Content>
  </Modal.Block>

export default injectSheet(styles)(Principal)
