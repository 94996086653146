import React from 'react';
import { useSelector } from 'react-redux'
import { Modal } from '../../../layout'
import { getGuarantorCompanies, getGuarantorPersons } from '../../../redux/Company/selectors'

export const Guarantors = React.memo(() => {
  const guarantorCompanies = useSelector(getGuarantorCompanies);
  const guarantorPersons = useSelector(getGuarantorPersons);

  return <Modal.Guarantors guarantorCompanies={guarantorCompanies} guarantorPersons={guarantorPersons} />
})

