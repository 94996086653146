import React, { useState } from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Modal } from './../../index'
import Icon from './../../Icons'

const styles = {
	root: {
    background: '#fff',
    fontFamily: 'Montserrat',
		position: 'relative',
  },
  list: {
    paddingTop: '20px',
    paddingBottom: '20px',
    position: 'relative',
  },
  item: {
    position: 'relative',
    '& + &': {
      marginTop: '20px',
    },
    '&.isMy': {
      textAlign: 'right',
    },
  },
  message: {
    background: '#fff',
    width: '580px',
    padding: '15px 20px',
    border: '1px solid #F4F4F4',
    borderRadius: '12px',
    display: 'inline-block',
    verticalAlign: 'top',
    '.isMy &': {
      background: '#F4F4F4',
    },
  },
  author: {
    marginBottom: '6px',
    '& svg': {
      fill: '#9498A9',
      width: '24px',
      height: '24px',
      marginRight: '8px',
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& span': {
      fontSize: '14px',
      lineHeight: '24px',
      color: '#000',
      fontWeight: 600,
      fontStyle: 'normal',
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  text: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  button: {
    background: '#22C7B5',
    marginTop: '15px',
    padding: '6px 20px',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#fff',
    borderRadius: '8px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  date: {
    marginTop: '15px',
    textAlign: 'right',
    fontSize: '10px',
    lineHeight: '18px',
    color: '#b8b8b8',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    '.isMy &': {
      color: '#4a4a4a',
    },
  },
  fieldset: {
    display: 'none',
    position: 'relative',
  },
  input: {
    background: '#fff',
    width: '100%',
    height: '40px',
    padding: '11px 48px 11px 16px',
    border: '1px solid #ddd',
    borderRadius: '16px',
    outline: 'none',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#000',
    fontWeight: 500,
    fontFamily: 'Montserrat',
  },
  submit: {
    position: 'absolute',
    right: '12px',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    '& svg': {
      fill: '#22C7B5',
      width: '20px',
      height: '22px',
      display: 'block',
      pointerEvents: 'none',
    },
  },
}

const Comments = ({ items, classes }) => {
  const [value, setValue] = useState('')

  const onChange = (e) => setValue(e.target.value)

  const isItemsIsset = items && items.length

  if (!isItemsIsset) {
    return null
  }

  return (
    <Modal.Block iconName='comments' title='Комментарии'>
      <div className={classes.root}>
        <div className={classes.list}> 
          {items.map(({ message, authorName, isMy, postedDateTime, taskName }, index) => 
            <div className={classnames(classes.item, { isMy })} key={`comment-${index}`}>
              <div className={classes.message}>
                <div className={classes.author}>
                  <Icon iconName='user' />
                  <span>{authorName}</span>
                </div>
                <div className={classes.text}>{message}</div>
                <div className={classes.button}>{taskName}</div>
                <div className={classes.date}>{postedDateTime}</div>
              </div>
            </div>
          )}
        </div>
        <div className={classes.fieldset}>
          <input 
            type='text'
            className={classes.input}
            onChange={onChange}
            value={value}
            placeholder='Текст комментария'
          />
          <div className={classes.submit}>
            <Icon iconName='letter' />
          </div>
        </div>
      </div>
    </Modal.Block>
  )
}

export default injectSheet(styles)(Comments)