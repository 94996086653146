import React, { useCallback } from 'react'
import { change } from 'redux-form'
import { InputPhone } from '../InputPhone/InputPhone'

export const FormInputPhone = ({
  input: {
    name,
    value,
    onBlur,
    ...inputProps
  },
  meta: {
    form,
    dispatch,
    touched,
    error,
  },
  ...rest
}) => {
  const onBlurWithSetValue = useCallback((event, value) => {
    onBlur(event)
    dispatch(change(form, name, value))
  }, [onBlur, dispatch])

  return (
    <div>
      <InputPhone
        name={name}
        value={value}
        {...rest}
        {...inputProps}
        onBlurWithSetValue={onBlurWithSetValue}
      />
      {touched && error &&
        <span className='form-error' style={{ marginTop: 2 }}>
          {error}
        </span>
      }
    </div>
  )
}
