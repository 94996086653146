import axios from 'axios';
import { SERVER } from '../constants';

export const getStoriesInfo = async (userLogin) => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/getStoriesInfo/${userLogin}`,
        });
        if (Object.keys(data).length) {
            return {
            showNewYearStories: true,
            isSuccess: true,
                ...data,
            };
        }
        return {
            showNewYearStories: false,
            isSuccess: true,
        };
    } catch (err) {
        return {
            message: err.message,
            isSuccess: false,
        }
    }
};
