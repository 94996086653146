import { createSelector } from 'reselect';
import * as settingsSelectors from '#/src/redux/Settings/selectors';
import { prepareOrderStatusCategory } from '#/src/utils/prepareOrderStatusCategory';
import * as userSelectors from '#/src/redux/User/selectors';
import { getShowNewYearStories} from '../Stories/selectors';

export const getTasks = ({ Tasks }) => Tasks;

export const getActiveTask = (state, { id }) => {
  const { _byIds, activeTaskId } = getTasks(state);
  return _byIds?.[id || activeTaskId];
};

export const getStatusItems = createSelector(
  userSelectors.getStatusItems,
  settingsSelectors.getOrderStatusCategory,
  (statusItems, orderStatusCategory) => prepareOrderStatusCategory({ statusItems, orderStatusCategory })
);

export const getTasksViewModel = createSelector(
  getTasks,
  settingsSelectors.getSettings,
  userSelectors.getProcessDefinitionKeys,
  getStatusItems,
  userSelectors.getUserName,
  getShowNewYearStories,
  ({
     isFetching,
     isFetchingNext,
     order,
     filters,
     page,
     more,
     amount_min,
     amount_max,
     clientName
   }, { orderStatusCategory, user }, processDefinitionKeys, statusItems, username, showNewYearStories) => ({
    isFetching,
    isFetchingNext,
    list: order,
    filters: { ...filters, orderStatusCategory },
    statusItems,
    nextPage: page + 1,
    hasMorePage: more,
    processDefinitionKeys,
    filterAmount: { from: amount_min, to: amount_max },
    ismanager: user?.ismanager,
    orderStatusCategory,
    defaultClientValue: clientName,
    username,
    showNewYearStories,
  })
);
