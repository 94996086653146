import React from 'react';
import cupIcon from '#/src/static/img/cup.svg';

import { usePromoRating } from './usePromoRating';

import { styles } from './styles';
import { createUseStyles } from 'react-jss';
import { Place } from './Place'
import Dropdown from '#/src/components/Dropdown';
import { RATING_TYPES_OPTIONS } from './constants'

const useStyles = createUseStyles(styles);

export const PromoRating = React.memo(() => {
  const {
    currentRating,
    currentPlace,
    handleChangeSelectedRatingType,
    showSelectType,
    ratingDescription
  } = usePromoRating();
  const styles = useStyles();

  if (!currentRating || !currentPlace) {
    return null;
  };

  return (
    <div className={ styles.plate }>
      { showSelectType && (
        <Dropdown
          name="ratingTypePicker"
          toggleClassName={ styles.ratingTypePicker }
          defaultText="Рейтинг"
          list={ RATING_TYPES_OPTIONS }
          onSelectItem={ handleChangeSelectedRatingType }
        />
      ) }
      <div className={ styles.content }>
        <p>{ ratingDescription }</p>
          <div
              className={ styles.place }
            >
              <img
                src={ cupIcon }
                width={ 24 }
                alt='cup'
              />
              <span>ТОП-{ currentRating.topLimit }</span>
          </div>
          <Place current={ currentPlace } rating={ currentRating } />
      </div>
    </div>
  );
});
