import React from 'react'
import injectSheet from 'react-jss'
import { Icon } from './../../../layout'

const styles = {
	root: {
		background: '#EB9A42',
		marginTop: '20px',
		padding: '9px 24px 7px 57px',
		borderRadius: '8px',
		color: '#fff',
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: 600,
    display: 'inline-block',
    position: 'relative',
    '&:hover': {
      color: '#fff',
      textDecoration: 'none',
    },
    '& svg': {
      fill: '#fff',
      width: '24px',
      height: '24px',
      display: 'block',
      position: 'absolute',
      left: '23px',
      top: '7px',
    },
  },
}

const DownloadAllButton = ({ file, classes }) =>  
  <a 
    className={classes.root} 
    href={file.url} 
    target='_blank' 
    download={file.name} 
    rel='noopener noreferrer'
  >
    <Icon iconName='download-all' />
    Скачать документы
  </a>

export default injectSheet(styles)(DownloadAllButton)