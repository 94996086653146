import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { logoutUser } from '../../redux/User/actions'
import Icon from '../../layout/Icons'
import { onLogout } from '../../utils/tokenStorage'
import { IS_NEW_AUTH, THEME_COLOR, adaptive_1200 } from '../../config'

const styles = {
  root: {
    // background: '#FF9A8D',
    background: THEME_COLOR,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  logo: {
    backgroundPosition: 'top left',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    left: 70,
    top: 70,
    [adaptive_1200]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      left: 'auto',
      top: 'auto',
      marginTop: 30,
    },
  },
  error: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  message: {
    width: 700,
    marginTop: 60,
    textAlign: 'center',
    color: '#fff',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1.6,
    [adaptive_1200]: {
      width: 280,
      marginTop: 30,
      fontSize: 16,
      lineHeight: 1.5,
    },
  },
  logout: {
    marginTop: 20,
    color: '#fff',
    borderBottom: [1, 'solid', '#fff'],
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
  },
  icon: {
    width: 150,
    height: 150,
    [adaptive_1200]: {
      width: 100,
      height: 100,
    },
  },
}

const ErrorPage = ({ 
  classes,
  errorMessage
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { authType } = useSelector(state => state.User.settings)

  const _onLogout = () => IS_NEW_AUTH
    ? onLogout()
    : dispatch(logoutUser(authType))

  const isErrorPage = /error/.test(window.location.pathname)

  const parsedQueryParams = queryString.parse(
    history &&
    history.location &&
    history.location.search
  )
  const message = isErrorPage && parsedQueryParams.message
    ? parsedQueryParams.message
    : errorMessage

  const defaultError = 'Авторизация не удалась. Обратитесь к администратору.'
  
  return (
    <div className={classes.root}>
      <div className={classnames(classes.logo, 'verify-logo')} />
      <div className={classes.error}>
        <Icon iconName='sad-face' className={classes.icon} />
        <div className={classes.message}>
          {message || defaultError}
          <br/>
          <div className={classes.logout} onClick={_onLogout}>Выход</div>
        </div>
      </div>
    </div>
  )
}

export default injectSheet(styles)(ErrorPage)