import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Tooltip } from 'reactstrap';
import Modal from '../index';
import Icon from './../../Icons'

const useStyles = createUseStyles({
  trackingNumber: {
    marginTop: '15px'
  },
  swiftMessage: {
    marginTop: '15px',
  },
  copyButton: {
    border: 'none',
    cursor: 'pointer',
    background: 'none',
  },
  copyIcon: {
    height: '16px',
    width: '16px',
    marginTop: '-3px',
  },
  copySuccessIcon: {
    height: '20px',
    width: '20px',
    marginTop: '-10px',
    marginLeft: '-5px',
  },
  tooltip: {
    zIndex: 30001,
  },
});

const renderTextWithNewLineToBr = (text) => text.split("\n").map(function(item) {
  return (
    <span>
      {item}
      <br/>
    </span>
  )
});

export const Delivery = ({ deliveryTypeTitle, trackingNumber, swiftMessage }) => {
  const classes = useStyles();
  const [isShowCopySuccess, setIsShowCopySuccess] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleToggleTooltip = () => setIsTooltipOpen((prevIsTooltipOpen) => !prevIsTooltipOpen);

  const handleCopySwiftMessage = () => {
    setIsShowCopySuccess(true);
    navigator.clipboard.writeText(swiftMessage).then(() => {
      setTimeout(() => {
        setIsShowCopySuccess(false);
      }, 1500);
    });
  }

  return (
    <Modal.Block iconName='request' title='Параметры доставки гарантии'>
      <Modal.Content>
        <Modal.Title>Способ доставки</Modal.Title>
        <Modal.Value>{deliveryTypeTitle}</Modal.Value>
        {trackingNumber && (
          <div className={classes.trackingNumber}>
            <Modal.Title>Номер отслеживания</Modal.Title>
            <Modal.Value>{trackingNumber}</Modal.Value>
          </div>
        )}
        {swiftMessage && (
          <div className={classes.swiftMessage}>
            <Modal.Title>
              Текст swift сообщения 

              <Tooltip 
                popperClassName={classes.tooltip}
                isOpen={ isTooltipOpen }
                toggle={ handleToggleTooltip }
                target='copySwiftMessageButton'
                placement="top"
                fade={false}
              >
                Скопировать
              </Tooltip>

              <button
                id='copySwiftMessageButton'
                onClick={handleCopySwiftMessage}
                type="button"
                className={ classes.copyButton }
              >
                { isShowCopySuccess ? 
                  <Icon iconName="check" className={ classes.copySuccessIcon } /> :
                  <Icon iconName="copy" className={ classes.copyIcon } /> 
                }
              </button>
          </Modal.Title>
          <Modal.Value>{renderTextWithNewLineToBr(swiftMessage)}</Modal.Value>
        </div>
        )}
      </Modal.Content>
    </Modal.Block>
  );
};

Delivery.propTypes = {
  deliveryTypeTitle: PropTypes.string.isRequired,
  trackingNumber: PropTypes.string
};
