import React from 'react'
import { Route, Router, Switch, Redirect } from 'react-router-dom'
import { history } from './history'
import LayoutBase from './containers/Layout'
import Agents from './pages/Agents'
import Calculator from './pages/Calculator'
import Clients from './pages/Clients'
import LoginBase from './pages/Login'
import NotFound from './pages/NotFound'
import Register from './pages/Register'
import { RegisterToken } from './pages/RegisterToken'
import Tasks from './pages/Tasks'
import ErrorPage from './pages/ErrorPage'
import { ErrorBoundary } from './components/ErrorBoundary'

export default ({ isKeycloakAuthType }) => {
  const Login = LoginBase
  const Layout = LayoutBase

  const ToTasks = () => <Redirect to='/tasks' />

  return (
    <Router history={history}>
      <ErrorBoundary>
        <Switch>
          {isKeycloakAuthType
            ? <Layout exact path='/' component={ToTasks} />
            : <Route exact path='/' component={Login} />
          }
          <Route exact path='/register/:token' component={RegisterToken} />
          <Route exact path='/register' component={Register} />
          <Route path='/error' component={ErrorPage} />
          <Layout path='/tasks/:id' component={Tasks} />
          <Layout path='/tasks' component={Tasks} />
          <Layout path='/clients/:id' component={Clients} />
          <Layout path='/clients' component={Clients} />
          <Layout path='/agents/:agent/users/new' component={Agents} />
          <Layout path='/agents/:agent/users/:user' component={Agents} />
          <Layout path='/agents/:agent/users' component={Agents} />
          <Layout path='/agents' component={Agents} />
          <Layout path='/calc' component={Calculator} />
          <Layout component={NotFound} isNotFound />
        </Switch>
      </ErrorBoundary>
    </Router>
  )
}
