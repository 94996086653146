export const getRomanianPlace = (place) => {
    if (place === 1) {
        return 'I';
    }

    if (place === 2) {
        return 'II';
    }

    return 'III';
};

export const reducePodiumRating = (rating) =>
    rating.positions.reduce(
        (acc, item) => {
            if (item.place <= 3) {
                acc.medalists.push(item);
            } else {
                acc.others.positions.push(item);
            }

            return acc;
        },
        {
          medalists: [],
          others: {
            ratingType: rating.ratingType,
            positions: [],
          }
        },
    );
