import React from 'react';

import { usePlace } from './usePlace';
import { Tooltip } from 'reactstrap'
import informationIcon from '#/src/static/img/information.svg'

export const Place = ({ current, rating }) => {
  const { shouldShowTooltip, RatingComponent, isTooltipOpen, handleTooltipOpen } = usePlace(rating);

  return (
    <div style={ { display: 'flex', alignItems: 'center' } }>
      <span style={ { marginRight: '8px' } }>{ current.place } место</span>
      { shouldShowTooltip && (
        <div>
          <Tooltip
            style={ { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', maxWidth: 'none' } }
            isOpen={ isTooltipOpen }
            toggle={ handleTooltipOpen }
            target='information'
            placement="right"
          >
            <RatingComponent rating={ rating } currentPlace={ current.place } />
          </Tooltip>
          <img
            id='information'
            src={ informationIcon }
            width={ 24 }
            alt={ 'cup' }
          />
        </div>
      ) }
    </div>
  );
};
