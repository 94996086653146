import React from 'react';
import { useSelector } from 'react-redux'
import { Modal } from '../../../layout'
import { getOrderComments } from '../../../redux/Company/selectors'

export const Comments = () => {
  const comments = useSelector(getOrderComments)

  return <Modal.Comments items={comments} />
}

