import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss"
import Title from "../Title";
import { styles } from './styles'

const useStyles = createUseStyles(styles);

export const Field = ({ title, children, className }) => {
  const classes = useStyles();

  return <div className={className}>
    <Title>{title}</Title>
    <p className={classes.fieldValue}>{children || "—"}</p>
  </div>
};

Field.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string
};
