import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Icon } from '../../layout'
import { HIDE_CHANGE_PASSWORD, IS_NEW_AUTH } from './../../config'
import { onLogout } from './../../utils/tokenStorage'

class UserMenu extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onLogout: PropTypes.func.isRequired,
  }

  state = { 
    isOpen: false, 
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleOutsideClick)
  }
  
  handleToggleMenu = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
      document.removeEventListener('click', this.handleOutsideClick)
    } else {
      this.setState({ isOpen: true })
      document.addEventListener('click', this.handleOutsideClick)
    }
  }

  handleLogout = () => IS_NEW_AUTH
    ? onLogout()
    : this.props.onLogout()

  handleOutsideClick = ({ target }) => {
    if (this.menu && this.menu.contains(target)) return     
    this.handleToggleMenu()
  }

  render() {
    const { name, changePasswordLink } = this.props
    const { isOpen } = this.state

    return (
      <div className={classnames('fr-user-menu', {'open': isOpen})} ref={node => this.menu = node}>
        <div className='fr-user-menu__dropdown'>
          <div className='fr-user-menu__list'>
            {/* <Link className='fr-user-menu__item' to="?restore-password">
              <i className='icon icon-settings' />
              Настройки
            </Link> */}
            {!HIDE_CHANGE_PASSWORD && 
              <a className='fr-user-menu__item' href={changePasswordLink}>
                <i className='icon icon-settings' />
                Поменять пароль
              </a>
            }
            <span className='fr-user-menu__item' onClick={this.handleLogout}>
              <i className='icon icon-exit' />
              Выход
            </span>
          </div>
        </div>
        <div className='fr-user-menu__main' onClick={this.handleToggleMenu}>
          <div className='fr-user-menu__name'>
            <Icon iconName='user2' />
            {name}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ Settings }) => {
  return {
    changePasswordLink: Settings.changePasswordLink,
  }
}

export default connect(mapStateToProps)(UserMenu)