import axios from 'axios'
import { SERVER } from '../constants'
import { prepareFiltersToQuery } from '../utility'

export const getTasksList = async (filters = {}, limit = 20, { cancelToken }) => {
  try {
    const query = prepareFiltersToQuery(filters)
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/order/list?limit=${limit}${query}`,
      cancelToken
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
      err
    }
  }
}

export const getNextPage = async (page, filters = {}, limit = 20) => {
  try {
    const query = prepareFiltersToQuery(filters)
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/order/list?page=${page}&limit=${limit}${query}`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

export const assignTask = async taskId => {
  try {
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/task/claim?task_id=${taskId}`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

export const getNextTask = async taskId => {
  try {
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      method: 'GET',
      params: { taskId },
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/task/getNext`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

export const getNextProcessTask = async processInstanceId => {
  try {
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      method: 'GET',
      params: { processInstanceId },
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/task/getNext`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

export const getTaskUsers = async taskId => {
  try {
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/manager/task/getUsers?task_id=${taskId}`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

export const changeTasksUser = async data => {
  try {
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      data,
      method: 'POST',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/manager/task/change`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}
