import React from 'react'
import PropTypes from 'prop-types'
import Item from './Item'

const CheckboxList = ({
  activeValue,
  onChangeItem,
  statusItems,
}) =>
  <div className='checkbox-list'>
    {statusItems.map(({ key, text, className, orderStatus: descendants }, index) => {
      if (key === 'total' || key === 'assigned') {
        return null
      }
      return (
        <Item
          key={index}
          onChange={onChangeItem}
          name='status'
          value={key}
          text={text}
          isChecked={
            activeValue === key ||
            (descendants && descendants.reduce((result, { key: dKey }) => result || activeValue === dKey, false))
          }
          className={className}
          descendants={descendants}
          activeValue={activeValue}
        />
      )
    })}
    <Item
      key={statusItems.length}
      onChange={onChangeItem}
      name='status'
      value='all'
      title='Все'
      text='Все'
      className='white'
    />
  </div>

CheckboxList.propTypes = {
  activeValue: PropTypes.string,
  onChangeItem: PropTypes.func.isRequired,
  statusItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      className: PropTypes.string
    })
  )
}

CheckboxList.defaultProps = {
  activeValue: '',
  statusItems: [],
}

export default React.memo(CheckboxList);
