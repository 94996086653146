import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { formatNumber } from '../../../utils'
import { adaptive_1200 } from '../../../config'

const styles = {
  root: {
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    [adaptive_1200]: {
      display: 'block',
    },
  },
  item: {
    background: '#fff',
    padding: [12, 40, 12, 24],
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    '& + &': {
      marginLeft: 10,
      [adaptive_1200]: {
        marginTop: 10,
        marginLeft: 0,
      },
    },
  },
  title: {
    color: '#9B9B9B',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: '0.5px',
    opacity: 0.64,
  },
  value: {
    marginTop: 8,
    color: '#242232',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 1.2,
  },
}

const ClientsStatsPanel = ({
  classes,
  companiesStat,
  statusItems,
}) => {
  const { count = 0, order = {} } = companiesStat
  const { total = {} } = order
  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <span className={classes.title}>Клиенты</span>
        <span className={classes.value}>{formatNumber(count)}</span>
      </div>
      <div className={classes.item}>
        <span className={classes.title}>Сделки</span>
        <ul className='deals-list'>
          {statusItems.map(({ key, text, className }) => order[key]
            ? <li
                key={key}
                className={classnames('deals-list__item', {
                  [`deals-list__item--${className}`]: className,
                })}
                title={text}
              >
                {formatNumber(order[key].count)}
              </li>
            : null
          )}
        </ul>
      </div>
      <div className={classes.item}>
        <span className={classes.title}>Сумма</span>
        <span className={classes.value}>
          {`${formatNumber(total.amount ? total.amount : 0, true)} ₽`}
        </span>
      </div>
    </div>
  )
}

ClientsStatsPanel.propTypes = {
  companiesStat: PropTypes.shape({
    count: PropTypes.number,
    order: PropTypes.object,
  }),
}

ClientsStatsPanel.defaultProps = {
  companiesStat: {
    count: 0,
    order: {},
  },
  statusItems: [],
}


export default injectSheet(styles)(ClientsStatsPanel)