import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '../../../layout';
import { getOrderDelivery } from '../../../redux/Company/selectors';

export const Delivery = React.memo(() => {
  const { deliveryTypeTitle, trackingNumber, swiftMessage } = useSelector(getOrderDelivery);

  return <Modal.Delivery deliveryTypeTitle={deliveryTypeTitle} trackingNumber={trackingNumber} swiftMessage={swiftMessage} />;
});

