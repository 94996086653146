import React, { Fragment, useState, useEffect } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { useRegisterStyles } from './styles'
import { registerTokenAction } from './../../redux/Register/actions'
import Overlay from './../../components/Overlay'
import { ModalAlternative } from '../../containers/ModalAlternative/ModalAlternative'

import like from '../../static/img/like.svg'
import likeSub from '../../static/img/likesub.svg'
import stopIcon from '../../static/img/stop.svg'

export const RegisterToken = () => {
  const classes = useRegisterStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { token } = useParams()

  const { isFetching, error: errorText } = useSelector(state => state.Register)

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  
  useEffect(() => {
    const onSuccess = () => setSuccess(true)
    const onError = () => setError(true)
    dispatch(registerTokenAction({ token }, onSuccess, onError))
  }, [])

  const onRedirect = () => history.push('/tasks')

  const SuccessContent = () =>
    <Fragment>
      <div className={classes.logoContainer}>
        <img className={classes.background} iconName='success-background' src={likeSub} alt='' />
        <img className={classes.icon} iconName='success' src={like} alt='' />
      </div>
      <h1 className={classes.h1}>Ваш e-mail подтвержден.</h1>
    </Fragment>

  const ErrorContent = () =>
    <Fragment>
      <div className={classes.logoContainer}>
        <img className={classes.stopIcon} src={stopIcon} alt='' />
      </div>
      <div className={classnames(classes.p, classes.pError)}>
        {errorText}
      </div>
    </Fragment>

  const result = success || error

  return isFetching
    ? <Overlay />
    : <Fragment>
        {result &&
          <ModalAlternative
            modalClass={classes.modal}
            contentClass={classes.contentClass}
            onClose={onRedirect}
          >
            {success && <SuccessContent />}
            {error && <ErrorContent />}
          </ModalAlternative>
        }
      </Fragment>
}