import React from 'react'
import { Documents } from './Documents'
import { Parameters } from './Parameters'
import { Comments } from './Comments'
import { MainInfo } from './MainInfo'
import { Guarantors } from './Guarantors'
import { Delivery } from './Delivery';

export const OrderContent = ({ activeSection }) => {
  switch (activeSection) {
    case 'dealDocuments':
      return <Documents documentsType='deal' />
    case 'principalDocuments':
      return <Documents documentsType='principal' />
    case 'parameters':
      return <Parameters />
    case 'comments':
      return <Comments />
    case 'guarantors':
      return <Guarantors />
    case 'delivery':
      return <Delivery />
    default:
      return <MainInfo />
  }
}
