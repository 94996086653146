import React, { useState } from "react"

const SliderImage = ({ src, isActive }) => {
	const [loading, setLoading] = useState(true);
	const imageLoaded = () => {
		setLoading(false)
	}

  return (
		<>
			<img 
				style={{display: loading ? "none" : "block"}}
				src={ src} alt="Фоновая картинка"
				className="slider-image"
				onLoad={ imageLoaded }
			/>
			<div className="slider-lazy-load" style={{display: loading ? "flex" : "none"}}>
				{ isActive && (
					<div className="slider-loader" />
				) }
			</div>
		</>
  );
}

export default SliderImage