import { createSelector } from 'reselect'
import { formattedDate, formattedNumber, plural } from '../../utils'
import { ORDER_MENU_SECTIONS } from './constants'

const getCompany = (state) => state.Company;

const getOrder = (state) => getCompany(state).order;

const getIsOrderFetching = (state) => getCompany(state).isFetching

const getBankGuaranteeInfo = createSelector(
  getOrder,
  ({ orderTypeRefId, bankguarantee = {}, bankguaranteeReissue = {}, bankguaranteeLimit = {} }) => {
    switch (orderTypeRefId) {
      case 'bankguarantee':
        return bankguarantee;
      case 'bankguaranteeReissue':
        return bankguaranteeReissue;
      case 'bankguaranteeLimit':
        return bankguaranteeLimit;
      default:
        return {};
    }
});

const getOrderInfoContent = createSelector(
  getBankGuaranteeInfo,
  getOrder,
  ({
     durationDays,
     endDate,
     federalLaw,
     amount = 0,
     maxAmount = 0,
     lot = [],
     purchaseMarketPlace,
     purchaseNumber,
     purchaseSubject,
     purchaseTenderType,
     startDate,
     type,
     finalAmount,
  }, { documents = [] }) => {
    const documentsWithoutLots = documents.filter(item => item.lot);

    return {
      info: [
        {
          items: [
            { title: 'Вид банковской гарантии', value: type || '-', },
          ]
        },
        {
          items: [
            { title: 'Реестровый № торгов', value: purchaseNumber || '-', },
          ]
        },
        {
          items: [
            { title: 'Площадка', value: purchaseMarketPlace || '-', },
            { title: 'Закон', value: federalLaw || '-', right: true, },
          ]
        },
        {
          items: [
            { title: 'Предмет контракта / предмет закупки', value: purchaseSubject || '-', },
          ]
        },
        {
          items: [
            { title: 'Тип закупки', value: purchaseTenderType || '-', },
            { title: 'Предложенная цена', value: finalAmount ? `${formattedNumber(finalAmount)} ₽` : '-', right: true }
          ]
        },
      ],
      data: [
        { title: 'Дата начала', value: startDate ? formattedDate(startDate) : '-', },
        { title: 'Дата окончания', value: endDate ? formattedDate(endDate) : '-', },
        {
          title: 'Срок бг',
          value: durationDays ? `${durationDays} ${plural(durationDays, 'день', 'дня', 'дней')}` : '-',
        },
        { title: 'Итого НМЦ', value: maxAmount ? `${formattedNumber(maxAmount)} ₽` : '-' },
        { title: 'Итоговая гарантия', value: amount ? `${formattedNumber(amount)} ₽` : '-' },
      ],
      lots: lot,
      documents: documentsWithoutLots,
    }
  }
);

const getOrderHeader = createSelector(
  getOrder,
  ({ orderTypeRefId, orderNumber }) => {
    switch (orderTypeRefId) {
      case 'bankguarantee':
        return `Банковская гарантия №${orderNumber || '-'}`;
      case 'bankguaranteeReissue':
        return `Перевыпуск БГ №${orderNumber || '-'}`;
      case 'bankguaranteeLimit':
        return `Заявка на лимит БГ №${orderNumber || '-'}`;
      default:
        return '-';
    }
  });

export const getOrderParameters = (state) => getOrder(state).parameters || [];

export const getOrderComments = (state) => getOrder(state).comments || [];

export const getGuarantorCompanies = (state) => getOrder(state).guarantorCompanies || [];

export const getGuarantorPersons = (state) => getOrder(state).guarantorPersons || [];

export const getOrderDelivery = (state) => getOrder(state).delivery || {}

const getAvailableOrderMenuSections = createSelector(
  getGuarantorCompanies,
  getGuarantorPersons,
  (guarantorCompanies, guarantorPersons) => {
    const hasGuarantors = guarantorPersons.length > 0 && guarantorPersons.length > 0;

    return ORDER_MENU_SECTIONS.filter(({ id }) => id === 'guarantors' ? hasGuarantors : true)
  }
)

export const getDocumentsViewModel = createSelector(
  (_, { documentsType }) => documentsType,
  getOrder,
  (documentsType, { emailToken, documents = [], principalDocuments = [] }) => ({
    documents: documentsType === 'principal' ? principalDocuments : documents.filter((item) => !item.lot),
    emailToken: documentsType === 'principal' ? emailToken : undefined,
    title: documentsType === 'principal' ? 'Документы принципала' : 'Документы по сделке'
  })
)

export const getOrderMainInfoViewModel = createSelector(
  getOrder,
  getOrderInfoContent,
  getBankGuaranteeInfo,
  ({ orderTypeRefId, principal }, orderInfoContent, { calculatedAmount, finalAmount }) => {
    return {
      orderInfoContent,
      calculatedAmount,
      finalAmount,
      principalId: principal?.id,
      principalInn: principal?.INN || '-',
      principalKpp: principal?.KPP || '-',
      principalName: principal?.displayName || '-',
      isGuarantee: orderTypeRefId === 'bankguarantee',
      isReissue: orderTypeRefId === 'bankguaranteeReissue',
      isLimits: orderTypeRefId === 'bankguaranteeLimit'
    }
  }
)

export const getOrderViewModel = createSelector(
  getIsOrderFetching,
  getOrderHeader,
  getAvailableOrderMenuSections,
  (isFetching, header, menuSections) => ({
    menuSections,
    header,
    isFetching
  })
)
