import React, { PureComponent } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { KeycloakProvider } from 'react-keycloak'
import keycloak from './keycloak'
import './static/scss/style.scss'
import { Theme } from './themes'
import Overlay from './components/Overlay'
import AppRoutes from './routes.js'
import store from './redux/configureStore'
import { getAppSettings, authenticationUser } from './redux/User/actions'
import { User } from './services/api'
import { SET_JWT_OCCURRED } from './redux/User/actionTypes'
import { KeycloakAuthWrapper } from './KeycloakAuthWrapper'
import {
  isValidToken,
  onLogin,
  onLogout,
  setToken,
} from './utils/tokenStorage'
import { IS_NEW_AUTH, KEYCLOAK_AUTH_TYPE } from './config'

class App extends PureComponent {
  state = {
    settingsFetch: true,
    settings: {},
    error: false,
  }

  componentDidMount() {
    const { settings, settingsFetch } = store.getState().User

    this.setState({
      settings,
      settingsFetch,
    })

    this.unsubscribe = store.subscribe(this.onUpdateAppSettings)
    store.dispatch(getAppSettings())


    this.isStaticPage =
      /verify/.test(window.location.pathname) ||
      /register/.test(window.location.pathname) ||
      /error/.test(window.location.pathname)
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onUpdateAppSettings = () => {
    const { settingsFetch: prevSettingsFetch } = this.state
    const { settings, settingsFetch } = store.getState().User

    if (prevSettingsFetch !== settingsFetch && !settingsFetch) {
      this.setState({
        settings,
        settingsFetch,
      })
      this.unsubscribe()
    }
  }

  onAuthError = () => this.setState({ error: true })

  onError = (props) => console.log(props)
  onToken = (token) => setToken(token)
  onEvent = async (event) => {
    console.log(event)
    const loginEvents = ['onReady', 'onAuthError', 'onAuthRefreshError']
    const logoutEvents = ['onAuthLogout']
    if (loginEvents.indexOf(event) > -1 && !isValidToken()) {
      onLogin()
    }
    if (logoutEvents.indexOf(event) > -1) {
      onLogout()
    }
    if (event === 'onAuthSuccess') {
      if (!IS_NEW_AUTH) {
        await User.setJWT(keycloak.token)
        store.dispatch({ type: SET_JWT_OCCURRED })
      }
      store.dispatch(authenticationUser({
        onError: this.onAuthError,
      }))
    }
  }

  renderApp = (isKeycloakAuthType) => {
    const { error } = this.state
    return (
      <Provider store={store}>
        <Theme>
          <KeycloakAuthWrapper
            isKeycloakAuthType={isKeycloakAuthType}
            error={error}
            onError={this.onAuthError}
          >
            <AppRoutes isKeycloakAuthType={isKeycloakAuthType}/>
          </KeycloakAuthWrapper>
        </Theme>
      </Provider>
    )
  }

  render() {
    const { settings, settingsFetch } = this.state

    if (settings.authType === 'standard' || this.isStaticPage) {
      return this.renderApp(false)
    }

    if (settings.authType === 'keycloak') {
      return IS_NEW_AUTH
        ? <KeycloakProvider
            keycloak={keycloak}
            initConfig={{
              // onLoad: 'login-required',
              onLoad: KEYCLOAK_AUTH_TYPE,
              silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            }}
            onError={this.onError}
            onToken={this.onToken}
            onEvent={this.onEvent}
          >
            {this.renderApp(true)}
          </KeycloakProvider>
        : this.renderApp(true)
    }

    if (!settingsFetch) {
      return (
        <div
          style={{
            margin: '0 auto',
            width: '80%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: '72px',
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          Ошибка загрузки настроек приложения!
        </div>
      )
    }

    return <Overlay size='big'/>
  }
}

render(<App />, document.getElementById('root'))
