export const ORDER_MENU_SECTIONS = [
  {
    title: 'Основная информация',
    id: 'mainInfo'
  },
  {
    title: "Документы по сделке",
    id: "dealDocuments"
  },
  {
    title: "Документы принципала",
    id: "principalDocuments"
  },
  {
    title: "Поручители",
    id: "guarantors"
  },
  {
    title: "Параметры",
    id: "parameters"
  },
  { title: "Параметры доставки",
    id: "delivery"
  },
  {
    title: "Комментарии",
    id: "comments"
  }
];
