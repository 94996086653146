import React, { PureComponent } from 'react'
import { createSelector } from 'reselect'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { openNewYearStories } from '#/src/redux/Stories/actions'
import ProgressStatistic from '../../components/ProgressStatistic'
import UserMenu from '../../components/UserMenu'
import { Icon } from '../../layout'
import { logoutUser } from '../../redux/User/actions'
import { fetchWidgetData } from '../../redux/Statistics/actions'
import { SHOW_STATS } from '../../config'
import { getShowNewYearStories } from '#/src/redux/Stories/selectors'
import { getUser } from '#/src/redux/User/selectors';
import { getSettings } from '#/src/redux/Settings/selectors';

const styles = {
  button: {
    marginTop: 8,
    position: 'relative',
    '& a': {
      borderRadius: 16,
      color: '#242232',
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 600,
      display: 'block',
      '&:hover': {
        color: '#242232'
      },
    },
    '& svg': {
      width: 14,
      height: 14,
      marginRight: 16,
      fill: '#242232',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
}

class Sidebar extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    isManager: PropTypes.bool,
    widget: PropTypes.shape({
      items: PropTypes.object.isRequired,
      sum: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
      noItems: PropTypes.bool.isRequired
    }).isRequired,
    fetchWidgetData: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    openNewYearStories: PropTypes.func.isRequired,
    showNewYearStories: PropTypes.bool,
  }

  static defaultProps = {
    name: '',
    isManager: false,
  }

  componentDidMount() {
    this.props.fetchWidgetData()
  }

  onLogout = async () => {
    const { settings, logoutUser } = this.props
    await logoutUser(settings.authType)
  }

  onClick = () => {
    const { openNewYearStories } = this.props
    openNewYearStories()
  }

  renderStatsWidget() {
    const { widget, settings } = this.props

    return widget.noItems
      ? null
      : <div className='fr-sidebar-bm__statistics-cont progress-statistic'>
          {settings.statusItems.map(({
            key,
            text,
            className,
          }, index) => typeof widget.items[key] !== 'undefined'
            ? <ProgressStatistic
                key={index}
                count={widget.items[key].count}
                sum={widget.sum}
                text={text}
                className={className}
              />
            : null
          )}
        </div>
  }

  render() {
    const {
      classes,
      name,
      widgets,
      isManager,
      isClient,
      isRko,
      onClose,
      showNewYearStories
    } = this.props

    const isCalc = widgets && widgets.bgtc

    return (
      <section className='fr-sidebar'>
        {showNewYearStories ? (
          <div className='new-year-fr-sidebar__logo fr-sidebar__logo' onClick={ this.onClick } />
        ) : (
          <Link className='fr-sidebar__logo' to='/' />
        )}
        <div className='fr-sidebar__menu'>
          {!isRko &&
            <NavLink to='/tasks' activeClassName='active' className='fr-sidebar__menu-link' onClick={onClose}>
              <span className='icon icon-ok' />
              <span>Заявки</span>
            </NavLink>
          }
          {!isClient && !isRko &&
            <NavLink to='/clients' activeClassName='active' className='fr-sidebar__menu-link' onClick={onClose}>
              <span className='icon icon-user' />
              <span>Клиенты</span>
            </NavLink>
          }
          {isManager &&
            <NavLink to='/agents' activeClassName='active' className='fr-sidebar__menu-link' onClick={onClose}>
              <span className='icon icon-user' />
              <span>Агенты</span>
            </NavLink>
          }
          {isCalc &&
            <div className={classes.button}>
              <NavLink to='/calc' className='fr-sidebar__menu-link' onClick={onClose}>
                <Icon iconName='calculator' />
                <span>Калькулятор</span>
              </NavLink>
            </div>
          }
        </div>
        <div className='fr-sidebar-bm'>
          <div className='fr-sidebar-bm__aft-ui-link-wrapper'>
            <div className='fr-sidebar-bm__aft-ui-link'>
              <a href='https://bg.alfabank.ru/aft-ui' target="_blank" rel="noreferrer">Новый кабинет</a>
            </div>
          </div>

          {!isRko && SHOW_STATS &&
            <div className='fr-sidebar-bm__statistics'>
              {this.renderStatsWidget()}
              <div className='fr-sidebar-bm__statistics-btn'>
                <Link to='?show-statistic'>Смотреть статистику</Link>
              </div>
            </div>
          }
          <UserMenu name={name} onLogout={this.onLogout} />
        </div>
      </section>
    )
  }
}

const mapStateToProps = createSelector(
  getUser,
  getSettings,
  getShowNewYearStories,
  ({ Statistics }) => Statistics,
  ({ settings, fullname, ismanager, isrko, isclient }, { widgets }, showNewYearStories, { widget }) => ({
      settings: settings,
      name: fullname,
      isManager: ismanager,
      isRko: isrko,
      widgets: widgets,
      widget: widget,
      isClient: isclient,
      showNewYearStories,
    })
)

const StyledSidebar = injectSheet(styles)(Sidebar);

export default connect(mapStateToProps, { fetchWidgetData, logoutUser, openNewYearStories })(StyledSidebar)
