import Modal from './Modal'
import H1 from './H1'
import H2 from './H2'
import Title from './Title'
import Value from './Value'
import Block from './Block'
import Wrapper from './Wrapper'
import Lot from './Lot'
import Principal from './Principal'
import Activities from './Activities'
import Limits from './Limits'
import Info from './Info'
import Parameters from './Parameters'
import Documents from './Documents'
import Comments from './Comments'
import Guarantors from './Guarantors'
import { Delivery } from './Delivery';

Modal.H1 = H1
Modal.H2 = H2
Modal.Wrapper = Wrapper
Modal.Block = Block
Modal.Title = Title
Modal.Value = Value
Modal.Lot = Lot
Modal.Principal = Principal
Modal.Activities = Activities
Modal.Info = Info
Modal.Limits = Limits
Modal.Documents = Documents
Modal.Parameters = Parameters
Modal.Comments = Comments
Modal.Guarantors = Guarantors
Modal.Delivery = Delivery
Modal.Content = Block.Content
Modal.DownloadAllButton = Block.DownloadAllButton

export default Modal
