import React from 'react'
import { createUseStyles } from 'react-jss';
import Modal from '../index'
import Value from '../Value';
import { File } from '../../../components/File';

const useStyles = createUseStyles({
  document: {
    marginBottom: '25px'
  },
  file: {
    marginBottom: '15px',
    padding: '10px 15px'
  }
})

const Documents = ({
  title,
  documents,
  className,
  emailToken,
}) => {
  const classes = useStyles();
  const documentsIsset = documents && documents.length

  if (!documentsIsset) {
    return null
  }

  return (
    <Modal.Block iconName='document' title={title} emailToken={emailToken}>
      <Modal.Content className={className}>
        {documents.map(({ name, files, type }) =>
          <div key={type} className={classes.document}>
            <Value>{name}</Value>
            {files.map((file) =>
              <File className={classes.file} key={`${file.name}${file.url}`} {...file} />
            )}
          </div>
        )}
      </Modal.Content>
    </Modal.Block>
  )
}

export default Documents
