export const COLORS = {
  //background
  bgWhiteColor: "#fff",
  bgGreyColor: "#eee",

  //text
  textBaseColor: "#000",
  textSecondaryColor: "#9b9b9b",
  textLinkColor: "#242232",
  textErrorColor: "#fa6a47"
}
