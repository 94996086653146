import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Overlay from '../../../components/Overlay'
import * as TaskService from '../../../services/api/tasks'
import * as Error from '../../../redux/Error/actions'
import * as Task from '../../../redux/Tasks/actions'

class AddModalForm extends Component {
  state = {
    loading: false,
  }

  static propTypes = {
    activeDefinitionKey: PropTypes.string,
    title: PropTypes.string,
    onCloseModal: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  static getFormScript(processDefinitionKey) {
    return `
      ngapp.init($('#create-task')[0], '#process_definition_key#', function (CamSDK) {
        var camClient = new CamSDK.Client({
          mock: false,
          apiUri: '/camunda/api/engine'
        });
        var processService = new camClient.resource('process-definition');
        // var $container = $('.start-form-section').parent();
    
        processService.getByKey('#process_definition_key#', function (err, def) {
          if (err) {
            setErrorNotification(err.message);
            setTimeout(function () {
              clearErrorNotification();
            }, 3000);
            return;
          }
    
          processService.startForm({id: def.id}, function (err, taskFormInfo) {
            if (err) {
              setErrorNotification(err.message);
              setTimeout(function () {
                clearErrorNotification();
              }, 3000);
              return;
            }
    
            var url = taskFormInfo.key.replace('embedded:app:', taskFormInfo.contextPath + '/');
    
            new CamSDK.Form({
              client: camClient,
              formUrl: url,
              processDefinitionId: def.id,
              containerElement: $('#camunda'),
              done: function (err, camForm) {
                if (err) {
                  console.log('all', err);
                  throw err;
                }
    
                camForm.on('submit-success', function () {
                  removePreloader();
                });
    
                camForm.on('submit-error', function (evt, res) {
                  console.log('submit-error', res);
                  removePreloader();
                  setErrorNotification(res[0]);
                  setTimeout(function () {
                    clearErrorNotification();
                  }, 3000);
                });
    
                $('#camunda_submit').click(function (e) {
                  e.preventDefault();
                  setPreloader();
                  camForm.submit(function (err, data) {
                    if (err) {
                      removePreloader();
                      var $scope = angular.element('#create-task form').scope();
                      if ($scope.$$camForm.$valid) {
                        setErrorNotification(err.message);
                        setTimeout(function () {
                          clearErrorNotification();
                        }, 3000);
                      } else {
                        setErrorNotification(err);
                        setTimeout(function () {
                          clearErrorNotification();
                        }, 3000);
                      }
                    }

                    window['submitSuccess-#process_definition_key#'] && window['submitSuccess-#process_definition_key#'](data);

                  });
                });
              }
            });
          });
        });
        
        
        function setErrorNotification(content) {
          var innerHtml =
            '<span class="notification__text">' +
            content +
            '</span>' +
            '<button type="button" class="notification__reload-link">' +
            '    <i class="icon icon-close-s" />' +
            '</button>'
            .replace(/#content#/g, content);
          
          var elem = $(document.createElement('div'))
            .addClass('notification')
            .attr('id', 'error-note')
            .html(innerHtml);
            
          $('#root').append(elem);
          
          $('.notification__reload-link').click(function() {
            clearErrorNotification();
          });
        }
        
        function clearErrorNotification() {
          $('#error-note').remove();
        }
        
        function setPreloader() {
          var innerHtml = 
            '<svg viewBox="-2000 -1000 4000 2000">' +
              '<path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z" />' +
              '<use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px" />' +
            '</svg>';
            
          var elem = $(document.createElement('div'))
            .addClass('preloader preloader--big')
            .attr('id', 'preloader')
            .html(innerHtml);
            
          $('#root').append(elem);
        }
        
        function removePreloader() {
          $('#preloader').remove();
        }
      });
    `
      .replace(/#process_definition_key#/g, processDefinitionKey);
  }

  componentDidMount() {
    this.initNgApp()
  }

  initNgApp() {
    const { activeDefinitionKey, onCloseModal } = this.props

    window[`submitSuccess-${activeDefinitionKey}`] = this.onFormSubmitSuccess.bind(this)

    if (typeof activeDefinitionKey === 'undefined') {
      onCloseModal()
    }

    window[`submitSuccess-${activeDefinitionKey}`] = this.onFormSubmitSuccess.bind(this)

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.innerHTML = AddModalForm.getFormScript(activeDefinitionKey)

    const taskDetailBlock = document.querySelector('#create-task')
    taskDetailBlock.appendChild(script)
  }

  componentWillUnmount() {
    const { activeDefinitionKey } = this.props
    delete window[`submitSuccess-${activeDefinitionKey}`]
  }

  onClose = () => this.props.onCloseModal(-2)

  onStartProcess = (e) => {
    const { activeDefinitionKey } = this.props

    if (!e.defaultPrevented) {
      window[`submitSuccess-${activeDefinitionKey}`] && window[`submitSuccess-${activeDefinitionKey}`](Date.now())
    }
  }

  onFormSubmitSuccess(processInstance = {}) {
    const {
      history,
      process,
    } = this.props

    if (process && processInstance && process.isWizzard && processInstance.id) {
      this.setState({ processInstanceId: processInstance.id }, this.waitNextTask)
    } else if (Object.keys(processInstance).length > 0 && processInstance.constructor === Object) {
      history.push('/tasks')
    }
  }

  waitNextTask = async (iteration = 0) => {
    const {
      dispatch,
      settings,
      history
    } = this.props
    this.setState({ loading: true })
    const nextTask = await this.getNextTask()
    if ( nextTask ) {
      dispatch(Task.insertTask(nextTask))
      const nextTaskId = nextTask.task_id || nextTask.id
      dispatch(Task.setActiveTask(nextTaskId))
      this.setState({ loading: false })
      history.push(`/tasks/${nextTaskId}`, { title: nextTask.name })
    } else {
      if ((iteration * settings.poolingInterval) < settings.poolingMaxTime && (nextTask === undefined)) {
        setTimeout(() => { this.waitNextTask(iteration + 1) }, settings.poolingInterval * 1000)
      } else {
        this.setState({ loading: false, hasNotNextTask: true })
      }
    }
  }

  getNextTask = async () => {
    const {
      dispatch,
    } = this.props
    const { processInstanceId } = this.state
    try {
      const { isSuccess, wizzardNoNextText, ...resp } = await TaskService.getNextProcessTask(processInstanceId)
      if (!isSuccess) {
        return null
      }
      if (wizzardNoNextText) {
        this.setState({wizzardNoNextText})
      }
      return resp.nextTask
    } catch (err) {
      dispatch(Error.setErrorContent(err))
      return null
    }
  }

  render() {
    const { title } = this.props
    const { loading, hasNotNextTask, wizzardNoNextText } = this.state

    return (
      <>
        <div className='modal-content__header'>
          <div>
            <div className='modal-content__title modal-content__title--task'>
              <span className='task-title'>{title}</span>
            </div>
          </div>
        </div>
        <div className='modal-content__body' id='create-task'>
          {loading && !hasNotNextTask
            ? <Overlay />
            : !hasNotNextTask
              ? <div id='camunda'>
                  <div className='loading-indicator'>Подождите, идет загрузка...</div>
                </div>
              : <div>
                  <div className='loading-indicator'>{wizzardNoNextText  || 'Нет следующей задачи'}</div>
                </div>
          }
        </div>
        <div className='modal-content__footer'>
          {!hasNotNextTask
            ? <>
                <button className='btn btn-primary' type='button' onClick={this.onClose}>Отменить</button>
                <button className='btn btn-primary' type='button' onClick={this.onStartProcess} id='camunda_submit'>Разместить заявку</button>
              </>
            : <button className='btn btn-primary' type='button' onClick={this.onClose}>Закрыть</button>
          }
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ User, Settings }, ownProps) => {
  const title = User.processDefinitionKeys.reduce((result, item) => {
    if (item.process_definition_key === ownProps.activeDefinitionKey) {
      return item.process_name
    }
    return result
  }, 'Создание')

  const process = (Settings.process_definition_keys || [])
    .find(item => item.process_definition_key === ownProps.activeDefinitionKey) || {}

  return {
    title,
    process,
    settings: Settings.nextTask || {},
  }
}

export default withRouter(connect(mapStateToProps)(AddModalForm))
