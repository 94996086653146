import React from "react";
import injectSheet from "react-jss";
import { Icon } from "../../layout";

const styles = {
  root: {
    width: "50px",
    height: "50px",
    // position: 'fixed',
    // top: '6px',
    // left: '50%',
    // transform: 'translateX(595px)',
    cursor: "pointer",
    marginLeft: "8px",
    zIndex: 30000,
    "& svg": {
      width: "inherit",
      height: "inherit",
      display: "block",
    },
  },
};

export const CloseAlternative = injectSheet(styles)(
  ({ classes, style, onClose }) => {
    return (
      <div className={classes.root} onClick={onClose} style={style}>
        <Icon iconName="close" />
      </div>
    );
  }
);
