import React, { useCallback }  from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { COLORS } from '../../constants/styleVariables'
import cx from 'classnames'

const useStyles = createUseStyles({
  item: {
    padding: "10px 30px",
    width: "100%",
    color: COLORS.textBaseColor,
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "21px",
    textAlign: "left",
    border: "none",
    cursor: "pointer",

    '&:hover, &$active': {
      textDecoration: "none",
      color: COLORS.textBaseColor,
      backgroundColor: COLORS.bgWhiteColor
    }
  },
  active: {}
})

export const Item = ({ id, title, isActive, onClick }) => {
  const classes = useStyles();

  const handleClick = useCallback(() => {
    onClick(id)
  }, [onClick, id]);

  return (
    <li>
      <button
        className={cx(classes.item, isActive && classes.active)}
        onClick={handleClick}
      >
        {title}
      </button>
    </li>
  )
}

Item.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}
