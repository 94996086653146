import React, { useCallback, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { IMaskMixin } from 'react-imask'
import { getMask } from './PhoneNumberProps'
import { useInputPhoneStyles } from './styles'

const initMask = {
  mask: '0 000 000-00-00',
  country: 'ru',
  start: '7',
}

export const InputPhone = ({
  placeholder = '',
  label = '',
  className,
  inputClassName,
  value = '',
  onChange,
  onBlur: onBlurOuter,
  onBlurWithSetValue,
  disabled,
  ...rest
}) => {
  const classes = useInputPhoneStyles()
  const [mask, setMask] = useState(initMask)
  const input = useRef(null)
  const [unmaskedValue, setUnmaskedValue] = useState(value)

  const onBlur = useCallback(
    (event) => {
      onBlurOuter && onBlurOuter(event)
        onBlurWithSetValue && onBlurWithSetValue(event, unmaskedValue)
    },
    [onBlurOuter, unmaskedValue]
  )

  const updateMask = useCallback(
    (unmaskedValue) => {
      const newMask = getMask(unmaskedValue, mask, initMask)
      if (newMask.country !== mask.country || newMask.country === 'ru') {
        setMask(newMask)
      }
    },
    [mask]
  )

  useEffect(() => {
    updateMask(value)
    // eslint-disable-next-line
  }, [])

  const onAccept = useCallback(
    (valueMask, optionMask) => {
      onChange(optionMask.unmaskedValue)
      updateMask(optionMask.unmaskedValue)
      setUnmaskedValue(optionMask.unmaskedValue)
    },
    [onChange, updateMask]
  )

  return (
    <div className={classnames(classes.container, className)}>
      {label &&
        <label className={classes.label}>{label}</label>
      }
      <MaskedStyledInput
        ref={input}
        placeholder={placeholder}
        onBlur={onBlur}
        mask={[mask]}
        radix='.'
        onAccept={onAccept}
        value={value}
        disabled={disabled}
        inputClassName={inputClassName}
        {...rest}
      />
    </div>
  )
}

const MaskedStyledInput = IMaskMixin(
  ({
    inputRef,
    inputClassName,
    ...props
  }) =>
    <input
      {...props}
      className={classnames('form-control', inputClassName)}
      ref={inputRef}
    />
)
