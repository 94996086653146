import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import { Modal } from '../../../layout'
import Overlay from '../../Overlay'
import { ModalMenu } from '../../ModalMenu'
import { getOrderViewModel } from '../../../redux/Company/selectors'
import { OrderContent } from './OrderContent'

const useStyles = createUseStyles({
  container: {
    display: "flex",
    height: "100%"
  },
  content: {
    padding: "30px",
    overflowY: "auto",
    flexGrow: 1
  }
})

const OrderComponent = ({
	isFetching,
  header,
  menuSections
}) => {
  const classes = useStyles();

  const [activeSection, setActiveSection] = useState('mainInfo');

  if (isFetching) {
    return <Overlay />
  }

  return (
    <div className={classes.container}>
      <ModalMenu items={menuSections} onItemClick={setActiveSection} activeItemId={activeSection} />
      <div className={classes.content}>
        <Modal.H1>{header}</Modal.H1>
        <OrderContent activeSection={activeSection} />
      </div>
    </div>
  )
}

export const Order = connect(getOrderViewModel)(OrderComponent)
