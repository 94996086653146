export const styles = {
  plate: {
    backgroundColor: '#dff8e5',
    border: '1px solid #0cc44d',
    borderRadius: 12,
    padding: 12,
    fontWeight: 500,
    marginBottom: 10,
    width: 'max-content',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: '0 6px',
    }
  },
  place: {
    display: 'inline-flex',
    alignItems: 'center',
    color: '#ef3124',
    '& > *': { margin: '0 2px' }
  },
  ratingTypePicker: {
    border: 'none',
  }
}
