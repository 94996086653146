export const AGENTS_FETCH = 'AGENTS_FETCH'
export const AGENTS_SUCCESS = 'AGENTS_SUCCESS'
export const AGENTS_ERROR = 'AGENTS_ERROR'

export const AGENT_USERS_FETCH = 'AGENT_USERS_FETCH'
export const AGENT_USERS_SUCCESS = 'AGENT_USERS_SUCCESS'
export const AGENT_USERS_ERROR = 'AGENT_USERS_ERROR'

export const AGENT_USER_CHANGE_STATUS_FETCH = 'AGENT_USER_CHANGE_STATUS_FETCH'
export const AGENT_USER_CHANGE_STATUS_SUCCESS = 'AGENT_USER_CHANGE_STATUS_SUCCESS'
export const AGENT_USER_CHANGE_STATUS_ERROR = 'AGENT_USER_CHANGE_STATUS_ERROR'

export const AGENT_USER_ADD_FETCH = 'AGENT_USER_ADD_FETCH'
export const AGENT_USER_ADD_SUCCESS = 'AGENT_USER_ADD_SUCCESS'
export const AGENT_USER_ADD_ERROR = 'AGENT_USER_ADD_ERROR'
export const AGENT_USER_ADD_RESET = 'AGENT_USER_ADD_RESET'

export const AGENT_USER_EDIT_INITIALIZED = 'AGENT_USER_EDIT_INITIALIZED';
export const AGENT_USER_EDIT_FETCH = 'AGENT_USER_EDIT_FETCH'
export const AGENT_USER_EDIT_SUCCESS = 'AGENT_USER_EDIT_SUCCESS'
export const AGENT_USER_EDIT_ERROR = 'AGENT_USER_EDIT_ERROR'
export const AGENT_USER_EDIT_RESET = 'AGENT_USER_EDIT_RESET'

export const AGENT_SUB_NEW_FETCH = 'AGENT_SUB_NEW_FETCH'
export const AGENT_SUB_NEW_SUCCESS = 'AGENT_SUB_NEW_SUCCESS'
export const AGENT_SUB_NEW_ERROR = 'AGENT_SUB_NEW_ERROR'
export const AGENT_SUB_NEW_RESET = 'AGENT_SUB_NEW_RESET'

export const RESET_ADD_USER_ERROR = 'RESET_ADD_USER_ERROR'
