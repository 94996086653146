import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
	root: {
		fontWeight: 600,
		fontSize: '24px',
		lineHeight: '26px',
		color: '#000',
	},
	status: {
		background: '#22C7B5',
		marginTop: '17px',
		padding: '4px 14px',
		color: '#008f6b',
		fontSize: '12px',
		lineHeight: '16px',
		borderRadius: '8px',
		display: 'inline-block',
		verticalAlign: 'top',
	},
}

const H1 = ({ 
	children,
	className,
	classes,
}) => 
	<div className={classnames(classes.root, className)}>
		<div>{children}</div>
		{/* <div className={classes.status}>Готово к отправке</div> */}
	</div>

export default injectSheet(styles)(H1)