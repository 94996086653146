import * as types from './actionTypes'
// import Cookies from 'js-cookie'

const initialState = {
  isFetching: false,
  isFetchingRefreshToken: false,
  isAuth: false,
  isAuthError: false,
  session_id: null, // Cookies.get('session_id'),
  logout: false,
  logoutRedirectUrl: null,
  username: '',
  fullname: '',
  isclient: null,
  isagent: null,
  processDefinitionKeys: [],
  keycloak: {},
  settingsFetch: true,
  settings: {},
  filters: [],
  setJwtOccurred: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_FETCH:
    case types.LOGOUT_FETCH:
      return {
        ...state,
        isFetching: true,
        logout: false,
        logoutRedirectUrl: null,
      }
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuth: true,
        ...action.data,
      }
    case types.LOGOUT_SUCCESS:
      return {
        ...initialState,
        logout: true,
        session_id: '',
        settingsFetch: state.settingsFetch,
        settings: state.settings,
        logoutRedirectUrl: action.data.redirectUrl
      }
    case types.LOGIN_ERROR:
    case types.LOGOUT_ERROR:
      return {
        ...state,
        isFetching: false,
        session_id: '',
      }
    case types.AUTH_FETCH:
      return {
        ...state,
        isFetching: true,
        logout: false,
        isAuthError: false,
      }
    case types.AUTH_SUCCESS:
      return {
        ...state,
        ...action.data,
        isFetching: false,
        isAuth: true,
        isAuthError: false,
      }
    case types.AUTH_ERROR:
      return {
        ...state,
        session_id: '',
        isFetching: false,
        isAuthError: true,
      }
    case types.REFRESH_TOKEN_FETCH:
      return {
        ...state,
        isFetchingRefreshToken: true,
        logout: false,
        isAuthError: false,
      }
    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isFetchingRefreshToken: false,
        isAuth: true,
        isAuthError: false,
      }
    case types.REFRESH_TOKEN_ERROR:
        return {
          ...state,
          session_id: '',
          isFetchingRefreshToken: false,
          isAuthError: true,
        }
    case types.SET_KEYCLOAK:
      return {
        ...state,
        keycloak: action.keycloak,
      }
    case types.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.data,
        settingsFetch: false,
      }
    case types.GET_SETTINGS_ERROR:
      return {
        ...state,
        settingsFetch: false,
      }
    case types.SET_JWT_OCCURRED: {
      return {
        ...state,
        setJwtOccurred: true
      }
    }
    default:
      return state
  }
}