import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import Dropdown from '#/src/components/Dropdown';
import { FormError } from '#/src/components/FormError';

const useStyles = createUseStyles({
  error: {
    marginTop: '16px'
  }
});

const options = [
  { key: '-1', value: '' },
  { key: '0', value: 'Пользователь' },
  { key: '1', value: 'Менеджер' }
];

export const DropdownField = ({ input: { name, value, onChange }, meta: { touched, error } }) => {
  const classes = useStyles();

  const handleSelect = useCallback((_, key) => {
    onChange(key);
  }, [onChange]);

  return (
    <div className='form-group form-group--mb'>
      <Dropdown
        name={name}
        toggleClassName='btn btn-dropdown--transparent'
        defaultText='Роль'
        defaultActive={value ? +value + 1 : 0}
        list={options}
        hideDefaultItem
        onSelectItem={handleSelect}
      />
      {touched && error && (
        <FormError className={classes.error}>{error}</FormError>
      )}
    </div>
  );
};
