import { useState } from 'react'
import { getRatingComponent } from '#/src/containers/Filter/PromoRating/utils'

export const usePlace = (rating) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const shouldShowTooltip = rating.positions.length > 1;
  const RatingComponent = getRatingComponent(rating);

  const handleTooltipOpen = () => setIsTooltipOpen((prev) => !prev);

  return {
    shouldShowTooltip,
    RatingComponent,
    isTooltipOpen,
    handleTooltipOpen,
  };
};
