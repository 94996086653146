import React from 'react';
import { createUseStyles } from "react-jss";
import { Field } from './Field';
import Modal from '../index';
import { styles } from './styles';

const useStyles = createUseStyles(styles);

export const GuarantorCompanies = ({ companies }) => {
  const classes = useStyles();

  if (!companies.length) {
    return null;
  }

  return (
    <Modal.Content className={classes.container}>
      <h3 className={classes.guarantorsType}>Юридические лица</h3>
      {companies.map(({ INN, OGRN, displayName, id }) =>
        <div key={OGRN} className={classes.guarantorCard}>
          <a
            href={`/clients/${id}`}
            target='_blank'
            rel='noopener noreferrer'
            className={classes.guarantorName}
          >
            {displayName}
          </a>
          <div className={classes.fieldsGroup}>
            <Field title="ИНН">{INN}</Field>
            <Field title="ОГРН">{OGRN}</Field>
          </div>
        </div>
      )}
    </Modal.Content>
  )
};
