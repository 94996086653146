import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import cx from 'classnames';
import injectStyles from 'react-jss';
import { editUser, resetEditingUserStatus, fetchUserData } from '#/src/redux/Agents/actions';
import { DropdownField } from '#/src/containers/Form/DropdownField';
import { FormTextField } from '#/src/components/_formFields/FormTextField';
import * as validation from '#/src/utils/validationFunctions';
import Overlay from '#/src/components/Overlay';
import { FormInputPhone } from '#/src/components/_formFields/FormInputPhone';
import { adaptive_600 } from '#/src/config';
import { editAgentFormName } from './constants';

const formSettings = {
  form: editAgentFormName
};

const styles = {
  form: {
    position: 'relative',
    padding: 20,
    [adaptive_600]: {
      padding: 10
    }
  }
}

class EditAgentForm extends PureComponent {
  static propTypes = {
    companyId: PropTypes.string.isRequired,
    onCloseForm: PropTypes.func.isRequired,
    editUserInfo: PropTypes.shape({
      fetching: PropTypes.bool.isRequired,
      status: PropTypes.bool.isRequired,
      error: PropTypes.string,
      isInitialized: PropTypes.bool.isRequired
    })
  };

  componentDidMount() {
    const { userId, dispatch } = this.props;
    dispatch(fetchUserData({ userId }));
  }


  componentDidUpdate(prevProps) {
    const { onCloseForm, editUserInfo: nowEditUserInfo, dispatch } = this.props;
    const { editUserInfo: prevEditUserInfo } = prevProps;
    if (!prevEditUserInfo.status && nowEditUserInfo.status) {
      onCloseForm();
      dispatch(resetEditingUserStatus());
    }
  }

  onFormSubmit = (values) => {
    const { companyId, userId, dispatch } = this.props;
    dispatch(editUser({ companyId, oldUsername: userId, ...values }));
  };

  handleSubmitForm = (event) => {
    const { editUserInfo, handleSubmit } = this.props;
    if (editUserInfo.fetching) {
      return;
    }
    handleSubmit(this.onFormSubmit)(event);
  };

  render() {
    const { editUserInfo, classes } = this.props;
    return (
      <form onSubmit={this.handleSubmitForm} className={classes.form}>
        <div className={cx('restore-pass__title')}>Изменение пользователя</div>
        <Field
          component={FormTextField}
          name='name'
          type='text'
          aria-describedby='fioHelp'
          placeholder='ФИО'
          inputClassName='form-control--transparent'
          validate={[validation.required, validation.fullName]}
        />
        <Field
          component={FormTextField}
          name='username'
          type='text'
          placeholder='Логин'
          inputClassName='form-control--transparent'
          validate={validation.required}
        />
        <Field
          component={FormTextField}
          name='email'
          type='email'
          aria-describedby='emailHelp'
          placeholder='Эл. почта'
          inputClassName='form-control--transparent'
          validate={[validation.required, validation.email]}
        />
        <Field
          component={FormInputPhone}
          name='phone'
          type='text'
          placeholder='Номер телефона'
          inputClassName='form-control--transparent'
          validate={validation.phone}
        />
        <Field
          component={DropdownField}
          name='ismanager'
          validate={validation.required}
        />
        <div className={cx('form-group')}>
          <button type='submit' className={cx('btn btn-block btn-white')}>
            Изменить
            {editUserInfo.fetching && <Overlay size='small' />}
          </button>
        </div>
        {!editUserInfo.isInitialized && <Overlay size='small' />}
      </form>
    );
  }
}

const mapStateToProps = ({ Agents }) => ({
  editUserInfo: Agents.editUser
});

export default reduxForm(formSettings)(
  connect(mapStateToProps)(
    injectStyles(styles)(EditAgentForm)
  )
);
