import * as actionTypes from './actionTypes'
import { Tasks } from '../../services/api'

import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'
import axios from 'axios'

const prepareTasksList = orderList =>
  orderList.reduce((result, { tasks }) => {
    if (!tasks) {
      return result
    }
    return Object.assign(result, { [`${tasks[0].task_id}`]: tasks[0].name })
  }, {})

let cancelToken

export const getTasksList = (filters, id) => {
  if (cancelToken) {
    cancelToken.cancel() // Cancel previous request
  }

  cancelToken = axios.CancelToken.source()

  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.TASKS_FETCH })

      const { isSuccess, err, ...res } = await Tasks.getTasksList(filters, 20, { cancelToken: cancelToken.token })

      if (!isSuccess) {
        if (axios.isCancel(err)) {
          return
        }

        if (res.needLogout) {
          dispatch(logoutProcess(res.message))
          return
        }
        throw new Error(res.message)
      }
      if (res.order) {
        const orders = res.order
        // .filter(item => item.tasks
        //   ? item.tasks.find(task => task.task_id !== id)
        //   : item
        // )
        res.order = orders
        res.tasks = prepareTasksList(orders)
      } else {
        res.order = []
        res.tasks = {}
      }

      dispatch({
        type: actionTypes.TASKS_SUCCESS,
        data: res
      })
    } catch (err) {
      console.log(err)
      dispatch(setErrorContent(err.message))
      dispatch({ type: actionTypes.TASKS_ERROR })
    }
  }
}

export const getNextTasksPage = (page, filters) => async dispatch => {
  try {
    dispatch({ type: actionTypes.NEXT_TASKS_FETCH })
    const { isSuccess, ...res } = await Tasks.getNextPage(page, filters)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    res.tasks = prepareTasksList(res.order)
    dispatch({
      type: actionTypes.NEXT_TASKS_SUCCESS,
      data: res
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({
      type: actionTypes.NEXT_TASKS_ERROR
    })
  }
}

export const setTasksFilter = filters => dispatch =>
  dispatch({
    type: actionTypes.TASKS_SET_FILTER,
    data: { filters }
  })

export const setTasksFilterHard = filters => dispatch =>
  dispatch({
    type: actionTypes.TASKS_SET_FILTER_HARD,
    data: { filters }
  })

export const clearAllFilters = () => dispatch =>
  dispatch({
    type: actionTypes.TASKS_CLEAR_FILTERS
  })

export const insertTask = task => dispatch =>
  dispatch({
    type: actionTypes.INSERT_TASK,
    data: {
      tasks: [task]
    }
  })

export const setActiveTask = task => dispatch =>
  dispatch({
    type: actionTypes.TASK_SET_ACTIVE,
    data: task
  })

export const getTaskUsers = taskId => async dispatch => {
  try {
    dispatch({ type: actionTypes.GET_TASK_USERS })
    const { isSuccess, message, ...res } = await Tasks.getTaskUsers(taskId)
    if (isSuccess) {
      dispatch({
        type: actionTypes.GET_TASK_USERS_SUCCESS,
        data: res
      })
    } else {
      console.log(message)
      dispatch(setErrorContent(message))
      dispatch({ type: actionTypes.GET_TASK_USERS_ERROR })
    }
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_TASK_USERS_ERROR })
  }
}

export const changeTasksUser = (payload, callback) => async dispatch => {
  try {
    dispatch({ type: actionTypes.CHANGE_TASK_USERS })
    const { isSuccess, message, ...res } = await Tasks.changeTasksUser(payload)
    if (isSuccess) {
      dispatch({
        type: actionTypes.CHANGE_TASK_USERS_SUCCESS,
        data: res,
        task_id: payload.task_id,
        username: payload.username
      })
      if (callback) {
        callback()
      }
    } else {
      console.log(message)
      dispatch(setErrorContent(message))
      dispatch({
        type: actionTypes.CHANGE_TASK_USERS_ERROR,
        error: message
      })
    }
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent(error.message))
    dispatch({
      type: actionTypes.CHANGE_TASK_USERS_ERROR,
      error
    })
  }
}
