import React from 'react';
import cn from 'classnames';

import { getPlaceTitle } from './utils';

import { createUseStyles } from 'react-jss'
import { styles } from './styles';
import { RATING_CONFIG_BY_TYPE } from '#/src/containers/Filter/PromoRating/constants'

const useStyles = createUseStyles(styles);

export const RatingList = ({ rating, currentPlace }) => {
  const classes = useStyles();

  return (
    rating.positions.map((item) => (
      <div className={ cn(item.isCurrent && classes.current, classes.ratingItem) }>
        { getPlaceTitle({
          place: item.place,
          isCurrent: item.isCurrent,
          currentPlace,
          ratingType: rating.ratingType,
        }) }{ ' ' }
        место — { item.score } { RATING_CONFIG_BY_TYPE[rating.ratingType].scoreUnits }
      </div>
    ))
  );
}
