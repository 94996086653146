import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Link, Redirect } from 'react-router-dom'
import classnames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import Sidebar from '../Sidebar'
import Modal from '../Modal'
import AddModalSelect from '../AddModal/Select'
import AddModalForm from '../AddModal/Form'
import NewAgentForm from '../Form/NewAgent'
import NewSubagentForm from '../Form/NewSubagent'
import EditAgentForm from '../Form/EditAgent'
import FormForgotPassword from '../Form/ForgotPassword'
import FormSearch from '../Form/Search'
import UserStatistics from '../UserStatistics'
import ClientDetail from '../Detail/Client'
import TaskDetail from '../Detail/Task'
import AgentList from '../Detail/Agent'
import SnackBar from '../SnackBar'
import Overlay from '../../components/Overlay'
import { getTasksList } from '../../redux/Tasks/actions'
import { authenticationUser } from '../../redux/User/actions'
import { IS_NEW_AUTH } from '../../config'
import Carousel from '#/src/components/Carousel'

class Layout extends Component {
  static propTypes = {
    isAuth: PropTypes.bool.isRequired,
    logout: PropTypes.bool.isRequired,
    showAddAgent: PropTypes.bool,
    showAddTask: PropTypes.bool,
    showAddHelp: PropTypes.bool.isRequired,
    isManager: PropTypes.bool,
    isRko: PropTypes.bool,
    isNotFound: PropTypes.bool,
    showSnackBar: PropTypes.bool.isRequired
  }
  static defaultProps = {
    isNotFound: false,
    isManager: false,
    showAddTask: false
  }

  state = {
    prevFetchStatus: false,
    keycloakAuth: false,
    keycloakFetch: true,
    isMobileMenuOpened: false,
  }

  static getDerivedStateFromProps(props, state) {
    const { isFetching } = props
    const { prevFetchStatus } = state

    if (isFetching !== prevFetchStatus) {
      return {
        prevFetchStatus: isFetching
      }
    }

    return {}
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      // settings,
      path: pathNow,
      location: locationNow,
      // dispatch,
      // setJwtOccurred,
    } = this.props
    const { location: locationPrev } = prevProps

    // if (settings.authType === 'keycloak') {
    //   const { keycloakAuth: nowKeycloakAuth } = this.state
    //   const { keycloakAuth: prevKeycloakAuth } = prevState
    //   const { keycloak } = this.props

    //   if (!prevState.keycloakAuth && keycloak.authenticated) {
    //     this.setState({ keycloakAuth: true, keycloakFetch: false })
    //     dispatch(setKeycloak(keycloak))
    //   }

    //   if (!prevKeycloakAuth && nowKeycloakAuth && setJwtOccurred) {
    //     dispatch(authenticationUser())
    //   }
    // }

    if (pathNow === locationNow.pathname && locationNow.pathname !== locationPrev.pathname) {
      window.scrollTo(0, 0)
    }
  }

  async componentDidMount() {
    const {
      settings,
      // keycloak,
      isAuth,
      dispatch,
    } = this.props
    // if (settings.authType === 'keycloak') {
    //   if (keycloak.authenticated) {
    //     // await User.setJWT(keycloak.token)
    //     // localStorage.setItem('JWT', keycloak.token)
    //     // Cookies.set('JWT', keycloak.token)
    //     this.setState({ keycloakAuth: true, keycloakFetch: false })
    //     dispatch(setKeycloak(keycloak))
    //   }
    // }
    if (settings.authType === 'standard') {
      if (!isAuth) {
        dispatch(authenticationUser())
      }
    }
  }

  renderAddButton() {
    const { showAddTask, showAddHelp, showAddAgent } = this.props

    if (showAddTask) {
      return (
        <div className='btn-options'>
          <Link to='?add-task' className='btn-options__link' />
          {showAddHelp &&
            <span className='btn-options__tooltip'>
              Однако, вам стоит подумать о будущем и создать пару задач…
            </span>
          }
        </div>
      )
    }

    if (showAddAgent) {
      return (
        <div className='btn-options'>
          <Link to='?add-agent=true' className='btn-options__link' />
        </div>
      )
    }

    return null
  }

  renderModalNode(props) {
    const {
      location: {
        search,
        state: routeState = {},
      },
      history,
      match,
      dispatch,
      filters,
    } = props

    const onRefreshTasksList = () => dispatch(getTasksList(filters, match.params.id))
    const onClose = () => history.push('/tasks')

    switch (true) {
      case search === '?restore-password':
        return (
          <Modal centerPosition modalClass='restore-pass-form' onCloseModal={history.goBack}>
            <FormForgotPassword title='Изменение пароля' buttonText='Изменить' onCloseModal={history.goBack} />
          </Modal>
        )
      case search.search(/\?search/) !== -1:
        const query = decodeURIComponent(search).split('=')[1]
        return (
          <Modal centerPosition modalClass='modal-search' onCloseModal={history.goBack}>
            <FormSearch defaultSearch={query} />
          </Modal>
        )
      case search === '?show-statistic':
        return (
          <Modal
            centerPosition
            dialogClass='modal-dialog--xl'
            contentClass='modal-content__p-0 modal-content__chart-stats'
            onCloseModal={history.goBack}
          >
            <UserStatistics />
          </Modal>
        )
      case search.search(/\?add-task/) !== -1:
        const addResult = search.match(/add-task=[A-Za-z\d-]+/g)
        if (addResult) {
          const definitionKey = addResult[0].split('=')[1]
          return (
            <Modal
              topPosition
              modalClass='modal-custom--wide-width'
              preventOutsideClick
              onCloseModal={() => history.go(-2)}
            >
              <AddModalForm activeDefinitionKey={definitionKey} onCloseModal={history.goBack} />
            </Modal>
          )
        } else {
          return <AddModalSelect onCloseModal={history.goBack} onProgrammingRedirect={history.push} />
        }
      case search.search(/\?add-agent/) !== -1:
        return (
          <Modal
            modalClass='user-edit-form'
            contentClass='modal-content--centred'
            dialogClass='modal-custom--empty'
            onCloseModal={history.goBack}
            preventOutsideClick
          >
            <NewSubagentForm
              // companyId={match.params.agent}
              onCloseModal={history.goBack}
            />
          </Modal>
        )
      case match.path.search('/clients/') !== -1 && typeof match.params.id !== 'undefined':
        return (
          <Modal topPosition modalClass='modal-custom--with-help-block' onCloseModal={history.goBack}>
            <ClientDetail id={match.params.id} onProgrammingRedirect={history.push} />
          </Modal>
        )
      case routeState && routeState.isModal && routeState.modalName === 'companyInfo':
        return (
          <Modal topPosition modalClass='modal-custom--with-help-block' onCloseModal={history.goBack}>
            <ClientDetail id={routeState.companyId} onProgrammingRedirect={history.push} />
          </Modal>
        )
      case match.path.search('/tasks/') !== -1 && typeof match.params.id !== 'undefined':
        const { title } = routeState
        return (
          <Modal
            topPosition
            modalClass='modal-custom--wide-width'
            preventOutsideClick
            onCloseModal={() => {
              onRefreshTasksList()
              onClose()
            }}
          >
            <TaskDetail
              id={match.params.id}
              title={title}
              onCloseDetail={onClose}
              onRefreshTasksList={onRefreshTasksList}
            />
          </Modal>
        )
      case match.path.search('/agents/') !== -1 && typeof match.params.agent !== 'undefined':
        if (match.path.search('/users/new') !== -1) {
          return (
            <Modal
              modalClass='user-edit-form'
              contentClass='modal-content--centred'
              dialogClass='modal-custom--empty'
              onCloseModal={history.goBack}
              preventOutsideClick
            >
              <NewAgentForm companyId={match.params.agent} onCloseForm={history.goBack} />
            </Modal>
          )
        }
        if (typeof match.params.user !== 'undefined') {
          return (
            <Modal
              modalClass='user-edit-form'
              contentClass='modal-content--centred'
              dialogClass='modal-custom--empty'
              onCloseModal={history.goBack}
              preventOutsideClick
            >
              <EditAgentForm companyId={match.params.agent} userId={match.params.user} onCloseForm={history.goBack} />
            </Modal>
          )
        }
        if (match.path.search('/users') !== -1) {
          return (
            <Modal
              modalClass='users-list'
              dialogClass='modal-dialog--md modal-custom--empty'
              contentClass='modal-content--centred'
              onCloseModal={history.goBack}
            >
              <AgentList id={match.params.agent} />
            </Modal>
          )
        }
        return null
      default: return null
    }
  }

  handleMobileHamburgerClick = () => {
    this.setState({ isMobileMenuOpened: !this.state.isMobileMenuOpened })
  }

  render() {
    const {
      component: Component,
      keycloak,
      isNotFound,
      isManager,
      isRko,
      settings,
      isAuth,
      logout,
      isFetching,
      showSnackBar,
      dispatch,
      filters,
      ...rest
    } = this.props

    const {
      prevFetchStatus,
      isMobileMenuOpened,
    } = this.state

    return (
      <Route
        {...rest}
        render={matchProps => {
          const { match } = matchProps

          if (!IS_NEW_AUTH) {
            if (settings.authType === 'keycloak') {
              if (!isAuth) {
                return <Redirect to={{ pathname: '/', search: '' }} />
              }

              if (match.path === '/' && isAuth) {
                return <Redirect to={{ pathname: '/tasks', search: '' }} />
              }
            }

            if (settings.authType === 'standard') {
              if ((prevFetchStatus || logout) && !isFetching && !isAuth) {
                return <Redirect to={{ pathname: '/', search: '' }} />
              }

              if (!isAuth) {
                return <Overlay size='big' />
              }

              if (match.path.search('/agents/') !== -1 && !isManager) {
                return <Redirect to={{ pathname: '/tasks', search: '' }} />
              }
            }
          }

          const contentNode = this.renderModalNode({...matchProps, dispatch, filters})

          return (
            <>
              <Carousel />
              <div className='fr-app'>
                <div
                  className={classnames('fr-container-toggler', {
                    'fr-container-toggler--active' : isMobileMenuOpened,
                  })}
                  onClick={this.handleMobileHamburgerClick}
                >
                  <div className='fr-container-toggler__item'/>
                  <div className='fr-container-toggler__item'/>
                  <div className='fr-container-toggler__item'/>
                </div>
                <div className={classnames('fr-container', {
                  'fr-container--error': isNotFound,
                  'fr-container--opened': isMobileMenuOpened,
                })}>
                  {!isNotFound &&
                    <Sidebar onClose={this.handleMobileHamburgerClick} />
                  }
                  <Component {...matchProps} />
                </div>
                {this.renderAddButton()}
              </div>
              <CSSTransition
                timeout={200}
                in={Boolean(contentNode)}
                classNames='fade'
              >
                <div>{contentNode}</div>
              </CSSTransition>
              {showSnackBar
                ? <SnackBar />
                : null
              }
            </>
          )
        }}
      />
    )
  }
}

const mapStateToProps = ({ Tasks, User, Error }, ownProps) => {
  const isTaskEmpty = ownProps.path &&
    ownProps.path.search('/tasks') !== -1 &&
    !Tasks.order.length &&
    !Tasks.isFetching

  return {
    showAddTask: ownProps.path && (ownProps.path.search('/tasks') !== -1),
    showAddHelp: isTaskEmpty,
    showAddAgent: ownProps.path && ownProps.path.search('/agents') !== -1,
    settings: User.settings,
    isAuth: User.isAuth,
    logout: User.logout,
    isFetching: User.isFetching,
    isManager: User.ismanager,
    isRko: User.isrko,
    showSnackBar: Error.show,
    setJwtOccurred: User.setJwtOccurred,
    filters: Tasks.filters,
  }
}

export default connect(mapStateToProps)(Layout)
