import * as actionTypes from './actionTypes'
import { RATING_TYPES } from '#/src/containers/Filter/PromoRating/constants'

const initialState = {
  promoRating: {
    period: undefined,
    selectedRatingType: undefined,
    ratingLists: [],
  }
}

function transformTotalAmount(score) {
  return +(score / 100000000).toFixed(3)
}

function formatScoreInRatingPromo(ratingPromo) {
  return ratingPromo.map(({ ratingType, positions, topLimit }) => ({
    ratingType,
    topLimit,
    positions: positions.map((position) => ({
      ...position,
      // Для рейтинга по объему в score приходит сумма в копейках - необходимо привести к млн
      score:
        ratingType === RATING_TYPES.totalAmount
          ? transformTotalAmount(position.score)
          : position.score,
    })),
  }));
}


export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PROMO_RATING_SUCCESS:
      const { ratingLists, period } = action.data;

      const ratingListWithFormattedScore = formatScoreInRatingPromo(ratingLists);

      const initialRatingType =
        ratingLists.length > 1
          ? RATING_TYPES.totalAmount
          : ratingLists[0].ratingType;

      return {
        ...state,
        promoRating: {
          period,
          selectedRatingType: initialRatingType,
          ratingLists: ratingListWithFormattedScore,
        },
      }

    case actionTypes.CHANGE_SELECTED_RATING_TYPE:
      return {
        ...state,
        promoRating: {
          ...state.promoRating,
          selectedRatingType: action.data,
        }
      }

    default:
      return state
  }
}
