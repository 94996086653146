import { COLORS } from '../../../constants/styleVariables'

export const styles = {
  container: {
    paddingLeft: "34px"
  },
  guarantorsType: {
    fontWeight: "bold",
    marginBottom: "15px",
  },
  guarantorCard: {
    backgroundColor: COLORS.bgGreyColor,
    padding: "20px",
    marginLeft: "-20px",
    marginBottom: "20px",
    borderRadius: "16px"
  },
  guarantorName: {
    display: "block",
    marginBottom: "10px",
    fontWeight: "500",
    fontSize: "16px",
    textDecoration: "none",
    color: COLORS.textLinkColor,
    "&:hover": {
      textDecoration: "underline",
      color: COLORS.textLinkColor
    }
  },
  fieldsGroup: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "20px",
    "& > *": {
      marginRight: "15px",
      "&:last-child": {
        marginRight: 0
      }
    },
    "&:last-child": {
      marginBottom: 0
    }
  },
  fieldsGroupTitle: {
    margin: "25px 0 10px",
    color: COLORS.textSecondaryColor,
    fontWeight: 500
  },
  fieldValue: {
    marginBottom: "10px",
    lineHeight: "18px"
  },
  contacts: {
    display: "flex",
    flexWrap: "wrap",
    "& > *:first-child": {
      marginRight: "15px"
    }
  },
  phone: {
    width: "130px"
  }
};
