export const RATING_TYPES = {
  totalAmount: 'TOTAL_AMOUNT',
  count: 'COUNT',
};

export const RATING_CONFIG_BY_TYPE = {
  [RATING_TYPES.totalAmount]: {
    ratingParam: 'Объем',
    ratingName: 'по объему',
    scoreUnits: 'млн ₽',
  },
  [RATING_TYPES.count]: {
    ratingParam: 'Количество',
    ratingName: 'по количеству',
    scoreUnits: '',
  },
};

export const RATING_TYPES_OPTIONS = [
  {
    key: RATING_TYPES.totalAmount,
    value: RATING_CONFIG_BY_TYPE[RATING_TYPES.totalAmount].ratingName,
  },
  {
    key: RATING_TYPES.count,
    value: RATING_CONFIG_BY_TYPE[RATING_TYPES.count].ratingName,
  }
];