import regexps from '#/src/utils/regexps';
import { isPhone } from '#/src/components/InputPhone/PhoneNumberProps';

export const required = (value) => {
  if (!value) {
    return 'Поле обязательно для заполнения!';
  }
};

export const email = (value) => {
  if (!value) {
    return;
  }

  if (!regexps.email.test(value)) {
    return 'Некорректный e-mail!';
  }
};

export const fullName = (value) => {
  if (!value) {
    return;
  }

  const parts = value.split(' ');
  const [firstName, lastName] = parts;

  if (parts.length < 2) {
    return 'Фамилия и Имя являются обязательными!';
  }
  if (firstName.length < 2) {
    return 'Неверный ввод Фамилии!';
  }
  if (lastName.length < 2) {
    return 'Неверный ввод Имени!';
  }
};

export const phone = (value) => {
  if (!value) {
    return;
  }

  if (!isPhone(value)) {
    return 'Некорректный телефонный номер!';
  }
};
