import React from 'react'
import injectSheet from 'react-jss'
import { Modal } from './../../index'
import { formattedNumber } from '../../../utils'

const styles = {
	root: {
    background: '#F4F4F4',
    padding: '15px',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  },
  item: {
    background: '#E4E4E4',
    minWidth: '316px',
    marginRight: '14px',
    padding: '16px 20px 17px',
    // borderRadius: '12px',
    display: 'inline-block',
    '&:first-child': {
      background: '#B060EF',
      color: '#6100DF',
    },
    '&:last-child': {
      background: '#FFBE00',
      color: '#C76202',
      marginRight: 0,
    },
  },
  value: {
    color: 'inherit',
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    // color: '#97969c',
    opacity: 0.7,
  },
}

const Limits = ({ 
  calculatedAmount,
  finalAmount,
  classes, 
}) => {
  // const calculated = !calculatedAmount ? 'НЕ РАССЧИТАН' : `${formattedNumber(calculatedAmount)} ₽`
  const final = !finalAmount ? 'НЕ РАССЧИТАН' : `${formattedNumber(finalAmount)} ₽`
  
  return (
    <Modal.Block iconName='info' title='Информация по лимитам'>
      <Modal.Content>
        <div className={classes.root}>
          {/* <div className={classes.item}>
            <Modal.Value className={classes.value}>{calculated}</Modal.Value>
            <div className={classes.label}>Автоматически рассчитанный лимит</div>
          </div> */}
          <div className={classes.item}>
            <Modal.Value className={classes.value}>{final}</Modal.Value>
            <div className={classes.label}>Итоговый лимит</div>
          </div>
        </div>
      </Modal.Content>
    </Modal.Block>
  )
}

export default injectSheet(styles)(Limits)