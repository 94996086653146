import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from '../Link';
import { Icon } from '../../layout';
import { styles } from './styles';

const useStyles = createUseStyles(styles);

export const File = ({
  url,
  name,
  isSigned,
  signUrl,
  attachedBy,
  attachDate,
  validToDate,
  isChecked,
  isDateValid,
  className
}) => {
  const classes = useStyles();
  const tooltipTargetRef = useRef(null);
  const showTooltip = attachedBy || attachDate;

  return <>
    <div className={classnames(classes.file, className)} ref={tooltipTargetRef}>
      <div className={classes.header}>
        <div>{name}</div>
        <div className={classes.links}>
          <Link
            href={url}
            download={isSigned ? name.replace('.sig', '') : name}
            className={classes.link}
          >
            <Icon iconName='download' className={classes.downloadIcon} />
            <span>Оригинал</span>
          </Link>
          {isSigned && (
            <Link
              href={signUrl}
              download={name}
              className={classes.link}
            >
              <Icon iconName='download' className={classes.downloadIcon} />
              <span>Подписанный</span>
            </Link>
          )}
        </div>
      </div>

      <div className={classes.footer}>
        {validToDate && (
          <>
            <span>действительно до {validToDate}</span>
            {!isDateValid && <Icon className={classes.crossIcon} iconName='close2' />}
          </>
        )}
        {isChecked && (
          <>
            <span className={classes.checked}>Проверено</span>
            <Icon className={classes.checkIcon} iconName='check' />
          </>
        )}
      </div>
    </div>
    {showTooltip && (
      <UncontrolledTooltip
        target={tooltipTargetRef}
        container={tooltipTargetRef}
        placement='bottom'
        autohide={false}
        innerClassName={classes.tooltip}
      >
        {attachedBy && <div>прикрепил {attachedBy}</div>}
        <div>{attachDate}</div>
      </UncontrolledTooltip>
    )}
  </>;
};

File.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  signUrl: PropTypes.string,
  attachedBy: PropTypes.string,
  attachDate: PropTypes.string,
  validToDate: PropTypes.string,
  isChecked: PropTypes.bool,
  isSigned: PropTypes.bool,
  isDateValid: PropTypes.bool
};
