import * as types from './actionTypes'
import { Stories } from '../../services/api'
import { setErrorContent } from '../Error/actions'

export const getStoriesInfo = (userLogin) => async (dispatch) => {
  try {
    dispatch({ type: types.STORIES_FETCH })
    const data = await Stories.getStoriesInfo(userLogin)
    const { isSuccess, ...res } = data

    if(!isSuccess) {
      throw new Error(res.message)
    }

    dispatch({
      type: types.STORIES_SUCCESS,
      data: res,
    })
  } catch (err) {
    dispatch(setErrorContent(err.message))
    dispatch({ type: types.STORIES_ERROR })
  }
}

export const openNewYearStories = () => (dispatch) => {
  dispatch({
    type: types.OPEN_NEW_YEAR_STORIES,
  })
}

export const closeNewYearStories = () => (dispatch) => {
  dispatch({
    type: types.CLOSE_NEW_YEAR_STORIES,
  })
}