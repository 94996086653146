import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
	root: {
		background: '#fff',
		marginTop: '10px',
  },
}

const Content = ({ 
	children,
	className,
	classes,
}) => 
	<div className={classnames(classes.root, className)}>
		{children}
	</div>

export default injectSheet(styles)(Content)