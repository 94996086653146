import React, { useEffect } from 'react'
import injectSheet from 'react-jss'
import { getTaskUsers } from '../../../redux/Tasks/actions'
import { Modal } from '../../../layout'
import Content from './Content'

const styles = {
  root: {
    background: '#fff',
    width: '100%',
    height: '100%',
  },
}

const ModalAssignee = ({
  classes,
  width,
  height,
  taskId,
  onClose,
}) => {
  useEffect(() => {
		getTaskUsers(taskId)
  }, [])

  return (
    <Modal className={classes.root} width={width} height={height} onClose={onClose}>
      <Content taskId={taskId} onClose={onClose} />
    </Modal>
  )
}

export default injectSheet(styles)(ModalAssignee)