import React from 'react';
import { createUseStyles } from "react-jss";
import { Field } from './Field';
import Modal from '../index';
import { styles } from './styles';

const useStyles = createUseStyles(styles);

export const GuarantorPersons = ({ persons }) => {
  const classes = useStyles();

  if (!persons.length) {
    return null;
  }

  return (
    <Modal.Content className={classes.container}>
      <h3 className={classes.guarantorsType}>Физические лица</h3>
      {persons.map(({
        firstName,
        secondName,
        lastName,
        citizenship,
        birthDate,
        birthPlace,
        INN,
        passport,
        registrationAddress,
        actualAddress,
        contactsList
      }) => {
        const { number, series, issuingAuthorityCode, issuingAuthority } = passport;
        const fullName = [firstName, secondName, lastName].filter(Boolean).join(" ");

        return (
          <div key={number} className={classes.guarantorCard}>
            <h4 className={classes.guarantorName}>{fullName}</h4>

            <Field title='Дата рождения'>{birthDate}</Field>
            <Field title='Место рождения'>{birthPlace}</Field>
            <Field title='Гражданство'>{citizenship}</Field>
            <Field title='ИНН'>{INN}</Field>

            <h5 className={classes.fieldsGroupTitle}>Паспорт</h5>
            <div className={classes.fieldsGroup}>
              <Field title='Серия'>{series}</Field>
              <Field title='Номер'>{number}</Field>
              <Field title='Код подразделения'>{issuingAuthorityCode}</Field>
              <Field title='Выдан'>{issuingAuthority}</Field>
            </div>

            <Field title='Адрес регистрации'>{registrationAddress}</Field>
            <Field title='Фактический адрес'>{actualAddress}</Field>

            {contactsList.length > 0 &&
              <>
                <h5 className={classes.fieldsGroupTitle}>Контакты</h5>
                {contactsList.map(({ email, phone }) =>
                  <div key={phone} className={classes.contacts}>
                    <Field className={classes.phone} title='Телефон'>{phone}</Field>
                    <Field title='Почта'>{email}</Field>
                  </div>
                )}
              </>
            }
          </div>
        )
      })}
    </Modal.Content>
  )
};
