import * as actionTypes from './actionTypes'

const initialState = {
  isFetching: false,
  company: {},
  order: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_ORDER:
      return {
        ...state,
        isFetching: true,
      }
    case actionTypes.GET_COMPANY_ORDER_SUCCESS:
      return {
        ...state,
        ...action.data,
        isFetching: false,
      }
    case actionTypes.GET_COMPANY_ORDER_ERROR:
      return {
        ...state,
        isFetching: false,
    }
    default:
      return state
  }
}
