import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { addUser, resetAddingUserStatus, resetAddUserError } from '#/src/redux/Agents/actions';
import Overlay from '#/src/components/Overlay';
import { FormError } from '#/src/components/FormError';
import { newAgentFormFields } from './constants';

const styles = {
  error: {
    marginTop: '16px'
  }
};

class NewAgentForm extends Component {
  static propTypes = {
    companyId: PropTypes.string.isRequired,
    onCloseForm: PropTypes.func.isRequired,
    addUserInfo: PropTypes.shape({
      fetching: PropTypes.bool.isRequired,
      status: PropTypes.bool.isRequired,
      error: PropTypes.string
    })
  };

  componentDidUpdate(prevProps) {
    const { onCloseForm, addUserInfo: nowAddUserInfo } = this.props;
    const { addUserInfo: prevAddUserInfo } = prevProps;

    if (!prevAddUserInfo.status && nowAddUserInfo.status) {
      onCloseForm();
      resetAddingUserStatus();
    }
  }

  onAddUser = (values) => {
    const { companyId, addUser } = this.props;
    addUser({ companyId, ...values });
  };

  onSubmitForm = (event) => {
    const { addUserInfo, handleSubmit } = this.props;
    if (addUserInfo.fetching) {
      return;
    }
    handleSubmit(this.onAddUser)(event);
  };

  handleFormChange = () => {
    const { addUserInfo, resetAddUserError } = this.props;
    if (addUserInfo.error) {
      resetAddUserError();
    }
  };

  render() {
    const { addUserInfo, classes } = this.props;

    return (
      <form onSubmit={this.onSubmitForm} onChange={this.handleFormChange}>
        <div className='restore-pass__title'>Добавление пользователя</div>
        {newAgentFormFields.map((input) => <Field key={input.name} {...input} />)}
        <div className='form-group'>
          <button type='submit' className='btn btn-block btn-white' disabled={addUserInfo.fetching}>
            {addUserInfo.fetching ? <Overlay size='small' /> : 'Сохранить'}
          </button>
        </div>
        {addUserInfo.error && (
          <FormError className={classes.error}>{addUserInfo.error}</FormError>
        )}
      </form>
    );
  }
}

const formSettings = {
  form: 'newUser'
};

const mapStateToProps = (state) => {
  const { Agents } = state;
  return {
    addUserInfo: Agents.addUser
  };
};

const NewAgentFormStyled = injectSheet(styles)(NewAgentForm);

const NewAgentFormConnected = connect(mapStateToProps, {
  resetAddUserError,
  resetAddingUserStatus,
  addUser
})(NewAgentFormStyled);

export default reduxForm(formSettings)(NewAgentFormConnected);
