import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import AgentStats from './blocks/AgentStats';
import { declOfNum } from '../../../services/utility';
import { COLORS } from '#/src/constants/styleVariables';

const users = ['пользователь', 'пользователя', 'пользователей'];
const clients = ['клиент', 'клиента', 'клиентов'];

const useStyles = createUseStyles({
  notDefined: {
    fontStyle: 'italic',
    color: COLORS.textSecondaryColor
  }
});

export const AgentCard = ({
  id,
  level = 0,
  name,
  inn,
  agentsCount,
  companyCount,
  statList,
  onShowClients,
  statusItems,
}) => {
  const classes = useStyles();
  return (
    <div className='fr-agent-card' style={{ marginLeft: `${30 * level}px` }}>
      <div className='fr-agent-card__body'>
        <div className='fr-agent-card__main'>
          <span className={cx('fr-agent-card__title', !name && classes.notDefined)}>
            {name || 'Название не указано'}
          </span>
          <span>
            <span style={{ marginRight: '5px' }}>ИНН: </span>
            <span className={inn ? '' : classes.notDefined}>{inn || 'Не указан'}</span>
          </span>
        </div>
        <div className='fr-agent-card__stats'>
          <Link to={`/agents/${id}/users`} className='fr-agent-card__stats-item' data-toggle="modal" data-target="#usersListModal">
            <span className='fr-agent-card__stats-value'>{agentsCount}</span>
            <span className='fr-agent-card__stats-text'>{declOfNum(agentsCount, users)}</span>
          </Link>
          <button
            type="button"
            className='fr-agent-card__stats-item fr-agent-card__stats-item-button'
            onClick={onShowClients.bind(this, id)}
          >
            <span className='fr-agent-card__stats-value'>{companyCount}</span>
            <span className='fr-agent-card__stats-text'>{declOfNum(companyCount, clients)}</span>
          </button>
        </div>
      </div>
      <AgentStats items={statList} statusItems={statusItems} />
    </div>
  );
};

AgentCard.propTypes = {
  level: PropTypes.number,
  name: PropTypes.string,
  inn: PropTypes.string,
  statList: PropTypes.shape({
    assigned: PropTypes.shape({
      count: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
    lost: PropTypes.shape({
      count: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
    inprogress: PropTypes.shape({
      count: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
    sold: PropTypes.shape({
      count: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  }),
  agentsCount: PropTypes.number.isRequired,
  companyCount: PropTypes.number.isRequired,
  onShowClients: PropTypes.func.isRequired,
};
