import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import injectSheet from 'react-jss'
import { getCompanyOrder } from '../../../redux/Company/actions'
import { Modal } from '../../../layout'
import { Order } from './Order'

const styles = {
  root: {
    background: '#fff',
    padding: 0,
    width: '100%',
    height: '100%',
  },
}

const ModalDeal = ({
  classes,
  width,
  orderId,
	onClose,
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCompanyOrder(orderId))
  }, [])

  return (
    <Modal className={classes.root} width={width} onClose={onClose}>
      <Order />
    </Modal>
  )
}

export default injectSheet(styles)(ModalDeal)
