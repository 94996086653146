import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { COLORS } from '../../constants/styleVariables'
import { Item } from './Item'

const useStyles = createUseStyles({
  menu: {
    height: "100%",
    width: "242px",
    flexShrink: 0,
    background: COLORS.bgGreyColor,
    overflowY: "auto"
  }
})

export const ModalMenu = ({ items, activeItemId, onItemClick }) => {
  const classes = useStyles();

  return (
    <menu className={classes.menu}>
      <ul>
        {items.map(({ id, title }) => (
          <Item key={id} id={id} title={title} isActive={id === activeItemId} onClick={onItemClick} />
        ))}
      </ul>
    </menu>
  )
}

ModalMenu.propTypes = {
  onItemClick: PropTypes.func,
  activeItemId: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.string,
    title: PropTypes.string,
  }))
}
