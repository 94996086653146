import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
	root: {
		color: '#000',
		fontSize: '18px',
		lineHeight: '32px',
		fontWeight: 500,
		position: 'relative',
		'& p': {
			color: '#000',
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 500,
		},
	},
}

const Value = ({
  children,
  className,
	classes,
	onClick,
}) => <div className={classnames(classes.root, className)} onClick={onClick}>{children}</div>

export default injectSheet(styles)(Value)