import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal } from '../../../layout'
import { getDocumentsViewModel } from '../../../redux/Company/selectors'

const DocumentsComponent = ({ documents, emailToken, title }) => {
  return <Modal.Documents documents={documents} emailToken={emailToken} title={title} />
}

export const Documents = connect(getDocumentsViewModel)(DocumentsComponent)

Documents.propTypes = {
  documentsType: PropTypes.oneOf(['deal', 'principal'])
}
