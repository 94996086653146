import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'debounce'

import ClearButton from '../ClearButton'
import ListItem from './blocks/ListItem'

import { searchByString, clearSearchResults } from '../../redux/Search/actions'

class TextFieldWithAutoComplete extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    classNames: PropTypes.shape({
      container: PropTypes.string,
      input: PropTypes.string
    }),
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string
    }),
    findAll: PropTypes.bool,
    icon: PropTypes.string,
  }

  static defaultProps = {
    placeholder: '',
    value: '',
    classNames: {
      container: '',
      input: '',
      error: ''
    },
    meta: {
      touched: false,
      error: ''
    },
    findAll: false,
  }

  state = {
    value: this.props.defaultValue ? this.props.defaultValue : this.props.value,
    showResult: false,
  }

  onClear = () => {
    this.setState({ value: '' })
    this.props.onClear(this.props.name, '')
    this.props.dispatch(clearSearchResults())
  }

  onFocus = () => {
    if (!this.props.noClearOnFocus) {
      this.onClear()
    }
    document.addEventListener('click', this.onOutsideClick)
  }

  onOutsideClick = ({ target }) => {
    if (this.textField && this.textField.contains(target)) return
    this.onToggleResults()
  }

  onToggleResults = () => {
    this.props.dispatch(clearSearchResults())
    this.setState({ showResult: false })
  }

  onChange = (e) => {
    const { value } = e.target
    const showResult = !!value.length
    const { dispatch } = this.props

    this.setState({ value, showResult })

    if (showResult) {
      this.onSearch()
    } else {
      dispatch(clearSearchResults())
    }
  }

  onSearch = debounce(() => {
    const { dispatch, findAll } = this.props
    const { value } = this.state
    dispatch(searchByString(value, findAll))
  }, 300)

  onSelectItem = (id, value) => {
    this.setState({ value, showResult: false })

    this.props.onSelect(this.props.name, id, value)
  }

  renderSearchResults() {
    const { list } = this.props
    const { showResult, value } = this.state

    if (!(list.length && showResult)) {
      return null
    }

    return (
      <div className='dropdown-menu show'>
        {list.map((item, index) =>
          <ListItem
            key={index}
            data={item.id}
            text={item.displayName}
            searchQuery={value}
            onClick={this.onSelectItem}
          />
        )}
      </div>
    )
  }

//   static getDerivedStateFromProps(nextProps, prevState){
//     if (nextProps.defaultValue && !prevState.value) {
//        this.setState({ value: nextProps.defaultValue })
//    }
// }

  render() {
    const { name, classNames, placeholder, meta: { touched, error }, icon } = this.props
    const { value } = this.state

    return (
      <div className={classNames.container} ref={node => { this.textField = node }}>
        <div style={{ position: 'relative' }}>
          {icon && (
            <span className={`icon ${icon}`} />
          )}
          <input
            type='text'
            autoComplete='off'
            className={classNames.input}
            placeholder={placeholder}
            name={name}
            value={value}
            onFocus={this.onFocus}
            onChange={this.onChange}
          />
          <ClearButton onClear={this.onClear} isHidden={!value.length} />
          {this.renderSearchResults()}
        </div>
        {touched && error &&
          <span className={classNames.error}>{error}</span>
        }
      </div>
    )
  }
}

const mapStateToProp = ({ Search }) => {
  return {
    list: Search.list,
    isFetching: Search.isFetching,
  }
}

export default connect(mapStateToProp)(TextFieldWithAutoComplete)
