import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import SliderImage from "./SliderImage";
import Close from "#/src/layout/Modal/Modal/Close";
import { closeNewYearStories } from "#/src/redux/Stories/actions";
import { getIsExpand, getStoriesCards } from "#/src/redux/Stories/selectors";

import SliderDots from "./SliderDots";

const styles = {
  width: '48px',
  height: '48px',
  left: 'auto',
  right: '6px',
  transform: 'translateX(0)',
  '& svg': {
    width: 'inherit',
    height: 'inherit',
    display: 'block',
  }
}

const Slide = ({ slide, isActive, slickGoTo, index }) => (
  <div onClick={ () => slickGoTo(index) } className="slider-item">
    {!isActive && (
      <div className="slide-hover" />
    )}
    <SliderImage src={ slide.url } isActive={ isActive } />
    <div
      className="slide-content"
    >
      <div className="slide-text-wrapper">
        <h3 className="slide-title">{ slide.textHeader }</h3>
        <p className="slide-text">{ slide.textBody }</p>
      </div>
    </div>
  </div>
);

const Carousel = () => {
  const dispatch = useDispatch()
  const storiesCards = useSelector(getStoriesCards)
  const isExpand = useSelector(getIsExpand)
  const [activeSlide, setActiveSlide] = useState(0)

  const slider = useRef(null)

  const onClose = () => {
    setActiveSlide(0)
    dispatch(closeNewYearStories())
  }

  const slickGoTo = (index) => {
    slider.current.slickGoTo(index);
  }

  return (
    <>
      { isExpand && (
        <div className="slider-outside">
          <Close onClose={onClose} style={styles} />
          <div className="slider-wrapper">
            <Slider
              ref={ slider }
              arrows={ false }
              beforeChange={(prev, next) => setActiveSlide(next)}
              centerMode
              variableWidth
              infinite={ false }
              speed={ 500 }
              pauseOnHover={ false }
              swipe={false}
            >
              {storiesCards.map((item, index) => (
                <Slide
                  index={ index }
                  key={item.url}
                  slide={ item } 
                  isActive={ activeSlide === index }
                  slickGoTo={ slickGoTo }
                />
              ))}
              {/* пустой div для решения бага с centerMode при наличии только одной карточки */}
              <div /> 
            </Slider>
            <SliderDots
              dotAmount={storiesCards.length}
              activeSlide={activeSlide}
              slickGoTo={ slickGoTo }
            />
          </div>
        </div>
      ) }
    </>
  );
};

export default Carousel