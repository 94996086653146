// import axios from 'axios'
import * as actionTypes from './actionTypes'
import { User } from '../../services/api'
// import Cookies from 'js-cookie'
import { setErrorContent } from '../Error/actions'
import { setSettings } from '../Settings/actions'
import { SETTINGS, IS_NEW_AUTH } from './../../config'
import { onLogout } from '../../utils/tokenStorage'

export const logoutProcess = (message = '', redirectUrl) => async (dispatch, getState) => {
  const { settings: { authType }, keycloak } = getState().User
  let redirectUrlClose
  try {
    const data = await User.closeSession()
    redirectUrlClose = data.redirectUrl
  } catch (err) {
    dispatch(setErrorContent(err))
  }
  if (authType === 'keycloak' && Object.keys(keycloak).length) {
    onLogout()
    // if (keycloak.authenticated) {
    //   keycloak.logout()
    //   // Cookies.remove('JWT')
    // }
  }
  if (authType === 'standard') {
    // Cookies.remove('session_id')
    // Cookies.remove('JSESSIONID')
  }

  if (message) {
    dispatch(setErrorContent(message))
  }

  dispatch({
    type: actionTypes.LOGOUT_SUCCESS,
    data: {
      redirectUrl: redirectUrl || redirectUrlClose,
    } 
  })
}

export const loginUser = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.LOGIN_FETCH })
    const { isSuccess, ...res } = await User.login({ username, password })
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    // Cookies.set('session_id', res.session.session_id, { expires: 1 })
    // Cookies.set('JSESSIONID', res.session.JSESSIONID, { expires: 1 })
    dispatch({
      type: actionTypes.LOGIN_SUCCESS,
      data: Object.assign(
        {},
        { ...res.user },
        {
          processDefinitionKeys: res.process_definition_keys,
          session_id: IS_NEW_AUTH
            ? null
            : res.session.session_id,
        },
        {
          companyEmployees: res.company.agentLogins || [],
        },
      ),
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.LOGIN_ERROR })
  }
}

export const authenticationUser = (props) => async (dispatch) => {
  const { redirectToAuth, onSuccess, onError } = props || {}
  try {
    dispatch({ type: actionTypes.AUTH_FETCH })
    const { isSuccess, ...res } = await User.auth()
    if (!isSuccess) {
      dispatch({ type: actionTypes.AUTH_ERROR })
      if (res.redirectUrl && redirectToAuth && !IS_NEW_AUTH) {
        redirectToAuth(res.redirectUrl)
      }
      return
    }

    dispatch({ type: actionTypes.AUTH_SUCCESS, data: Object.assign(
      {},
      { ...res.user },
      {
        processDefinitionKeys: res.process_definition_keys,
        session_id: IS_NEW_AUTH
          ? null
          : res.session.session_id,
      },
      {
        companyEmployees: res.company.agentLogins || [],
      },
    ) })
    dispatch(setSettings(res))
    onSuccess && onSuccess()
    if (!IS_NEW_AUTH) {
      setTimeout(() => 
        dispatch(refreshJWT({ onSuccess, redirectToAuth, onError, refreshJWT: true })), 
        res.refreshTime * 1000
      )
    }
  } catch (err) {
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.AUTH_ERROR })
    onError && onError(err.message)
  }
}

export const refreshJWT = ({ onSuccess, redirectToAuth, onError }) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.REFRESH_TOKEN_FETCH })
    const { isSuccess, ...res} = await User.refreshJWT()
    if (!isSuccess) {
      dispatch({ type: actionTypes.AUTH_ERROR })
      if (res.redirectUrl && redirectToAuth) {
        redirectToAuth(res.redirectUrl)
      }
      return
    }

    dispatch({
      type: actionTypes.REFRESH_TOKEN_SUCCESS,
      data: {},
    })
    onSuccess && onSuccess()
    setTimeout(() =>
      dispatch(refreshJWT({ onSuccess, redirectToAuth, onError, refreshJWT: true })),
      res.refreshTime * 1000
    )
  } catch (err) {
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.REFRESH_TOKEN_ERROR })
    onError && onError(err.message)
  }
}

export const setKeycloak = (keycloak) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_KEYCLOAK,
    keycloak,
  })

export const logoutUser = (authType) => async (dispatch) => {
  let redirectUrl
  try {
    dispatch({ type: actionTypes.LOGOUT_FETCH })
    if (authType === 'standard') {
      const { isSuccess, ...res } = await User.logout()
      redirectUrl = res.redirectUrl
      
      if (!isSuccess) {
        if (res.needLogout) {
          dispatch(logoutProcess(res.message, redirectUrl))
          return
        }
        throw new Error(res.message)
      }
    }
    dispatch(logoutProcess('', redirectUrl))
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.LOGOUT_ERROR })
  }
}

// export const getAppSettings = () => async (dispatch) => {
// 	try {
// 		const { data } = await axios({
// 			method: 'GET',
// 			url: '/contentConstants.json',
// 		})
// 		dispatch({ type: actionTypes.GET_SETTINGS_SUCCESS, data })
// 	} catch (err) {
// 		console.log(err)
// 		dispatch(setErrorContent(err.message))
// 		dispatch({ type: actionTypes.GET_SETTINGS_ERROR })
// 	}
// }

export const getAppSettings = () => (dispatch) =>
  dispatch({ 
    type: actionTypes.GET_SETTINGS_SUCCESS, 
    data: SETTINGS, 
  })