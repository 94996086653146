import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { changeSelectedRatingType, getPromoAgentRating } from '#/src/redux/PromoAgentRating/actions'
import { getPromoRating as getPromoRatingSelector } from '#/src/redux/PromoAgentRating/selectors'
import {
  getCurrentPlaceFromRating,
  getCurrentRatingByRatingType,
  getRatingDescription
} from './utils'

export const usePromoRating = () => {
  const dispatch = useDispatch();
  const { ratingLists, selectedRatingType, period } = useSelector(getPromoRatingSelector);

  useEffect(() => {
    dispatch(getPromoAgentRating());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentRating = useMemo(
    () => getCurrentRatingByRatingType({ ratingLists, selectedRatingType }),
    [ratingLists, selectedRatingType],
  );

  const currentPlace = useMemo(
    () => currentRating && getCurrentPlaceFromRating(currentRating),
    [currentRating],
  );

  const handleChangeSelectedRatingType = (_, selectedType) => {
    dispatch(changeSelectedRatingType(selectedType))
  }

  const showSelectType = ratingLists?.length > 1

  const ratingDescription = selectedRatingType &&
    getRatingDescription({ currentPlace, selectedRatingType, period })

  return {
    currentRating,
    currentPlace,
    handleChangeSelectedRatingType,
    showSelectType,
    ratingDescription,
  }
}
