import React from 'react';
import classNames from "classnames";
  
const SliderDots = ({ dotAmount, activeSlide, slickGoTo }) => {
	return (
		<div className="slide-dots-wrapper">
			{dotAmount > 1 && Array.from({ length: dotAmount }).map((item, index) => (
				<div key={ index } onClick={ () => slickGoTo(index) } className={classNames("dot-progress", index === activeSlide && 'dot-progress-filled')} />
			))}
		</div>
	);
};

export default SliderDots;
