import React from 'react';
import cn from 'classnames';

export const FormTextField = ({
  input: {
    value,
    ...inputProps
  },
  meta: {
    touched,
    error,
  },
  label,
  disabled,
  defaultValue,
  placeholder,
  errorCustom,
  inputClassName,
  ...rest
}) =>
  <div>
    {label
      ? <label>{label}</label>
      : null
    }
    <div className='form-group'>
      <input
        type='text'
        className={cn('form-control', inputClassName)}
        disabled={disabled}
        value={value || defaultValue || ''}
        placeholder={placeholder}
        {...rest}
        {...inputProps}
      />
      {touched && error &&
        <span className='form-error'>{error}</span>
      }
      {errorCustom &&
        <span className='form-error'>{errorCustom}</span>
      }
    </div>
  </div>
