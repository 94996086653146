export const styles = {
    podium: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      marginBottom: '8px',
      width: '100%'
    },
    current: {
      color: '#ef3124',
    },
    place: {
      height: '70px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    podiumElement: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    second: {
      height: '50%',
      borderRadius: '4px 0 0 4px',
      borderRight: '0',
      boxShadow: '-5px 1px 5px -5px rgba(34, 60, 80, 0.6) inset',
      background: `
        conic-gradient(
          #d7d7d7,
          #c3c3c3,
          #ccc,
          #c6c6c6,
          #d3d3d3,
          #d8d8d8,
          #d5d5d5,
          #d8d8d8,
          #d3d3d3,
          #c5c5c5,
          #c0c0c0,
          #bfbfbf,
          #d0d0d0,
          #d9d9d9,
          #d1d1d1,
          #c5c5c5,
          #c8c8c8,
          #d7d7d7,
          #d5d5d5,
          #cdcdcd,
          #c4c4c4,
          #d9d9d9,
          #cecece,
          #c5c5c5,
          #c5c5c5,
          #cdcdcd,
          #d8d8d8,
          #d9d9d9,
          #d7d7d7
        )
        content-box,
        linear-gradient(#d4d4d4, #d4d4d4) padding-box,
        radial-gradient(rgba(120, 120, 120, 0.9), rgba(120, 120, 120, 0) 70%) 50% bottom/80%
          0.46875em no-repeat border-box;
      `,
    },
    first: {
      height: '70%',
      borderRadius: '4px 4px 0 0',
      background: `
        conic-gradient(
          #edc800,
          #e3b600,
          #f3cf00,
          #ffe800,
          #ffe900,
          #ffeb00,
          #ffe000,
          #ebc500,
          #e0b100,
          #f1cc00,
          #fcdc00,
          #ffe500,
          #fad900,
          #eec200,
          #e7b900,
          #f7d300,
          #ffe800,
          #ffe300,
          #f5d100,
          #e6b900,
          #e3b600,
          #f4d000,
          #ffe400,
          #ebc600,
          #e3b600,
          #f6d500,
          #ffe900,
          #ffe90a,
          #edc800
        )
        content-box,
        linear-gradient(#f6d600, #f6d600) padding-box,
        radial-gradient(rgba(120, 120, 120, 0.9), rgba(120, 120, 120, 0) 70%) 50% bottom/80%
          0.46875em no-repeat border-box;
      `,
    },
    third: {
      height: '30%',
      borderRadius: '0 4px 4px 0',
      borderLeft: '0',
      boxShadow: '10px 0 10px -10px rgba(34, 60, 80, 0.6) inset',
      background: `
        conic-gradient(
          #d95641,
          #b14439,
          #b2453a,
          #d25645,
          #d56847,
          #d05441,
          #b85137,
          #b2453a,
          #c34f40,
          #df4647,
          #a94338,
          #c94943,
          #c85442,
          #a4413c,
          #d9543a,
          #d1564e,
          #ab4338,
          #bb4a3c,
          #dc5843,
          #b94839,
          #aa4237,
          #c24e42,
          #ce523f,
          #ab4338,
          #dd5944,
          #ca4d33,
          #ab4338,
          #cb503e,
          #d95641
        )
        content-box,
        linear-gradient(#ad3b36, #ad3b36) padding-box,
        radial-gradient(rgba(120, 120, 120, 0.9), rgba(120, 120, 120, 0) 70%) 50% bottom/80%
          0.46875em no-repeat border-box;
      `,
    },
  };
