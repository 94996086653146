import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

export const plural = (number, one, two, five) => {
  let result = ''
  if ((number - number % 10) % 100 !== 10) {
    if (number % 10 === 1) {
      result = one
    } else if (number % 10 >= 2 && number % 10 <= 4) {
      result = two
    } else {
      result = five
    }
  } else {
    result = five
  }
  return result
}

export const formattedNumber = (number) => {
  if (!number) {
    return 0
  }
  
  return (number.toFixed(2)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

export const formattedNumberToGekto = (number) => {
  if (!number) {
    return 0
  }
  
  return ((number/100).toFixed(2)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

export const formattedNumberToKilo = (number) => {
  if (!number) {
    return 0
  }
  
  return ((number/1000).toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

export const formattedDate = (date) => date ? moment(date).format('DD.MM.YYYY') : ''
export const formattedDateTime = (date) => date ? moment(date).format('DD.MM.YYYY в HH:mm:ss') : ''
export const YYYYMMDD = (date) => date ? moment(date).format('YYYY-MM-DD') : ''

export const getLastMonths = (months = 1) => {
  let stepDateArray = []
  for (let i = 0; i < months; i++) {
    const nextMonth = moment(new Date()).add(-i, 'month')
    stepDateArray = [
      ...stepDateArray,
      {
        id: i + 1,
        name: moment(nextMonth).format('MMMM, YYYY'),
        period: moment(nextMonth).format('YYYY.MM'),
      },
    ]
  }
  return stepDateArray
}

export const isPressEscape = (e) => e.keyCode === 27

export const formattedCard = (string) => {
  if (!string) return null
  // 98765432112345678901 --> 98765.432.1.12345678901
  const s1 = string.substring(0, 5)
  const s2 = string.substring(5, 8)
  const s3 = string.substring(8, 9)
  const s4 = string.substring(9, 20)
  return `${s1}.${s2}.${s3}.${s4}`
}

export const formattedDateShort = (string) => {
  if (!string) return null
  // 28.08.2021 --> 28.08.21
  const s1 = string.substring(0, 6)
  const s2 = string.substring(8, 10)
  return `${s1}${s2}`
}

export const formatNumber = (value = 0, needPad) => {
  const parts = value.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  if (parts[1] && needPad) {
    parts[1] = parts[1].padEnd(2, '0')
  }
  return parts.join('.')
}