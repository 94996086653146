import * as types from './actionTypes'
import { PromoRating } from '../../services/api'

export const getPromoAgentRating = () => async (dispatch) => {
  const response = await PromoRating.getPromoAgentRating()
  const { isSuccess, data } = response;

  if(isSuccess) {
    dispatch({
      type: types.GET_PROMO_RATING_SUCCESS,
      data: data,
    })
  }
}

export const changeSelectedRatingType = (selectedRatingType) => async (dispatch) => {
  dispatch({
    type: types.CHANGE_SELECTED_RATING_TYPE,
    data: selectedRatingType,
  })
}
