import { initialize as initializeForm } from 'redux-form';
import * as actionTypes from './actionTypes'
import { Agents } from '../../services/api'
import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'
import { editAgentFormName } from '#/src/containers/Form/EditAgent/constants';

const prepareChildrenAgent = (ids, list) => {
  const rootAgents = []
  ids.forEach(id => {
    const parent = list[id].parentId
    if (parent) {
      list[parent].children.push(id)
    } else {
      rootAgents.push(id)
    }
  })
  return { rootAgents, ids, list }
}

const getSubAgentParams = async (formData) => {
  if (typeof formData.companyId === 'string') {
    return formData
  }

  const { isSuccess, ...res } = await Agents.createCompany(formData.companyId)
  if (!isSuccess) {
    throw new Error(res.message)
  }

  return {
    ...formData,
    companyId: res.company_id,
  }
}

export const getAgentsList = (needPrepareChildren = false) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.AGENTS_FETCH })
    const { isSuccess, ...res } = await Agents.getData()
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    const { agents, ...restRes } = res
    const { list, ids } = agents.reduce((acc, item) => {
      return {
        list: Object.assign({}, acc.list, {
          [`${item.id}`]: Object.assign({}, item, { children: [] }),
        }),
        ids: acc.ids.concat(item.id)
      }
    }, { list: {}, ids: [] })
    dispatch({
      type: actionTypes.AGENTS_SUCCESS,
      data: Object.assign(
        {},
        {
          agents,
          ...restRes,
        },
        needPrepareChildren
          ? prepareChildrenAgent(ids, list)
          : {}
      )
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.AGENTS_ERROR })
  }
}

export const getAgentUsersList = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.AGENT_USERS_FETCH })
    const { isSuccess, ...res } = await Agents.getUsersList(companyId)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ type: actionTypes.AGENT_USERS_SUCCESS, data: { users: res.user } })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.AGENT_USERS_ERROR })
  }
}

export const setUserEnable = (username) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.AGENT_USER_CHANGE_STATUS_FETCH, user: username })
    const { isSuccess, ...res } = await Agents.changeUserStatus.setEnable(username)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ type: actionTypes.AGENT_USER_CHANGE_STATUS_SUCCESS })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.AGENT_USER_CHANGE_STATUS_ERROR })
  }
}

export const setUserDisable = (username) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.AGENT_USER_CHANGE_STATUS_FETCH, user: username })
    const { isSuccess, ...res } = await Agents.changeUserStatus.setDisable(username)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ type: actionTypes.AGENT_USER_CHANGE_STATUS_SUCCESS })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.AGENT_USER_CHANGE_STATUS_ERROR })
  }
}

export const fetchUserData = ({ userId }) => async (dispatch) => {
  try {
    const { user, isSuccess, message } = await Agents.getUserData(userId);

    if (!isSuccess) {
      dispatch(setErrorContent(message));
      return;
    }

    const { email, firstName, ismanager, lastName, phone, username } = user;

    dispatch(initializeForm(editAgentFormName, {
      name: `${lastName} ${firstName}`,
      username: username,
      phone: phone,
      email: email,
      ismanager: ismanager ? '1' : '0'
    }));

    dispatch({ type: actionTypes.AGENT_USER_EDIT_INITIALIZED })
  } catch ({ message }) {
    dispatch(setErrorContent(message));
  }
}

export const addUser = ({ name, ismanager, ...restData }) => async (dispatch) => {
  const onError = (error) => {
    console.log(error)
    dispatch({
      type: actionTypes.AGENT_USER_ADD_ERROR,
      error,
    })
  }
  try {
    dispatch({ type: actionTypes.AGENT_USER_ADD_FETCH })
    const names = name.split(' ')
    const data = Object.assign({}, restData, {
      lastName: names[0],
      firstName: names[1],
      ismanager: +ismanager,
    })
    const { isSuccess, ...res } = await Agents.createUser(data)
    if (isSuccess) {
      dispatch({ type: actionTypes.AGENT_USER_ADD_SUCCESS })
    } else {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      onError(res.error)
    }
  } catch (error) {
    onError(error)
  }
}

export function resetAddingUserStatus() {
  return dispatch => dispatch({ type: actionTypes.AGENT_USER_ADD_RESET })
}

export const editUser = ({ name, ismanager, ...restData }) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.AGENT_USER_EDIT_FETCH })
    const names = name.split(' ')
    const data = Object.assign({}, restData, {
      lastName: names[0],
      firstName: names[1],
      ismanager: +ismanager,
    })
    const { isSuccess, ...res } = await Agents.editUser(data)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ type: actionTypes.AGENT_USER_EDIT_SUCCESS })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.AGENT_USER_EDIT_ERROR })
  }
}

export const resetEditingUserStatus = () => (dispatch) =>
  dispatch({ type: actionTypes.AGENT_USER_EDIT_RESET })

export const addSubagent = (data) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.AGENT_SUB_NEW_FETCH })
    const params = await getSubAgentParams(data)
    const { isSuccess, ...res } = await Agents.createSubagent(params)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ type: actionTypes.AGENT_SUB_NEW_SUCCESS })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.AGENT_SUB_NEW_ERROR })
  }
}

export const resetAddingSubagentStatus = () => (dispatch) =>
  dispatch({ type: actionTypes.AGENT_SUB_NEW_RESET })

export const resetAddUserError = () => (dispatch) =>
  dispatch({
    type: actionTypes.RESET_ADD_USER_ERROR
  })
