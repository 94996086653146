import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { AgentCard } from '#/src/components/Card/Agent'
import Skelet from '#/src/components/Card/Skelet'

class AgentsList extends Component {
	static propTypes = {
		rootAgents: PropTypes.array.isRequired,
		list: PropTypes.object.isRequired,
		isLoading: PropTypes.bool.isRequired,
		onShowClients: PropTypes.func.isRequired,
	}

	renderSkeleton = () => {
		return (
			<div className='fr-agents-list'>
				{[0, 1, 2, 3].map((_, index) =>
					<Skelet key={index} />
				)}
			</div>
		)
	}

	renderCard = (item, level) => {
		const { list, onShowClients, statusItems } = this.props

		return (
			<Fragment>
				<AgentCard
					key={item.id}
					id={item.id}
					level={level}
					name={item.displayName}
					inn={item.INN}
					statList={item.stat}
					agentsCount={item.agentLogins ? item.agentLogins.length : 0}
					companyCount={item.company_count}
					onShowClients={onShowClients}
					statusItems={statusItems}
				/>
				{item.children.map((id, index) =>
					<Fragment key={index}>
						{this.renderCard(list[id], level + 1)}
					</Fragment>
				)}
			</Fragment>
		)
	}

	render() {
		const { list, rootAgents, isLoading } = this.props

		if (isLoading) {
			this.renderSkeleton()
		}

		return (
			<div className='fr-agents-list'>
				{rootAgents.map((id, index) =>
					<Fragment key={index}>
						{this.renderCard(list[id], 0)}
					</Fragment>
				)}
			</div>
		)
	}
}

export default AgentsList
