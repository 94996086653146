import React from 'react';
import cn from 'classnames';
import { createUseStyles } from 'react-jss'

import { getRomanianPlace } from './utils';
import { styles } from './styles';
import { RATING_CONFIG_BY_TYPE } from '#/src/containers/Filter/PromoRating/constants'

const useStyles = createUseStyles(styles);

export const PodiumPlace = ({ score, place, isCurrent, ratingType }) => {
  const classes = useStyles();

  const PLACE_STYLES_MAP = {
    1: classes.first,
    2: classes.second,
    3: classes.third,
  };


  return (
    <div className={ classes.place }>
      <span className={ cn(isCurrent && classes.current) }>{ score } { RATING_CONFIG_BY_TYPE[ratingType].scoreUnits }</span>
      <div className={ cn(classes.podiumElement, PLACE_STYLES_MAP[place]) }>
        { getRomanianPlace(place) }
      </div>
    </div>
  )
};
