import React from 'react';

import { styles } from './styles';
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(styles);


export const PlaceTitle = ({
   iconSrc,
   place,
   iconWidth = 30,
   iconHeight = 30,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={ classes.imageContainer }>
        <img width={iconWidth} height={iconHeight} src={iconSrc} alt="placeIcon" />
      </div>
      &nbsp;{place}
    </React.Fragment>
  )
};
