import { COLORS } from '../../constants/styleVariables';

export const styles = {
  file: {
    backgroundColor: '#EAECF5',
    padding: '13px 15px',
    border: '1px solid #EAECF5',
    borderRadius: '12px',
    fontSize: '15px',
    fontWeight: 500
  },
  header: {
    display: 'flex',
    alignItems: 'baseline',
    lineHeight: '18px'
  },
  links: {
    paddingLeft: '15px',
    flexShrink: 0,
    marginLeft: 'auto'
  },
  link: {
    marginRight: '10px',
    fontSize: '14px',
    color: COLORS.textLinkColor,

    '&:hover, &:active': {
      color: COLORS.textLinkColor
    },

    '&:last-child': {
      marginRight: 0
    }
  },
  downloadIcon: {
    width: 18,
    height: 18,
    marginRight: '5px'
  },
  checkIcon: {
    marginLeft: '5px',
    width: '16px',
    height: '16px'
  },
  crossIcon: {
    width: '12px',
    height: '12px',
    marginLeft: '5px',
    fill: COLORS.textErrorColor
  },
  tooltip: {
    maxWidth: '400px'
  },
  footer: {
    display: 'flex',
    alignItems: 'baseline',
    fontSize: '12px',
    lineHeight: 1,
    color: COLORS.textSecondaryColor
  },
  checked: {
    marginLeft: 'auto'
  }
};
