import React from 'react';


import { RatingList } from '../RatingList';

import { PodiumPlace } from './PodiumPlace';
import { reducePodiumRating } from './utils';

import { createUseStyles } from 'react-jss'

import { styles } from './styles';

const useStyles = createUseStyles(styles);

export const Podium = ({ rating, currentPlace }) => {
    const classes = useStyles();
    const { medalists, others } = reducePodiumRating(rating);

    return (
        <React.Fragment>
            <div className={ classes.podium }>
                <PodiumPlace { ...medalists[1] } ratingType={ rating.ratingType } />
                <PodiumPlace { ...medalists[0] } ratingType={ rating.ratingType } />
                <PodiumPlace { ...medalists[2] } ratingType={ rating.ratingType } />
            </div>
            <RatingList rating={ others } currentPlace={ currentPlace }/>
        </React.Fragment>
    );
};
