import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'

const styles = {
  root: {
    marginBottom: '16px',
    fontSize: '12px',
    letterSpacing: '0.5px',
    color: '#FF9494',
    textAlign: 'center',
  },
}

const FormErrorComponent = ({
  classes,
  className,
  children,
}) =>
  <div className={classnames(classes.root, className)}>{children}</div>

export const FormError = injectSheet(styles)(FormErrorComponent)
