import React from 'react';
import { connect } from 'react-redux'
import { Modal } from '../../../layout'
import { getOrderMainInfoViewModel } from '../../../redux/Company/selectors'

const MainInfoComponent = ({
  orderInfoContent,
  principalName,
  principalInn,
  principalKpp,
  principalId,
  calculatedAmount,
  finalAmount,
  isLimits,
  isGuarantee,
  isReissue
}) => {
  return <>
    <Modal.Principal name={principalName} inn={principalInn} kpp={principalKpp} id={principalId} />
    {isLimits && <Modal.Limits calculatedAmount={calculatedAmount} finalAmount={finalAmount} />}
    {(isGuarantee || isReissue) && <Modal.Info content={orderInfoContent} />}
  </>
}

export const MainInfo = connect(getOrderMainInfoViewModel)(MainInfoComponent)
