import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import keycloak from '../keycloak'
import { IS_NEW_AUTH } from '../config'

const TOKEN_KEY = 'JWT'

export const setToken = (token) => IS_NEW_AUTH
  ? Cookies.set(TOKEN_KEY, token, 365)
  : localStorage.setItem(TOKEN_KEY, token)

export const getToken = () => Cookies.get(TOKEN_KEY)

export const getTokenParsed = () => {
  const JWT = getToken()
  const token = JWT && JWT !== 'undefined' ? jwtDecode(JWT) : false
  return token
}

export const isValidToken = () => {
  try {
    const token = getTokenParsed()
    const isExpired = keycloak.isTokenExpired();

    return token && !isExpired
  } catch (err) {
    // keycloak can throw not authorized error
    return false
  }
}

export const onLogin = () => keycloak.login()

export const onLogout = () => {
  keycloak.logout()
  IS_NEW_AUTH
    ? Cookies.remove(TOKEN_KEY)
    : localStorage.removeItem(TOKEN_KEY)
}