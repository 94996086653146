import * as actionTypes from './actionTypes'

const initialState = {
  isExpand: false,
  isFetching: false,
  cards: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORIES_FETCH:
      return {
        ...state,
        isFetching: true,
      }
    case actionTypes.STORIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.data,
      }
    case actionTypes.STORIES_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    case actionTypes.OPEN_NEW_YEAR_STORIES:
      return {
        ...state,
        isExpand: true,
      }
    case actionTypes.CLOSE_NEW_YEAR_STORIES:
      return {
        ...state,
        isExpand: false,
      }
    default:
      return state
  }
}