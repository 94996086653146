import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
	root: {
		color: '#898989',
		fontSize: '10px',
		lineHeight: '18px',
		fontWeight: 500,
		letterSpacing: '1px',
		textTransform: 'uppercase',
	},
}

const Title = ({ 
	classes,
	className,
  children,
}) => <div className={classnames(classes.root, className)}>{children}</div>

export default injectSheet(styles)(Title)