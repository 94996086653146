import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { Modal } from './../../index'

const styles = {
	root: {
    paddingBottom: '20px',
    borderBottom: '1px solid #DDDDDD',
    position: 'relative',
  },
  item: {
    paddingRight: '230px',
    position: 'relative',
    '& + &': {
      marginTop: '20px',
    },
  },
  right: {
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'right',
  },
  lots: {
    padding: '30px 0',
    position: 'relative',
  },
  info: {
    // background: '#E4E4E4',
    // width: 'calc(100% + 144px)',
    // marginLeft: '-74px',
    // marginTop: '35px',
    // padding: '0 75px',
    position: 'relative',
  },
  infoItem: {
    background: '#ECECEC',
    width: '170px',
    padding: '25px 0',
    textAlign: 'center',
    display: 'inline-block',
    position: 'relative',
    '& + &': {
      borderLeft: '1px solid #E4E4E4',
    },
  },
}

const Info = ({ 
  content: { 
    info = [], 
    data = [],
    lots = [],
    documents = [],
  },
  classes, 
}) => 
  <Modal.Block iconName='file' title='Сведения о закупке / контракте'>
    <Modal.Content>
      <div className={classes.root}>
        {info.map(({ items }, index) =>
          <div className={classes.item} key={`info-${index}`}>
            {items.map(({ title, value, right }, subIndex) => 
              <div 
                className={classnames({ [classes.right]: right })} 
                key={`info-${index}-${subIndex}`}
              >
                <Modal.Value>{value}</Modal.Value>
                <Modal.Title>{title}</Modal.Title>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={classes.lots}>
        {(lots || []).map((lot, index) =>
          <Modal.Lot {...lot} documents={documents} key={`lot-${index}`} />
        )}
      </div>
      <ul className={classes.info}>
        {data.map(({ title, value }, index) =>
          <li className={classes.infoItem} key={`data-${index}`}>
            <Modal.Title>{title}</Modal.Title>
            <Modal.Value>{value}</Modal.Value>
          </li>
        )}
      </ul>
    </Modal.Content>
  </Modal.Block>

export default injectSheet(styles)(Info)