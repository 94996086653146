import { createUseStyles } from 'react-jss'
import { adaptive_1200, THEME_COLOR } from '../../config'

export const useRegisterStyles = createUseStyles({
  root: {
    backgroundColor: 'rgb(226, 226, 226)',
    minHeight: '100vh',
    paddingTop: 30,
    paddingBottom: 1,
    fontFamily: 'Montserrat',
    [adaptive_1200]: {
      paddingTop: 0,
    },
  },
  header: {
    backgroundColor: THEME_COLOR,
  },
  wrapper: {
    width: '100vw',
    height: '100vh',
    padding: [50, 100],
    position: 'relative',
  },
  contentClass: {
    minWidth: 520,
  },
  logoContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    width: 41,
    height: 28,
    display: 'block',
    position: 'absolute',
    top: 50,
    left: 50,
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  modal: {
    width: 520,
    height: 420,
  },
  background: {
    width: 315,
    height: 157,
  },
  icon: {
    width: 88,
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-38%)',
  },
  stopIcon: {
    width: 96,
    height: 96,
    marginTop: 40,
    marginBottom: 24
  },
  h1: {
    marginTop: 20,
    lineHeight: 1.5,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 24,
  },
  p: {
    marginTop: 20,
    fontSize: 16,
    lineHeight: 1.25,
    fontWeight: 500,
    textAlign: 'center',
  },
  pError: {
    maxWidth: 400,
    marginBottom: 24,
  }
})
