import React from 'react';
import PropTypes from 'prop-types';

export const Link = (props) => (
  <a {...props} rel='noreferrer noopener'>{props.children}</a>
);

Link.propTypes = {
  href: PropTypes.string.isRequired
};
