import React, { Fragment, useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { createPortal } from 'react-dom'
import injectSheet from 'react-jss'
import Close from './Close'
import { isPressEscape } from '../../../utils'

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 20000,
    '&:before': {
      content: '""',
      display: 'block',
      background: '#1e1e1e',
      opacity: 0.9,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 20000,
    },
  },
  wrapper: {
    width: '1210px',
    position: 'fixed',
    top: '6px',
    bottom: '6px',
    left: '50%',
    transform: 'translateX(calc(-50% - 25px))',
    borderRadius: '16px',
    overflowY: 'auto',
    zIndex: 30000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.fixWidth': {
      '& > div': {
        width: '100%',
      },
    },
    '&.fixHeight': {
      top: '50%',
      transform: 'translate(calc(-50% - 25px), -50%)',
      '& > div': {
        overflowY: 'visible',
      },
    },
  },
  modal: {
    background: '#fff',
    maxHeight: '100%',
    maxWidth: ' 100%',
    height: 'auto',
    padding: '30px',
    overflowY: 'auto',
    borderRadius: '16px',
  },
}

const Modal = ({
  children,
  classes,
  className,
  width,
  height,
  onClose,
}) => {
  const [position, setPosition] = useState({})
  const portalContainerRef = useRef(document.createElement('div'));

  useEffect(() => {
    const root = document.getElementById('root')
    portalContainerRef.current.classList.add(classes.root)
    document.body.insertBefore(portalContainerRef.current, root)

    return () => {
      document.body.removeChild(portalContainerRef.current)
    }
  }, [])

  const CSS = {
    width,
    height,
  }

  const modalRef = useRef(null)

  useEffect(() => {
    const { top, left, width } = modalRef.current.getBoundingClientRect()
    setPosition({
      top: `${top}px`,
      left: `${left + width + 15}px`,
      transform: 'none',
    })
  }, [modalRef])

  const onEscape = (e) => {
    if (isPressEscape(e)) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', onEscape, false)
    return () => {
      document.removeEventListener('keyup', onEscape, false)
    }
  }, [])

  return createPortal(
    <Fragment>
      <div className={classnames(classes.wrapper, {'fixWidth': width, 'fixHeight': height})} style={CSS}>
        <div ref={modalRef} className={classnames(classes.modal, className)}>
          {children}
        </div>
      </div>
      <Close onClose={onClose} style={position} />
    </Fragment>, portalContainerRef.current)
}

export default injectSheet(styles)(Modal)
