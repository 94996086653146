import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
  root: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '1px',
  },
}

const Key = ({ children, className, classes }) =>
  <div className={classnames(classes.root, className)}>{children}</div>

export default injectSheet(styles)(Key)