import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ClearButton from '../ClearButton'

class TextField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string,
    icon: PropTypes.string,
  }

  static defaultProps = { value: '' }

  handleClearField = () => {
    const { name, onClear } = this.props
    onClear(name, '')
  }

  render() {
    const { name, onChange, value, placeholder, icon } = this.props

    return (
      <div className={classnames(
        'main-filter__control',
        {'main-filter__control--icon-left': !!icon}
      )}>
        {icon && <span className={`icon ${icon}`} />}
        <input
          type='text'
          className='main-filter__control-field'
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          // {...rest}
        />
        <ClearButton
          onClear={this.handleClearField}
          isHidden={!value.length}
        />
      </div>
    )
  }
}

export default TextField