import axios from 'axios'
import { SERVER } from '../constants'

export const login = async (authData) => {
  const options = {
    method: 'POST',
    url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/login`,
    data: authData,
  }
  try {
    const { data: { error_code, ...rest } } = await axios(options)
    const isSuccess = error_code === 0
    const needLogout = error_code === 2
    if (isSuccess) {
      return {
        isSuccess,
        ...rest,
      }
    }
    return {
      isSuccess,
      needLogout,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const auth = async () => {
  try {
    const { data: { error_code, ...rest } } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/session`,
    })
    const isSuccess = error_code === 0
    return {
      isSuccess,
      ...rest,
    }
    // if (error_code === 2) {
    //   return {
    //     isSuccess: false,
    //     message: 'Пользователя с данной сессией не найдено!',
    //   }
    // }
    // return {
    //   isSuccess: false,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const refreshJWT = async () => {
  const options = {
    method: 'GET',
    url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/refreshJWT`,
  }
  try {
    const { data: { error_code, ...rest } } = await axios(options)
    const isSuccess = error_code === 0
    return {
      isSuccess,
      ...rest,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const logout = async () => {
  const options = {
    method: 'GET',
    url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/logout`,
  }
  try {
    const { data: { error_code, ...rest } } = await axios(options)
    const isSuccess = error_code === 0
    const needLogout = error_code === 2
    if (isSuccess) {
      return {
        isSuccess,
        ...rest,
      }
    }
    return {
      isSuccess,
      needLogout,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const recoverPassword = async (username) => {
  const options = {
    method: 'POST',
    url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/recover`,
    data: { username }
  }
  try {
    const { data: { error_code, ...rest } } = await axios(options)
    const isSuccess = error_code === 0
    if (isSuccess) {
      return { isSuccess }
    }
    return {
      isSuccess,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const closeSession = async () => {
  const options = {
    params: {'_': Date.now()},
    method: 'GET',
    url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/logout`
  }
  try {
    const { data: { error_code, ...rest } } = await axios(options)
    const isSuccess = error_code === 0
    if (isSuccess) {
      return {
        isSuccess,
        ...rest,
      }
    }
    return {
      isSuccess,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const setJWT = async (JWT) => {
  const options = {
    // method: 'GET',
    // url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/setJWT`,
    // params: {JWT},
    method: 'POST',
    url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/setJWT`,
    data: { JWT },
  }
  try {
    const { data: { error_code } } = await axios(options)
    const isSuccess = error_code === 0
    return { isSuccess }
  } catch (err) {
    return { isSuccess: false }
  }
}
