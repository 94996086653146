import Cookies from 'js-cookie'

const {
  REACT_APP_ENV,
  REACT_APP_BUILD_ID,
  REACT_APP_THEME_COLOR,
  REACT_APP_REGISTER_ERROR_MESSAGE,
  REACT_APP_HIDE_CHANGE_PASSWORD,
  REACT_APP_SHOW_STATS,
  REACT_APP_SHOW_TASKS_FILTER_SUMM,
  REACT_APP_SHOW_TASKS_FILTER_CLIENT,
  REACT_APP_SHOW_CLIENTS_FILTER_STATUS,
  REACT_APP_LANDING_DADATA,
  REACT_APP_NEW_AUTH,
  REACT_APP_KEYCLOAK_TYPE,
  REACT_APP_AUTHTYPE,
} = process.env

export const JWT = Cookies.get('JWT')

export const ENV = REACT_APP_ENV
export const BUILD_ID = REACT_APP_BUILD_ID

console.info(`REACT_APP_ENV - ${ENV}`)
console.info(`REACT_APP_BUILD_ID - ${BUILD_ID}`)

export const THEME_COLOR = REACT_APP_THEME_COLOR || '#242232'
export const REGISTER_ERROR_MESSAGE = REACT_APP_REGISTER_ERROR_MESSAGE || ''

export const HIDE_CHANGE_PASSWORD = REACT_APP_HIDE_CHANGE_PASSWORD === 'true'

export const SHOW_STATS = REACT_APP_SHOW_STATS === 'true'
export const SHOW_TASKS_FILTER_SUMM = REACT_APP_SHOW_TASKS_FILTER_SUMM === 'true'
export const SHOW_TASKS_FILTER_CLIENT = REACT_APP_SHOW_TASKS_FILTER_CLIENT === 'true'
export const SHOW_CLIENTS_FILTER_STATUS = REACT_APP_SHOW_CLIENTS_FILTER_STATUS === 'true'

export const IS_LANDING_DADATA = REACT_APP_LANDING_DADATA === 'true'


export const IS_PROD = process.env.NODE_ENV === 'production'
export const IS_DEV = process.env.NODE_ENV === 'development'

export const IS_NEW_AUTH = REACT_APP_NEW_AUTH === 'true';

export const IS_COCONUT = BUILD_ID === 'coconut'

export const KEYCLOAK_AUTH_TYPE = REACT_APP_KEYCLOAK_TYPE || 'check-sso'

export const COLOR_KEYS = {
  PURPLE: 'purple',
  BLUE: 'blue',
  YELLOW: 'yellow',
  RED: 'red',
  GREEN: 'green',
}

export const COLOR_VALUES = {
  purple: '#722ED1',
  blue: '#538DD5',
  yellow: '#E98C0D',
  red: '#FF0D19',
  green: '#41B807',
}

export const COLOR_VALUES_MIDDLE = {
  purple: '#D3ADF7',
  blue: '#ADC6FF',
  yellow: '#E9CA66',
  red: '#F27C75',
  green: '#ACE97D',
}

export const COLOR_VALUES_LIGHT = {
  purple: '#F9F0FF',
  blue: '#F0F5FF',
  yellow: '#FFFBE6',
  red: '#FFEFEE',
  green: '#F4FFEA',
}

export const SETTINGS = {
  authType: REACT_APP_AUTHTYPE || 'keycloak',
  PHONE: '+7 (495) 799–09–83',
  EMAIL: 'support@farzoom.com',
  COPYRIGHT: 'FarZoom #ACTUAL_DATE# © v. 1.2.9.1 ßeta 2',
  statusItems: [
    { key: 'total', text: 'Всего' },
    { key: 'assigned', text: 'К выполнению', className: COLOR_KEYS.PURPLE, color: COLOR_VALUES.PURPLE },
    { key: 'inprogress', text: 'На стороне банка', className: COLOR_KEYS.YELLOW, color: COLOR_VALUES.YELLOW },
    { key: 'lost', text: 'Отказано', className: COLOR_KEYS.RED, color: COLOR_VALUES.RED },
    { key: 'sold', text: 'Одобрено', className: COLOR_KEYS.GREEN, color: COLOR_VALUES.GREEN },
  ],
}

export const COLORS_BY_ORDER_STATUS_CATEGORY = {
  total: '',
  assignedAgent: COLOR_KEYS.PURPLE,
  assignedClient: COLOR_KEYS.BLUE,
  inprogress: COLOR_KEYS.YELLOW,
  sold: COLOR_KEYS.GREEN,
  lost: COLOR_KEYS.RED,
}

export const adaptive_1200 = '@media (max-width: 1199.98px)'
export const adaptive_1400 = '@media (max-width: 1400px)'
export const adaptive_600 = '@media (max-width: 600px)'
