import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
  label: {
    whiteSpace: 'nowrap',
  },
  list: {
    height: 0, 
    position: 'relative', 
    zIndex: 10,
  },
  item: {
    position: 'absolute',
    top: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    overflow: 'hidden',
    boxShadow: '0px 3px 6px rgba(54, 70, 88, 0.15)',
    left: 0,
    right: 0,
  },
}

const Item = ({
  classes,
  name,
  value = '',
  title = '',
  text = '',
  className = '',
  isChecked = false,
  onChange,
  descendants,
  activeValue,
  isFirst = false,
  isDescendant = false,
  // isLastDescendant = false,
}) => {
  const [openDescendant, setOpenDescendant] = useState(false)
  
  const borderRadius = !isDescendant && isFirst
    ? '8px 0 0 8px'
    : '0'

  const onMouseEnter = () => setOpenDescendant(true)
  const onMouseLeave = () => setOpenDescendant(false)

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className={classnames('checkbox-list__item', {
        [`checkbox-list__item--${className}`]: className,
        [`checkbox-list__item--descendant`]: isDescendant,
      })} style={{ borderRadius }}>
        <input
          type='radio'
          name={name}
          value={value}
          id={value}
          checked={isChecked}
          onChange={onChange}
        />
        <label
          className={classes && classes.label}
          htmlFor={value}
          title={title || text}
        >
          {text}
        </label>
      </div>
      {descendants && openDescendant &&
        <div className={classes.list}>
          <div className={classes.item}>
            {descendants.map(({ key, text, name }, index) =>
              <Item
                key={index}
                onChange={onChange}
                name='status'
                value={key}
                text={name}
                isChecked={activeValue === key}
                className={className}
                isDescendant
                isLastDescendant={descendants.length - 1 === index}
              />
            )}
          </div>
        </div>
      }
    </div>
  )
}

Item.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.node,
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

export default injectSheet(styles)(Item)