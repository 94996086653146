import { FormTextField } from '#/src/components/_formFields/FormTextField';
import * as validation from '#/src/utils/validationFunctions';
import { FormInputPhone } from '#/src/components/_formFields/FormInputPhone';
import { DropdownField } from '../DropdownField';

export const newAgentFormFields = [
  {
    name: 'name',
    type: 'text',
    placeholder: 'ФИО',
    'aria-describedby': 'fioHelp',
    inputClassName: 'form-control--transparent',
    component: FormTextField,
    validate: [validation.required, validation.fullName]
  },
  {
    name: 'username',
    type: 'text',
    placeholder: 'Логин',
    autoComplete: 'off',
    inputClassName: 'form-control--transparent',
    component: FormTextField,
    validate: [validation.required]
  },
  {
    name: 'email',
    type: 'email',
    placeholder: 'Эл. почта',
    'aria-describedby': 'emailHelp',
    inputClassName: 'form-control--transparent',
    component: FormTextField,
    validate: [validation.required, validation.email]
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Пароль',
    autoComplete: 'new-password',
    id: 'new-password',
    inputClassName: 'form-control--transparent',
    component: FormTextField,
    validate: [validation.required]
  },
  {
    name: 'phone',
    type: 'text',
    placeholder: 'Номер телефона',
    inputClassName: 'form-control--transparent',
    component: FormInputPhone,
    validate: [validation.phone]
  },
  {
    name: 'ismanager',
    component: DropdownField,
    validate: [validation.required]
  }
];
