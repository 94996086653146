import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import TasksFilter from '../../containers/Filter/Tasks'
import TasksList from '../../containers/List/Tasks'
import EmptyTasksList from '../../components/Empty/TasksList'
import { getStoriesInfo } from '#/src/redux/Stories/actions'
import {
  getTasksList,
  getNextTasksPage,
  setTasksFilter,
  clearAllFilters
} from '../../redux/Tasks/actions'
import { authenticationUser } from '../../redux/User/actions'
import { getTasksViewModel } from '#/src/redux/Tasks/selectors';
import { PromoRating } from '#/src/containers/Filter/PromoRating'

class Tasks extends PureComponent {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    isFetchingNext: PropTypes.bool.isRequired,
    showNewYearStories: PropTypes.bool,
    list: PropTypes.array,
    filters: PropTypes.object,
    filterAmount: PropTypes.shape({
      from: PropTypes.number.isRequired,
      to: PropTypes.number.isRequired
    }).isRequired,
    nextPage: PropTypes.number.isRequired,
    hasMorePage: PropTypes.bool.isRequired,
    processDefinitionKeys: PropTypes.array.isRequired,

    getTasksList: PropTypes.func.isRequired,
    getNextTasksPage: PropTypes.func.isRequired,
    clearAllFilters: PropTypes.func.isRequired,
    setTasksFilter: PropTypes.func.isRequired,
    authenticationUser: PropTypes.func.isRequired,
    getStoriesInfo: PropTypes.func.isRequired
  }

  componentDidMount() {
    const { filters, getStoriesInfo, username, showNewYearStories } = this.props

    if (showNewYearStories === undefined) {
      getStoriesInfo(username)
    }

    this.fetchTasksDebounced(filters)
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onScroll)
  }

  componentWillReceiveProps(nextProps) {
    const { filters } = this.props
    if (JSON.stringify(filters) !== JSON.stringify(nextProps.filters)) {
      this.fetchTasksDebounced(nextProps.filters)
    }
  }

  componentWillUnmount() {
    const { clearAllFilters } = this.props
    clearAllFilters()
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onScroll)
  }

  fetchTasksDebounced = debounce(this.props.getTasksList, 700);

  onScroll = () => {
    const {
      list,
      filters,
      isFetchingNext,
      nextPage,
      hasMorePage,
      getNextTasksPage
    } = this.props

    const container = document.querySelector('.block-list.block-list--tasks')

    if (!container) {
      return null
    }

    const { height } = container.getBoundingClientRect()

    if (
        !isFetchingNext &&
        list.length > 0 &&
        hasMorePage &&
        window.pageYOffset + window.innerHeight + 10 >= height
    ) {
      getNextTasksPage(nextPage, filters)
    }
  }

  onChangeFilter = filters => {
    this.props.setTasksFilter(filters)
  }

  onOpenDetail = (taskId, taskName) => {
    const { history, authenticationUser } = this.props
    authenticationUser(true)
      .then(() => history.push(`/tasks/${taskId}`, { title: taskName }))
      .catch(err => console.log(err))
  }

  render() {
    const {
      list,
      filters,
      filterAmount,
      processDefinitionKeys,
      isFetching,
      isFetchingNext,
      ismanager,
      statusItems
    } = this.props

    return (
      <section className='fr-content fr-content--tasks'>
        <TasksFilter
          isDisable={!list.length && !Object.keys(filters).length}
          filters={filters}
          filterAmount={filterAmount}
          processes={processDefinitionKeys}
          onChangeFilter={this.onChangeFilter}
          statusItems={statusItems}
        />
        <PromoRating />
        {!list.length && !isFetching
          ? <EmptyTasksList />
          : <TasksList
              list={list}
              isLoading={isFetching}
              isLoadingNext={isFetchingNext}
              statusItems={statusItems}
              onOpenDetail={this.onOpenDetail}
              ismanager={ismanager}
            />
        }
      </section>
    )
  }
}

export default connect(getTasksViewModel, {
  getTasksList,
  getNextTasksPage,
  clearAllFilters,
  setTasksFilter,
  authenticationUser,
  getStoriesInfo,
})(Tasks)
