import React from 'react'
import classnames from 'classnames'
import { formatNumber } from '../../../utils'

const AgentsStatsPanel = ({
  list,
  statusItems,
}) => {
  const { company = {}, order = {} } = list
  const { total = {} } = order

  return (
    <div className='fr-stats-panel'>
      <div className='fr-stats-panel__item'>
        <span className='fr-stats-panel__title'>Агенты</span>
        <div className='fr-stats-panel__stats'>
          <span className='fr-stats-panel__stats-value'>
            {formatNumber(company.count ? company.count : 0)}
          </span>
        </div>
      </div>
      <div className='fr-stats-panel__item'>
        <span className='fr-stats-panel__title'>Заявки</span>
        <div className='fr-stats-panel__stats'>
          {statusItems.map(({ key, text, className }) => {
            if (!order[key]) {
              return null
            }

            return (
              <span
                key={key}
                className={classnames('fr-stats-panel__stats-value fr-stats-panel__stats-value--bold', {
                  [`fr-stats-panel__stats-value--${className}`]: className
                })}
                title={text}
              >
                {formatNumber(order[key].count)}
              </span>
            )
          })}
        </div>
      </div>
      <div className='fr-stats-panel__item'>
        <span className='fr-stats-panel__title'>Сумма</span>
        <div className='fr-stats-panel__stats'>
          <span className='fr-stats-panel__stats-value'>
            {`${formatNumber(total.amount ? total.amount : 0, true)} ₽`}
          </span>
        </div>
      </div>
    </div>
  )
}

AgentsStatsPanel.defaultProps = { 
  statusItems: [], 
}

export default AgentsStatsPanel